import React, { useState, memo, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Icon } from '@blueprintjs/core';
import isEmpty from 'lodash/isEmpty';
import { DateTime } from 'luxon';
import {
  CalendarDate,
  Product,
  ProductName,
  ProductType,
} from '@edfenergy/shift-desk-efa-calendar';
import {
  LoadingIndicators,
  FilterSelection,
} from '@edfenergy/shift-desk-wallace';
import Table from './BaseNopTable/index';

import {
  TableToolBar,
  ToolBarItem,
  ToolbarMessage,
  ToolbarToastMessage,
} from './style';

import {
  useNopState,
  useGridDateState,
  useHorizonWindowStatusState,
  useGridSelectionState,
} from '../../store/nop/selector';
import { useGateClosuresState } from '../../store/gateClosures/selector';

import { useChartNopTypeState } from '../../store/charting/selector';
import { NopChartType } from '../../store/charting/actions';
import nopTypeSelection from '../Charts/utils/nopTypeSelection';

import { ScrollableDiv } from '../../styles/app.styles';

import {
  GridSelectionMetaData,
  updateNopGridDate,
  updateNopGridSelection,
} from '../../store/nop/actions';

import {
  PeriodHeader,
  PeriodVolume,
  findDayToDisplay,
  findTier3DayToDisplay,
} from '../../data/createTableData';

import { findStartDate, findEndDate } from '../../common/dates/nopDateFinder';
import { Owner, PortfolioName } from '../../container/Tier3Nop';

import isCurrentEfaDay from '../../common/dates/isCurrentEfaDay';
import allocateEfaDayCorrectDate from '../../common/dates/efaDate';

import { getBodyCellHighlight, getHeaderCellHighlight } from './utils/table';
import { applyCellModifications } from './utils/cellModifier';
import { openTradeAllocationWindow } from '../../store/tradeAllocation/actions';
import { CounterpartySelectOption } from '../../store/tradeAllocation/reducer';

type DataType = 'tier2' | 'tier3';

export type NetOpenPositionTableProps = {
  id: string;
  dataType: DataType;
  owner?: Owner;
  dataName?: PortfolioName;
};

const NetOpenPositionTable: React.FC<NetOpenPositionTableProps> = (props) => {
  const { id, dataType, dataName, owner } = props;
  const [date, setDate] = useState<string>(allocateEfaDayCorrectDate());
  const [calendarMessage, setCalendarMessage] = useState<string>('');
  const dispatch = useDispatch();
  const gridDate = useGridDateState();
  const horizonData = useNopState();
  const gateClosures = useGateClosuresState();
  const nopType = useChartNopTypeState();
  const horizonOnOff = useHorizonWindowStatusState();

  const gridSelections = useGridSelectionState();

  const { currentGateDetails } = gateClosures;

  const isTier3 = !!(dataType === 'tier3' && dataName && owner);

  const settlementPeriod =
    isCurrentEfaDay(gridDate) && horizonOnOff && currentGateDetails
      ? new Product(
          ProductType.HalfHour,
          `${currentGateDetails.period}` as ProductName,
          CalendarDate.fromFormat(currentGateDetails.date),
        )
      : null;

  let table: any = [];

  if (dataType === 'tier3' && dataName && owner) {
    table = findTier3DayToDisplay(
      settlementPeriod,
      gridDate,
      horizonData,
      dataName,
      owner,
    );
  } else {
    table = findDayToDisplay(
      settlementPeriod,
      gridDate,
      horizonData,
      gridSelections,
    );
  }

  useEffect(() => {
    setDate(gridDate);

    return () => {
      setCalendarMessage('');
    };
  }, [gridDate]);

  const changeGridViewByDate = (e: React.MouseEvent) => {
    setCalendarMessage('');
    const { id } = e.currentTarget;

    const horizonStartDate = findStartDate(horizonData);
    const horizonEndDate = findEndDate(horizonData);

    const convertedDate = DateTime.fromISO(date);

    switch (id) {
      case 'go_forward_1_day':
        {
          const addOneDay = convertedDate.plus({ days: 1 });

          if (addOneDay <= horizonEndDate) {
            setDate(addOneDay.toISODate());
            dispatch(updateNopGridDate(addOneDay.toISODate()));
          } else {
            setCalendarMessage('No more future dates available!');
          }
        }
        break;
      case 'go_back_1_day':
        {
          const minusOneDay = convertedDate.plus({ days: -1 });
          if (minusOneDay >= horizonStartDate) {
            setDate(minusOneDay.toISODate());
            dispatch(updateNopGridDate(minusOneDay.toISODate()));
          } else {
            setCalendarMessage('No more past dates available!');
          }
        }
        break;
      default:
    }
  };

  const getKeyForHeader = (index: number): string => {
    const itemInfirstRow = (table.rows[0] || [])[index] || {};
    const header = table.headers[index];
    if (typeof header === 'string' || typeof header === 'number') {
      return `${header}`;
    }
    return `header-${(itemInfirstRow as PeriodVolume).periodId}`;
  };

  const getKeyForRow = (index: number): string => {
    const rowKeys = table.rows.map((cell: any) => {
      if (React.isValidElement(cell as JSX.Element)) {
        return 'JSX';
      }
      if (typeof cell === 'string' || typeof cell === 'number') {
        return cell;
      }
      return (cell as PeriodVolume).volume;
    });
    return `${index}-${rowKeys.join('-')}`;
  };

  const getKeyForCell = (rowIndex: number, cellIndex: number): string => {
    const cell = table.rows[rowIndex][cellIndex];
    if (React.isValidElement(cell as JSX.Element)) {
      return `${rowIndex}-${cellIndex}`;
    }
    if (typeof cell === 'string' || typeof cell === 'number') {
      return `${cell}`;
    }
    return (cell as PeriodVolume).periodId;
  };

  const prettyDisplayedDate = DateTime.fromISO(date).toFormat('dd/MM/yyyy');

  const openTradeAllocation = (
    productId: string,
    selectedCounterparty?: CounterpartySelectOption,
  ) => {
    dispatch(openTradeAllocationWindow({ productId, selectedCounterparty }));
  };

  const rows = applyCellModifications({
    rows: table.rows,
    onClickCallbackFn: openTradeAllocation,
  });

  const updateGridSelections = (e: GridSelectionMetaData[]) =>
    dispatch(updateNopGridSelection(e));

  return isEmpty(table.headers) ? (
    <LoadingIndicators
      type="circle"
      size="md"
      colour="blue"
      position="center"
    />
  ) : (
    <>
      <TableToolBar isToday={isCurrentEfaDay(gridDate)}>
        <ToolBarItem>
          <strong>Select a date:</strong>
        </ToolBarItem>
        <ToolBarItem>
          <Icon
            icon="chevron-left"
            size={12}
            htmlTitle="Go back a day"
            style={{ cursor: 'pointer' }}
            onClick={changeGridViewByDate}
            id="go_back_1_day"
          />
          {prettyDisplayedDate}
          <Icon
            icon="chevron-right"
            size={12}
            htmlTitle="Go forward a day"
            style={{ cursor: 'pointer' }}
            onClick={changeGridViewByDate}
            id="go_forward_1_day"
          />
        </ToolBarItem>
        <ToolBarItem>
          <ToolbarToastMessage isToday={isCurrentEfaDay(gridDate)}>
            Current trading day
          </ToolbarToastMessage>
        </ToolBarItem>
        <ToolBarItem>
          <ToolbarMessage>{calendarMessage}</ToolbarMessage>
        </ToolBarItem>
        {!isTier3 && (
          <ToolBarItem>
            <FilterSelection
              dataItems={gridSelections}
              onChangeEvent={(e) => updateGridSelections(e)}
            />
          </ToolBarItem>
        )}
      </TableToolBar>
      {rows.length !== 0 && (
        <ScrollableDiv>
          <Table
            id={id}
            firstColumnSeparate
            headers={table.headers}
            rows={rows}
            getHeaderValue={(header: PeriodHeader) => header.endTime}
            getCellValue={(cell: PeriodVolume) => cell.volume}
            freezeFirstColumn
            minCellWidth={53}
            minHeaderCellWidth={53}
            minWidth={800}
            getHeaderCellHighlight={(cell) =>
              getHeaderCellHighlight(cell, gateClosures)
            }
            getKeyForHeader={getKeyForHeader}
            getKeyForRow={getKeyForRow}
            getKeyForCell={getKeyForCell}
            getBodyCellHighlight={(cell) =>
              getBodyCellHighlight(cell, gateClosures)
            }
            rowIndexHighlight={nopTypeSelection(nopType, [
              NopChartType.EDF,
              NopChartType.WBB,
              NopChartType.ALL,
            ])}
          />
        </ScrollableDiv>
      )}
    </>
  );
};

NetOpenPositionTable.displayName = 'NetOpenPositionTable_Component';

export default memo(NetOpenPositionTable);
