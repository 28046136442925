import { Icon } from '@blueprintjs/core';
import React from 'react';

export type LegendProps = {
  colours: string[];
  label: string;
};

const Legend: React.FC<LegendProps> = (props) => {
  const { colours, label } = props;

  return (
    <>
      <Icon
        data-testid={`NOP_Legend_${label}_Positive`}
        icon="symbol-square"
        size={20}
        color={colours[0]}
        style={{ marginTop: 5, marginLeft: -2 }}
      />
      <Icon
        data-testid={`NOP_Legend_${label}_Negative`}
        icon="symbol-square"
        size={20}
        color={colours[1]}
        style={{ marginTop: 5, marginLeft: -10 }}
      />
      <span style={{ fontSize: 10 }}>{label}</span>
    </>
  );
};

export default Legend;
