import { Action } from 'redux';
import { logger } from '@edfenergy/shift-desk-monitoring';
import { APIRequest, API_REQUEST } from '../api/actions';
import client from '../../auth/client';

interface MiddleWareAction<T> extends Action {
  payload: APIRequest<T>;
}

export type APIMiddlewareError = TypeError;

const apiMiddleware =
  () =>
  (next: any) =>
  async <T>(action: MiddleWareAction<T>) => {
    if (action.type !== API_REQUEST) {
      return next(action);
    }

    const { query, variables, onSuccess, onFailure } = action.payload;

    await client
      .query({ query, variables })
      .then(({ data }: any) => {
        next(onSuccess(data));
      })
      .catch((error) => {
        logger.error('error', error);
        next(onFailure(error));
      });

    return next(action);
  };

export default apiMiddleware;
