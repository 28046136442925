import React, { memo } from 'react';
import { useParams } from 'react-router-dom';

import NopTable from '../components/Grids';
import displayNames from '../components/Grids/displayNames';
import { GridWrapper, Tier3Container, Tier3Heading } from './style';

export type PortfolioName =
  | 'Nuclear'
  | 'Coal'
  | 'WBB'
  | 'Gas'
  | 'Batteries'
  | 'Embed_Gen'
  | 'Customer_Vol'
  | 'Trades'
  | 'Gas Peaker'
  | 'Gas Peaker NOP'
  | 'Batteries NOP';

export type Owner = 'EDF' | 'WBB';

export type Tier3Params = {
  date: string;
  owner: Owner;
  portfolio: PortfolioName;
};

const Tier3Nop: React.FC = () => {
  const { owner, portfolio } = useParams<Tier3Params>();

  const displayName = displayNames[owner as Owner][portfolio as PortfolioName];

  document.title = `${owner} ${displayName} - Tier 3 - EDF Shift Trading`;

  return (
    <Tier3Container>
      <Tier3Heading>{displayName} Tier 3</Tier3Heading>
      <GridWrapper>
        <NopTable
          dataType="tier3"
          id={`NOP_TIER3_${displayName}`}
          dataName={portfolio}
          owner={owner}
        />
      </GridWrapper>
    </Tier3Container>
  );
};

export default memo(Tier3Nop);
