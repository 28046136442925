import {
  Tabs as WallaceTabs,
  Tab as WallaceTab,
} from '@edfenergy/shift-desk-wallace';
import styled from 'styled-components';

const TRADE_ALLOCATION_WIDTH = '400px';

export const Wrapper = styled.div`
  background-color: white;
  width: 100%;
  flex: 0 0 ${TRADE_ALLOCATION_WIDTH};
  overflow: hidden;
  height: 100%;
`;

export const TradeAllocationWindowTabs = styled(WallaceTabs)`
  height: calc(100% - 19px) !important;
  overflow: hidden;
`;

export const TradeAllocationWindowTab = styled(WallaceTab)``;

export const CloseButtonWrapper = styled.div`
  text-align: right;
`;

export const Divider = styled.div`
  height: 1px;
  background: #d3d3d3;
  width: 100%;
  margin: 9px 0 8px;
`;

export const TabContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

export const TabContentPrimary = styled.div`
  position: relative;
  height: 100%;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    background-color: #d3d3d3;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #969595;
  }
`;

export const TabContentSecondary = styled.div`
  max-height: 50%;
`;

export const TabContentSecondaryScrollable = styled.div`
  height: 70%;
  max-height: 70%;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    background-color: #d3d3d3;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #000000;
  }
`;

export const ButtonFooter = styled.div`
  background-color: white;
  display: flex;
  justify-content: flex-end;
  padding: 10px;
`;

export const FormSection = styled.div`
  margin-block-start: 1em;
  margin-block-end: 1em;
`;

export const FlexFormSection = styled.div`
  margin-block-start: 1em;
  margin-block-end: 1em;
  display: flex;
  align-items: center;
`;

export const LabelContainer = styled.div`
  font-size: 12px;
  padding-top: 2px;
  padding-left: 4px;
  height: 20px;
  width: 85px;
`;

export const SelectListContainer = styled.div`
  width: 200px;
`;

export const BlueBar = styled.div`
  background-color: #d9e9f8;
  padding: 0 8px;
  font-weight: bold;
  font-size: 80%;
  display: flex;
  justify-content: flex-end;
`;

export const TotalVolumeAndPriceWrapper = styled.div`
  width: 35%;
  display: flex;
  justify-content: space-between;
`;

export const InputFieldWrapper = styled.div`
  display: flex;
  margin-left: 5px;
`;

export const InputAndValidationContainer = styled.div`
  height: 270px;
`;

export const ConfirmSplitAllocationButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 15px;
`;

export const AllocateRemainingButtonWrapper = styled.div`
  width: 40%;
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
`;

export const RefreshTradesSection = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
`;

export const PriceInputContainer = styled.span`
  flex-direction: row-reverse;
  margin-top: 10px;
  margin-bottom: 0px;
  display: flex;
  padding-right: 3px;
  width: 100%;
  & #price {
    background-color: #f1f1f1;
    border-style: solid solid solid none;
    padding: 0px;
    height: 20px;
    width: 105px;
  }
`;

export const PoundSignBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 20px;
  margin-top: 23.5px;
  appearance: none;
  outline: none;
  font-size: 14px;
  font-weight: bold;
  background-color: #f1f1f1;
  border-top: 1px solid rgb(102, 102, 102);
  border-bottom: 1px solid rgb(102, 102, 102);
  border-left: 1px solid rgb(102, 102, 102);
  min-width: 20px;
`;
