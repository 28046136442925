import React from 'react';
import { BarId, LineMarkerProps } from './ResponsiveBarChart';
import { CurrentTimeLine } from './style';
import { useCurrentTimeState } from '../../store/timing/selector';
import { useGateClosuresState } from '../../store/gateClosures/selector';
import { composeBarId } from '../../data/createChartData';
import clock from '../../common/clock/clock';

const CurrentTimeMarker: React.FC<LineMarkerProps> = (props) => {
  const { bars, xScale, height } = props;
  const { currentGateDetails } = useGateClosuresState();
  const { currentDateTime } = useCurrentTimeState();

  const columnWidth = bars.length ? bars[0].width : 0;

  const t = currentDateTime ?? clock().now().toLuxon().toJSDate();

  const minutesInBlock = 30;
  const secondsInMinute = 60;
  const msInSecond = 1000;

  const msInBlock = minutesInBlock * secondsInMinute * msInSecond;
  let currentMs =
    (t.getMinutes() * secondsInMinute + t.getSeconds()) * msInSecond +
    t.getMilliseconds();

  // Note: current implementation presupposes chase mode ON - hence the condition below.
  currentMs = currentMs > msInBlock ? currentMs - msInBlock : currentMs;

  let currentId: BarId | null = composeBarId(
    currentGateDetails?.date ?? '',
    currentGateDetails?.startTime ?? '',
    currentGateDetails?.period ?? 0,
  );

  if (!bars.filter((x) => x.data.indexValue === currentId).length) {
    currentId = null;
  }

  // Note: given this is a bar chart, xScale seems to only support integers hence need to convert the integer and the fractional parts into the chart space separately.
  const currentPosition = currentId
    ? xScale(currentId) +
      (columnWidth / (minutesInBlock * secondsInMinute * msInSecond)) *
        currentMs
    : null;

  return currentPosition ? (
    <CurrentTimeLine
      data-testid="currentTimeMarker_line"
      opacity="1"
      x1={currentPosition}
      y1="0"
      x2={currentPosition}
      y2={height}
    />
  ) : (
    <></>
  );
};

export default CurrentTimeMarker;
