import { wapQuery } from '../../graphql/queries';
import { Action } from '../actions';
import { apiAction } from '../api/actions';

export const UPDATE_WAPS = 'UPDATE_WAPS';
export const WAPS_ERROR = 'WAPS_ERROR';
export const SET_WAPS_AS_LOADING = 'SET_WAPS_AS_LOADING';

export type Wap = {
  productId: string;
  publicWap: number | null;
  privateBuyWap: number | null;
  privateSellWap: number | null;
};

export type WapsApiResponse = {
  getWapsForProducts: Wap[] | null;
};

export const updateWaps = (payload: WapsApiResponse): Action => ({
  type: UPDATE_WAPS,
  payload,
});

export const wapsError = (payload: string) => ({
  type: WAPS_ERROR,
  payload,
});

export const setWapsAsLoading = () => ({
  type: SET_WAPS_AS_LOADING,
  payload: null,
});

export const queryWaps = (productIds: string[]): Action => {
  const action = apiAction<WapsApiResponse>({
    query: wapQuery,
    variables: { productIds },
    onSuccess: (res) => updateWaps(res),
    onFailure: (x) => wapsError(`Wap retrieval error: ${x}`),
    label: UPDATE_WAPS,
  });

  return action;
};
