import { DateTime } from 'luxon';
import { ConnectionLevel } from '.';

const determineConnectionLevel = (
  mostRecent: DateTime | null,
  currentTime: DateTime,
  weakThresholdSeconds: number,
  noneThresholdSeconds: number,
): ConnectionLevel => {
  if (noneThresholdSeconds < weakThresholdSeconds) {
    throw new RangeError('noneThreshold should be greater than weakThreshold');
  }

  if (mostRecent && currentTime) {
    const secondsSinceLastConnect: number = currentTime
      .diff(mostRecent)
      .as('seconds');

    if (secondsSinceLastConnect <= weakThresholdSeconds) {
      return ConnectionLevel.Good;
    }
    if (secondsSinceLastConnect >= noneThresholdSeconds) {
      return ConnectionLevel.None;
    }
    if (secondsSinceLastConnect > weakThresholdSeconds) {
      return ConnectionLevel.Weak;
    }
  }
  return ConnectionLevel.Initializing;
};
export default determineConnectionLevel;
