import {
  DefaultProductMapper,
  productConfig,
} from '@edfenergy/shift-desk-efa-calendar';
import timeGetter from './timeGetter';

const productMapper = () =>
  new DefaultProductMapper(productConfig, timeGetter());

export default productMapper;
