/* eslint-disable no-unused-vars */
import { Action } from '../actions';

export const SET_CHART_RANGE = 'SET_CHART_RANGE';
export const SET_CHART_ZOOM_HORIZONTAL = 'SET_CHART_ZOOM_HORIZONTAL';
export const SET_CHART_ZOOM_VERTICAL = 'SET_CHART_ZOOM_VERTICAL';
export const SET_NOP_CHART_TYPE = 'SET_NOP_CHART_TYPE';

export enum ChartRangeKind {
  Custom,
  Hours12,
  Hours24,
  Days2,
  Days7,
}

export type ChartRange = {
  rangeKind: ChartRangeKind;
  pastPeriods: number;
  currentPeriods: number;
  days: number;
};

export type RangePercent = {
  start: number;
  end: number;
};

export type ChartZoom = {
  horizontalZoom: RangePercent;
  verticalZoom: RangePercent;
};

export enum NopChartType {
  EDF = 'EDF',
  WBB = 'WBB',
  ALL = 'ALL',
}

export type SetChartRangePayload = {
  data: ChartRange;
};

export type SetChartZoomPayload = {
  data: RangePercent;
};

export type SetNopChartType = NopChartType;

export const setChartRange = (payload: SetChartRangePayload): Action => ({
  type: SET_CHART_RANGE,
  payload,
});

export const setChartHorizontalZoom = (
  payload: SetChartZoomPayload,
): Action => ({
  type: SET_CHART_ZOOM_HORIZONTAL,
  payload,
});

export const setChartVerticalZoom = (payload: SetChartZoomPayload): Action => ({
  type: SET_CHART_ZOOM_VERTICAL,
  payload,
});

export const setNopChartType = (payload: SetNopChartType): Action => ({
  type: SET_NOP_CHART_TYPE,
  payload,
});
