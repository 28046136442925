const MAX_PRICE_FOR_INTERNAL_NETTING_ALLOCATION = 9999.99;
const MIN_PRICE_FOR_INTERNAL_NETTING_ALLOCATION = -9999.99;

export const blankMessage = 'Cannot be blank.';

type VolumeValidationMessageContainer = {
  volumeErrorMessage: string | undefined;
  volumeDisplayMessage: string | undefined;
};

export const volumeAllocationValidation = (
  input: number | undefined,
  upperLimit: number,
  allowZero: boolean = false,
): VolumeValidationMessageContainer => {
  if (input || input === 0) {
    if (!allowZero && input <= 0) {
      return {
        volumeErrorMessage: `Must be a value > 0`,
        volumeDisplayMessage: undefined,
      };
    }
    if (allowZero && input < 0) {
      return {
        volumeErrorMessage: `Must be a value >= 0`,
        volumeDisplayMessage: undefined,
      };
    }
    if (upperLimit !== undefined && input > upperLimit) {
      return {
        volumeErrorMessage: undefined,
        volumeDisplayMessage: `exceeds ${upperLimit}MW`,
      };
    }
    return { volumeErrorMessage: undefined, volumeDisplayMessage: undefined };
  }
  return { volumeErrorMessage: blankMessage, volumeDisplayMessage: undefined };
};

export const combineVolumeAllocationValidations = (
  validations: VolumeValidationMessageContainer[],
): VolumeValidationMessageContainer => {
  const validationsWithError = validations.filter(
    (validation) => validation.volumeErrorMessage !== undefined,
  );
  return (
    validationsWithError[0] ?? {
      volumeErrorMessage: undefined,
      volumeDisplayMessage: undefined,
    }
  );
};

const isWhitespaceString = (input: string): boolean =>
  input.length === 0 || !input.trim();

const zeroPad = (input: number, places: number) => {
  const zero = places - input.toString().length + 1;
  const out = Array(+(zero > 0 && zero)).join('0') + input;
  if (input < 0 || Object.is(input, -0)) {
    return `-${out}`;
  }
  return out;
};

export const formatPrice = (input: string): string => {
  if (isWhitespaceString(input)) {
    return input;
  }

  if (Number.isNaN(+input)) {
    return input;
  }

  const inputNumber = parseFloat(input);
  const leadingPad =
    inputNumber > -1 && inputNumber < 1
      ? zeroPad(Math.trunc(inputNumber), 2)
      : String(Math.trunc(inputNumber));
  const rounded = (Math.round(inputNumber * 100) / 100).toFixed(2);
  return `${leadingPad.split('.')[0]}.${rounded.split('.')[1]}`;
};

export const priceAllocationValidation = (
  input: string,
): { priceErrorMessage?: string; priceDisplayMessage?: string } => {
  if (isWhitespaceString(input)) {
    return {
      priceErrorMessage: blankMessage,
    };
  }

  if (Number.isNaN(+input)) {
    return {
      priceErrorMessage: 'Must be numeric.',
    };
  }

  const parsedInput = parseFloat(input);

  if (parsedInput > MAX_PRICE_FOR_INTERNAL_NETTING_ALLOCATION) {
    return {
      priceErrorMessage: 'Cannot exceed 9999.99',
    };
  }

  if (parsedInput < MIN_PRICE_FOR_INTERNAL_NETTING_ALLOCATION) {
    return {
      priceErrorMessage: 'Cannot be less than -9999.99',
    };
  }

  if (parsedInput === 0) {
    return {
      priceDisplayMessage: 'is 0',
    };
  }

  if (parsedInput < 0) {
    return {
      priceDisplayMessage: 'is negative',
    };
  }

  return {
    priceErrorMessage: undefined,
  };
};

export const traderIdValidation = (
  input: string | undefined,
): { traderIdErrorMessage?: string } => {
  if (input) {
    if (/^[A-Z]{2}[0-9]{2}$/.test(input)) {
      return {};
    }
    return { traderIdErrorMessage: 'Must have valid format' };
  }
  return { traderIdErrorMessage: blankMessage };
};
