const displayNames: Record<string, Record<string, string>> = {
  EDF: {
    Nuclear: 'Nuclear',
    Coal: 'Coal',
    Batteries: 'Batteries',
    Gas_Peaker: 'Gas Peaker',
    Embed_Gen: 'Embed_Gen',
    Customer_Vol: 'Customer_Vol',
    Trades: 'Trades',
  },
  WBB: {
    Gas: 'WBB',
    Trades: 'WBB Trades',
  },
};

export default displayNames;
