import { NopChartData } from '.';
import { NopChartType, RangePercent } from '../../store/charting/actions';

const getAxisTicks = (
  minValue: number,
  maxValue: number,
  tickCount: number,
) => {
  const tickValue = getTickValue(minValue, maxValue, tickCount);

  if (tickValue === 0) return [];

  const startTick = Math.round(minValue / tickValue);
  const endTick = Math.round(maxValue / tickValue);

  const ticks: number[] = [];

  for (let i = startTick; i <= endTick; i += 1) {
    const iTickValue = i * tickValue;

    ticks.push(iTickValue);
  }

  return ticks;
};

const getTickValue = (
  minValue: number,
  maxValue: number,
  tickCount: number,
) => {
  if (minValue >= maxValue || tickCount <= 0) return 0;

  const range =
    Math.sign(minValue) === Math.sign(maxValue)
      ? maxValue - minValue
      : Math.abs(maxValue) + Math.abs(minValue);

  const rawTick = Math.floor(range / tickCount);

  if (rawTick < 10) return rawTick;

  const leadingNumberLength = 2;
  const rawTickStr = rawTick.toString();
  const tickMagnitude = rawTickStr.length - 1;
  const tickLeadingNumber = Math.round(
    Number(rawTickStr.substr(0, leadingNumberLength)) / 10,
  );

  return tickLeadingNumber * 10 ** tickMagnitude;
};

const getAxisRepresentativeItems = <T>(items: T[], setNumber: number) =>
  items.slice(0, Math.ceil(items.length / setNumber));

const getMinMaxValues = (
  data: NopChartData[],
  nopChartType: NopChartType,
  verticalAxisTicks: number,
  verticalZoom: RangePercent,
  gapFactor: number,
): { minValue: number; maxValue: number } => {
  let nopValues: number[];

  if (nopChartType === NopChartType.EDF) {
    nopValues = data.map((c) => c.EDF);
  } else if (nopChartType === NopChartType.WBB) {
    nopValues = data.map((c) => c.WBB);
  } else {
    nopValues = data.map((c) => [c.EDF, c.WBB, c.EDF + c.WBB]).flat();
  }

  const minNop = Math.min(...nopValues, 0);
  const maxNop = Math.max(...nopValues, 0);
  const tickValue = getTickValue(minNop, maxNop, verticalAxisTicks);

  const gap = tickValue * gapFactor;

  const minNopWithGap = minNop - gap;
  const maxNopWithGap = maxNop > 0 ? maxNop + gap : maxNop - gap;

  const verticalSwing = Math.abs(minNopWithGap) + Math.abs(maxNopWithGap);
  const minValue = Math.round(
    minNopWithGap + (verticalSwing / 100) * (100 - verticalZoom.end),
  );
  const maxValue = Math.round(
    minNopWithGap + (verticalSwing / 100) * (100 - verticalZoom.start),
  );

  return { minValue, maxValue };
};

export default getAxisTicks;
export {
  getAxisTicks,
  getTickValue,
  getAxisRepresentativeItems,
  getMinMaxValues,
};
