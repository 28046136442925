/* eslint-disable prettier/prettier */
import styled from 'styled-components';

type DateDisplayProps = {
  settlementPeriodsInEFADay: string;
};

type ToolbarToastMessageProps = {
  isToday: boolean;
}

const IconButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  &:hover span > svg {
    fill: #188cfc;
  }
`;

const DateDisplayContainer = styled.div`
  background-color: #fff;
`;

const DateDisplayTable = styled.table`
  margin-left: 114px;
  width: 100%;
  table-layout: fixed;
  background-color: #fff;
`;

const DateDisplayRow = styled.tr<DateDisplayProps>`
  font-size: 12px;
  background-color: #fff;
  > td {
    color: transparent;
    width: 45px;
    background-color: #fff;
    pointer-events: none;
  }

  > td:nth-child(${({ settlementPeriodsInEFADay }) =>
    settlementPeriodsInEFADay}n
      + 1) {
    white-space: nowrap;
    left: 0px;
    color: #000;
    z-index: 999;
    position: sticky;
    border-left: 1px solid #d3d3d3;
    background-color: #fff;
  }

  > td > span {
    background-color: #fff;
    padding-right: 10px;
    padding-left: 8px;
  }
`;

const TableToolBar = styled.div<ToolbarToastMessageProps>`
  padding: 10px 0;
  display: flex;
  justify-content: left;
  background: #${({ isToday }) => isToday ? 'FFF' : 'db6c78'};
`;

const ToolBarItem = styled.div`
  z-index: 99;
  margin: auto 10px;
  font-size: 13px;
`;

const ToolbarMessage = styled.span`
  font-weight: bold;
  color: #333;
`

const ToolbarToastMessage = styled.span<ToolbarToastMessageProps>`
  padding: 5px;

  background: #${({ isToday }) => isToday ? '10367a' : 'dadada'};
  text-decoration: ${({ isToday }) => isToday ? 'none' : 'line-through'};
  color: #${({ isToday }) => isToday ? 'FFF' : '333'};
`

const TradeAllocationButton = styled.div`

  transition: color 0.5s, background-color 0.5s;

  &:hover {
    background-color: #1089FF;
    color: #FFFFFF;
    cursor: pointer;
  }

`;

export {
  IconButton,
  DateDisplayContainer,
  DateDisplayRow,
  DateDisplayTable,
  TableToolBar,
  ToolBarItem,
  ToolbarMessage,
  ToolbarToastMessage,
  TradeAllocationButton,
};
