import { format, parseISO } from 'date-fns';

export const Y_M_D = 'yyyy-MM-dd';

const formatDateDDMmm = (date: string) => format(parseISO(date), 'dd MMM');

const formatDateYYYYMMDD = (date: Date) => format(date, Y_M_D);

const convertToCalendarDateIfNecessary = (
  dateAndTime: string,
  stringDate: string,
) => {
  const differenceBetweenEfaDayAndCalendarDay = ['23:30', '00:00'];
  if (differenceBetweenEfaDayAndCalendarDay.includes(dateAndTime.slice(-5))) {
    const date = new Date(stringDate);
    date.setDate(date.getDate() - 1);
    const newDate = formatDateYYYYMMDD(date);
    const time = dateAndTime.slice(6);
    const newDateAndTime = formatDateDDMmm(newDate) + time;
    return newDateAndTime;
  }
  return dateAndTime;
};

export {
  formatDateDDMmm,
  formatDateYYYYMMDD,
  convertToCalendarDateIfNecessary,
};
