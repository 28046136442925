import { zapiSubmissionsQuery } from '../../graphql/queries';
import { Action } from '../actions';
import { apiAction } from '../api/actions';
import { ZapiSubmissionsApiResponse } from './reducer';

export const UPDATE_ZAPI_SUBMISSIONS = 'UPDATE_ZAPI_SUBMISSIONS';
export const ZAPI_SUBMISSIONS_ERROR = 'ZAPI_SUBMISSIONS_ERROR';
export const SET_ZAPI_SUBMISSIONS_AS_LOADING =
  'SET_ZAPI_SUBMISSIONS_AS_LOADING';

export const updateZapiSubmissions = (
  payload: ZapiSubmissionsApiResponse,
): Action => ({
  type: UPDATE_ZAPI_SUBMISSIONS,
  payload,
});

export const zapiSubmissionsError = (payload: string) => ({
  type: ZAPI_SUBMISSIONS_ERROR,
  payload,
});

export const setZapiSubmissionsAsLoading = () => ({
  type: SET_ZAPI_SUBMISSIONS_AS_LOADING,
  payload: null,
});

export const queryZapiSubmissions = (): Action => {
  const action = apiAction<ZapiSubmissionsApiResponse>({
    query: zapiSubmissionsQuery,
    onSuccess: (res) => updateZapiSubmissions(res),
    onFailure: (x) =>
      zapiSubmissionsError(`Zapi submissions retrieval error: ${x}`),
    label: UPDATE_ZAPI_SUBMISSIONS,
  });

  return action;
};
