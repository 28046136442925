import React from 'react';
import { DisplayMessage, ErrorMessage } from './style';

type WindowMessageProps = {
  errorMessages: string[];
  infoMessages: string[];
};

const filterBlank = (message: string): boolean => message.length > 0;

const WindowMessage: React.FC<WindowMessageProps> = (props) => {
  const { errorMessages, infoMessages } = props;

  return (
    <>
      {errorMessages.filter(filterBlank).map((errorMessage) => (
        <div key={errorMessage}>
          <ErrorMessage>{errorMessage}</ErrorMessage>
        </div>
      ))}
      {infoMessages.filter(filterBlank).map((infoMessage) => (
        <div key={infoMessage}>
          <DisplayMessage>{infoMessage}</DisplayMessage>
        </div>
      ))}
    </>
  );
};
export default WindowMessage;
