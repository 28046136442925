import React from 'react';
import FooterContainer from './style';

// eslint-disable-next-line import/no-unresolved
import packageJson from '../../../package.json';

const Footer: React.FC = () => (
  <FooterContainer>Version {packageJson.version}</FooterContainer>
);

export default Footer;
