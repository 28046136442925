import {
  CalendarDate,
  CalendarDay,
  Product,
  ProductName,
  ProductType,
} from '@edfenergy/shift-desk-efa-calendar';
import { DateTime } from 'luxon';
import type { NopHorizon } from '../store/nop/reducer';
import { positiveNegativeColour } from '../components/Charts/utils';

import { BarData, BarId } from '../components/Charts/ResponsiveBarChart';
import { ChartRange, NopChartType } from '../store/charting/actions';
import { NopChartData } from '../components/Charts/NopBarChart';

import { NopType } from './createTableData';
import {
  findSettlementPeriodIndex,
  removeOutOfDateSettlementPeriods,
} from './utils';
import { Period, EfaDay } from '../store/nop/actions';

import { Y_M_D } from '../common/dates/dateFormatter';

export const composeBarId = (date: string, startTime: string, period: number) =>
  `${date}_${startTime}_${period}`;

export const splitBarId = (
  barId: BarId,
): {
  date: string;
  startTime: string;
  period: number;
} => {
  const [date, startTime, periodString] = barId.split('_');
  const period = parseFloat(periodString);

  return { date, startTime, period };
};

export const createChartData = (
  horizonData: NopHorizon,
  chartRange: ChartRange,
  currentSettlement: number,
  horizonWindowOn: boolean,
  nopChartTypeView: NopChartType,
  selectedDate: string,
): NopChartData[] => {
  if (Object.keys(horizonData).length === 0) {
    return [];
  }

  const { periods } = horizonData[selectedDate];
  const { pastPeriods, currentPeriods, days } = chartRange;

  const convertedDate = DateTime.fromFormat(selectedDate, Y_M_D);

  const horizonEndDate = convertedDate.plus({ days }).toFormat(Y_M_D);

  const horizonDates = Object.keys(horizonData);

  const startDateIndex = horizonDates.findIndex(
    (value) => value === selectedDate,
  );
  const endDateIndex = horizonDates.findIndex(
    (value) => value === horizonEndDate,
  );

  const horizonRange = horizonDates.slice(startDateIndex, endDateIndex + 1);

  const settlementPeriodHhProduct =
    currentSettlement - pastPeriods > 0
      ? new Product(
          ProductType.HalfHour,
          `${currentSettlement - pastPeriods}` as ProductName,
          CalendarDate.fromFormat(selectedDate),
        )
      : null;

  const currentSettlmentPeriodIndex = horizonWindowOn
    ? findSettlementPeriodIndex(periods, settlementPeriodHhProduct)
    : 0;

  const nopType =
    (nopChartTypeView === NopChartType.EDF && NopType.EDF) ||
    (nopChartTypeView === NopChartType.WBB && NopType.WBB) ||
    NopType.EDF;

  // check if it's in the correct range of settlement periods

  const totalPeriodsNeeded = pastPeriods + currentPeriods;

  const data = horizonRange
    .map((date, index) => {
      let settlementPeriodIndex = currentSettlmentPeriodIndex;

      if (index !== 0) {
        settlementPeriodIndex = 0;
      }

      return createChartDataShape(
        nopType,
        horizonData[date].periods,
        horizonData[date].date,
        settlementPeriodIndex,
        nopChartTypeView,
      );
    })
    .flat();

  if (data.length > totalPeriodsNeeded) {
    return data.splice(0, totalPeriodsNeeded);
  }

  return data;
};

const createChartDataShape = (
  nopType: number,
  periods: Period[],
  date: EfaDay,
  currentSettlementIndex: number,
  nopChartTypeView: NopChartType,
) => {
  const result = periods.map((settlement) => {
    const { nop, period } = settlement;
    const { position } = { ...nop[nopType] };

    const { startTime, endTime, settlementPeriod, periodId } = period;

    const { efaDate } = date;

    const nopPosition = position.nbp;

    const formattedEfaDate = DateTime.fromFormat(efaDate, Y_M_D).toJSDate();

    const calendarDay = new CalendarDay(formattedEfaDate);

    const [hour] = startTime.split(':');

    const calendarDate = calendarDay.getCalendarDateFromStartTime(
      parseFloat(hour),
    );

    const formatedCalendarDate =
      DateTime.fromJSDate(calendarDate).toFormat(Y_M_D);

    if (nopChartTypeView === NopChartType.ALL) {
      const createNopStructure = nop
        .map((detail) => {
          const { name, position } = detail;
          return {
            [name]: position.nbp,
            [`${name}Color`]:
              name === NopChartType.EDF
                ? positiveNegativeColour(position.nbp, NopChartType.EDF)
                : positiveNegativeColour(position.nbp, NopChartType.WBB),
          };
        })
        .flat();

      const allNops = Object.assign({}, ...createNopStructure);

      const barData: BarData = {
        barId: composeBarId(formatedCalendarDate, startTime, settlementPeriod),
        date: formatedCalendarDate,
        time: settlementPeriod,
        startTime,
        endTime,
        efaDate,
        periodId,
        ...allNops,
      };

      return barData;
    }

    if (nopChartTypeView === 'EDF') {
      return {
        barId: composeBarId(formatedCalendarDate, startTime, settlementPeriod),
        date: formatedCalendarDate,
        time: settlementPeriod,
        startTime,
        endTime,
        efaDate,
        volume: nopPosition,
        id: 'EDF',
        EDF: nopPosition,
        EDFColor: positiveNegativeColour(nopPosition, NopChartType.EDF),
        periodId,
      };
    }
    if (nopChartTypeView === 'WBB') {
      return {
        barId: composeBarId(formatedCalendarDate, startTime, settlementPeriod),
        date: formatedCalendarDate,
        time: settlementPeriod,
        startTime,
        endTime,
        efaDate,
        volume: nopPosition,
        id: 'WBB',
        WBB: nopPosition,
        WBBColor: positiveNegativeColour(nopPosition, NopChartType.WBB),
        periodId,
      };
    }
    return {
      barId: composeBarId(formatedCalendarDate, startTime, settlementPeriod),
      date: formatedCalendarDate,
      time: settlementPeriod,
      startTime,
      endTime,
      efaDate,
      periodId,
    };
  });

  return removeOutOfDateSettlementPeriods(result, currentSettlementIndex);
};
