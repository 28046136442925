import {
  Button,
  Grid,
  Label,
  RadioButtonGroup,
} from '@edfenergy/shift-desk-wallace';
import {
  CalendarDateTime,
  CalendarStartEndTimes,
  Product,
} from '@edfenergy/shift-desk-efa-calendar';
import _ from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import timeGetter from '../../../../common/products/timeGetter';
import { BuySell } from '../../../../data/suggestInternalAllocationVolumeAndSellDirection';
import { setTradeAllocationWindowState } from '../../../../store/tradeAllocation/actions';
import {
  CounterpartySelectOption,
  FormField,
  InternalAllocationForm,
  ProductSelectOption,
  TradeAllocationWindowState,
  WapType,
} from '../../../../store/tradeAllocation/reducer';
import { useTradeAllocationWindowState } from '../../../../store/tradeAllocation/selector';
import { setTraderId } from '../../../../store/user/actions';
import { Wap } from '../../../../store/waps/actions';
import { useWapState } from '../../../../store/waps/selector';
import {
  formatPrice,
  priceAllocationValidation,
  traderIdValidation,
  volumeAllocationValidation,
} from '../../../../validation/input';
import InputField from '../../../Form';
import SelectList from '../../../SelectList';
import WindowMessage from '../../../WindowMessage';
import {
  Divider,
  ButtonFooter,
  FormSection,
  TabContentPrimary,
  TabContentSecondary,
  TabContentWrapper,
  FlexFormSection,
  LabelContainer,
  SelectListContainer,
  PriceInputContainer,
  PoundSignBox,
} from '../../style';
import {
  CreateInternalPowerTransferDirectionInput,
  CreateInternalPowerTransferFromTo,
  submitInternalAllocation,
} from './submitInternalAllocation';
import { LoadingIndicator } from '../../../../styles/app.styles';

type FieldValidation = {
  isInvalid: boolean;
  errorMessage: string;
  infoMessage: string;
};

export type Validation = {
  traderId: FieldValidation;
  volume: FieldValidation;
  price: FieldValidation;
};

type WapRadioItem = { value: number; type: WapType; text: string };

export const nullStartEndTimes = { start: null, end: null };
export type NullStartEndTimes = typeof nullStartEndTimes;

export const blankValidation = {
  traderId: { isInvalid: false, errorMessage: '', infoMessage: '' },
  volume: { isInvalid: false, errorMessage: '', infoMessage: '' },
  price: { isInvalid: false, errorMessage: '', infoMessage: '' },
};

export const wapRadioItems: WapRadioItem[] = [
  {
    value: 0,
    type: 'publicWap',
    text: 'Market WAP',
  },
  {
    value: 1,
    type: 'privateBuyWap',
    text: 'Private Buy WAP',
  },
  {
    value: 2,
    type: 'privateSellWap',
    text: 'Private Sell WAP',
  },
];

const edfBuysOrSellsRadioItems = [
  {
    value: 0,
    text: 'BUY',
  },
  {
    value: 1,
    text: 'SELL',
  },
];

export const formatProductTime = (
  time: CalendarDateTime | null,
): JSX.Element => {
  if (time !== null) {
    return (
      <strong>
        {time.toFormat('dd LLL yy')} &bull; {time.toFormat('HH:mm')}
      </strong>
    );
  }
  return <strong>-</strong>;
};

const convertBuySellFromNumericToText = (numeric: number): BuySell =>
  numeric === 0 ? 'BUY' : 'SELL';

const convertBuySellFromTextToNumeric = (text: BuySell): number =>
  text === 'BUY' ? 0 : 1;

export const performValidation = (
  windowState: TradeAllocationWindowState,
): Validation => {
  // Validate trader ID
  const traderIdValidity = traderIdValidation(
    windowState.internalAllocationForm.traderId.value,
  );

  // Validate volume
  const volumeLimit = parseFloat(
    windowState.internalAllocationForm.volume.initialValue,
  );
  const volumeValidation = volumeAllocationValidation(
    parseFloat(windowState.internalAllocationForm.volume.value),
    volumeLimit,
  );

  // Validate price
  const priceValidation = priceAllocationValidation(
    windowState.internalAllocationForm.price.value,
  );

  return {
    traderId: {
      errorMessage: traderIdValidity.traderIdErrorMessage ?? '',
      infoMessage: '',
      isInvalid: !!traderIdValidity.traderIdErrorMessage,
    },
    volume: {
      errorMessage: volumeValidation.volumeErrorMessage ?? '',
      infoMessage: volumeValidation.volumeDisplayMessage ?? '',
      isInvalid: !!volumeValidation.volumeErrorMessage,
    },
    price: {
      errorMessage: priceValidation.priceErrorMessage ?? '',
      infoMessage: priceValidation.priceDisplayMessage ?? '',
      isInvalid: !!priceValidation.priceErrorMessage,
    },
  };
};

const checkIfFormIsValid = (validation: Validation): boolean => {
  const invalidFields = Object.values(validation).filter(
    (field) => field.isInvalid,
  );
  return invalidFields.length === 0;
};

const collateErrorMessages = (validation: Validation): string[] =>
  Object.entries(validation).map(([key, field]) =>
    field.errorMessage ? `${_.startCase(key)}: ${field.errorMessage}` : '',
  );

const collateInfoMessages = (validation: Validation): string[] =>
  Object.entries(validation).map(([key, field]) =>
    field.infoMessage ? `${_.startCase(key)}: ${field.infoMessage}` : '',
  );

const wapForProductId = (waps: Wap[], productId: string): Wap | null =>
  waps.filter((wap) => wap.productId === productId)[0] ?? null;

const InternalAllocationTab: React.FC = () => {
  const dispatch = useDispatch();
  const windowState = useTradeAllocationWindowState();

  const selectedProduct = windowState.productList.selected;
  const selectedCounterparty = windowState.counterpartyList.selected;
  const productTimings: CalendarStartEndTimes | NullStartEndTimes =
    selectedProduct
      ? timeGetter().getCalendarTime(Product.fromId(selectedProduct.value))
      : nullStartEndTimes;

  const wapState = useWapState();

  const availableWapItems = (): WapRadioItem[] => {
    const wap = wapForProductId(wapState.waps, selectedProduct?.value ?? '');
    if (wap === null) {
      return [];
    }
    return wapRadioItems.filter((radioItem) => wap[radioItem.type] !== null);
  };

  const validation = windowState.internalAllocationForm.hasValidationTriggered
    ? performValidation(windowState)
    : blankValidation;

  const handleOnFormChange = (
    key: keyof InternalAllocationForm,
    value: string,
  ) => {
    dispatch(
      setTradeAllocationWindowState({
        ...windowState,
        internalAllocationForm: {
          ...windowState.internalAllocationForm,
          [key]: {
            ...(windowState.internalAllocationForm[key] as FormField),
            value,
          },
          hasValidationTriggered: true,
        },
      }),
    );
  };

  const handleOnEdfBuySellsChange = (selectedValue: number) => {
    dispatch(
      setTradeAllocationWindowState({
        ...windowState,
        internalAllocationForm: {
          ...windowState.internalAllocationForm,
          edfBuysOrSells: {
            ...windowState.internalAllocationForm.edfBuysOrSells,
            value: convertBuySellFromNumericToText(selectedValue),
          },
          hasValidationTriggered: true,
        },
      }),
    );
  };

  const handleOnProductListChange = (selectedValue: unknown) => {
    dispatch(
      setTradeAllocationWindowState({
        ...windowState,
        productList: {
          ...windowState.productList,
          selected: selectedValue as ProductSelectOption,
        },
      }),
    );
  };

  const handleOnCounterpartyListChange = (selectedValue: unknown) => {
    dispatch(
      setTradeAllocationWindowState({
        ...windowState,
        counterpartyList: {
          ...windowState.counterpartyList,
          selected: selectedValue as CounterpartySelectOption,
        },
        internalAllocationForm: {
          ...windowState.internalAllocationForm,
          wapSelection: {
            ...windowState.internalAllocationForm.wapSelection,
            value: null,
          },
        },
      }),
    );
  };

  const handleOnWapSelectionChange = (selectedValue: number) => {
    dispatch(
      setTradeAllocationWindowState({
        ...windowState,
        internalAllocationForm: {
          ...windowState.internalAllocationForm,
          wapSelection: {
            ...windowState.internalAllocationForm.wapSelection,
            value: selectedValue,
          },
        },
      }),
    );
  };

  const handleOnFormBlur = () => {
    dispatch(
      setTradeAllocationWindowState({
        ...windowState,
        internalAllocationForm: {
          ...windowState.internalAllocationForm,
          price: {
            ...windowState.internalAllocationForm.price,
            value: formatPrice(windowState.internalAllocationForm.price.value),
          },
          hasValidationTriggered: true,
        },
      }),
    );
  };

  const handleOnPriceFocus = () => {
    dispatch(
      setTradeAllocationWindowState({
        ...windowState,
        internalAllocationForm: {
          ...windowState.internalAllocationForm,
          wapSelection: {
            ...windowState.internalAllocationForm.wapSelection,
            value: null,
          },
        },
      }),
    );
  };

  const handleOnSubmit = async () => {
    if (windowState.internalAllocationForm.isSubmitting) {
      return;
    }
    const fromTo: CreateInternalPowerTransferFromTo =
      windowState.internalAllocationForm.edfBuysOrSells.value === 'BUY'
        ? {
            from: windowState.counterpartyList.selected
              ?.value as CreateInternalPowerTransferDirectionInput,
            to: 'EDFE',
          }
        : {
            from: 'EDFE',
            to: windowState.counterpartyList.selected
              ?.value as CreateInternalPowerTransferDirectionInput,
          };

    await dispatch(
      setTradeAllocationWindowState({
        ...windowState,
        internalAllocationForm: {
          ...windowState.internalAllocationForm,
          isSubmitting: true,
        },
      }),
    );
    await submitInternalAllocation({
      ...fromTo,
      trader: windowState.internalAllocationForm.traderId.value,
      productId: windowState.productList.selected?.value as string,
      volume: parseFloat(windowState.internalAllocationForm.volume.value),
      price: parseFloat(windowState.internalAllocationForm.price.value),
    });
    await dispatch(
      setTraderId(windowState.internalAllocationForm.traderId.value),
    );
    await dispatch(
      setTradeAllocationWindowState({
        ...windowState,
        internalAllocationForm: {
          ...windowState.internalAllocationForm,
          isSubmitting: false,
          volume: { initialValue: '', value: '' },
          price: { initialValue: '', value: '' },
        },
      }),
    );
  };

  return (
    <TabContentWrapper>
      <TabContentPrimary>
        <Grid row>
          <Grid column md={5}>
            <InputField
              type="text"
              onTextInput={(e) => handleOnFormChange('traderId', e)}
              fieldName="Trader ID"
              initialValue={windowState.internalAllocationForm.traderId.value}
              htmlId="traderId"
              hasError={validation.traderId.isInvalid}
              onBlur={handleOnFormBlur}
            />
          </Grid>
          <Grid column md={12}>
            <Divider />
          </Grid>
          <Grid column md={6}>
            <FormSection>
              Product <br />
              <SelectList
                id="product_select"
                reactSelectProps={{
                  isSearchable: false,
                  defaultValue: selectedProduct,
                  value: selectedProduct,
                  options: windowState.productList.options,
                  onChange: handleOnProductListChange,
                }}
              />
            </FormSection>
          </Grid>
          <Grid column md={7}>
            <FormSection>
              Start date & time <br />
              {formatProductTime(productTimings.start)}
            </FormSection>
          </Grid>
          <Grid column md={5}>
            <FormSection>
              End date & time <br />
              {formatProductTime(productTimings.end)}
            </FormSection>
            <FormSection />
          </Grid>
          <Grid column md={12}>
            <Divider />
          </Grid>
          <Grid column md={12}>
            <RadioButtonGroup
              id="edf_buy_or_sell"
              items={edfBuysOrSellsRadioItems}
              selectedValue={convertBuySellFromTextToNumeric(
                windowState.internalAllocationForm.edfBuysOrSells
                  .value as BuySell,
              )}
              onChange={handleOnEdfBuySellsChange}
            />
            <FlexFormSection>
              <b>EDF</b> &nbsp;
              <LabelContainer>
                <Label color="blue">
                  {windowState.internalAllocationForm.edfBuysOrSells.value ===
                  'BUY'
                    ? 'buys from'
                    : 'sells to'}
                </Label>
              </LabelContainer>
              &nbsp;
              <SelectListContainer>
                <SelectList
                  id="counterparty_select"
                  reactSelectProps={{
                    isSearchable: false,
                    defaultValue: selectedCounterparty,
                    value: selectedCounterparty,
                    options: windowState.counterpartyList.options,
                    onChange: handleOnCounterpartyListChange,
                  }}
                />
              </SelectListContainer>
            </FlexFormSection>
          </Grid>
          <Grid column md={12}>
            <Divider />
          </Grid>
          <Grid column md={5}>
            <InputField
              onTextInput={(e) => handleOnFormChange('volume', e)}
              fieldName="Volume"
              aria-label="volume"
              type="number"
              initialValue={windowState.internalAllocationForm.volume.value}
              htmlId="volume"
              units="MW"
              hasError={validation.volume.isInvalid}
              onBlur={handleOnFormBlur}
              onFocus={handleOnFormBlur}
            />
          </Grid>
          <Grid column md={1}>
            <div style={{ marginTop: 37 }}>@</div>
          </Grid>
          <Grid column md={6}>
            <PriceInputContainer>
              <InputField
                type="text"
                onTextInput={(e) => handleOnFormChange('price', e)}
                fieldName="Price"
                aria-label="price"
                htmlId="price"
                initialValue={windowState.internalAllocationForm.price.value}
                hasError={validation.price.isInvalid}
                onFocus={handleOnPriceFocus}
                onBlur={handleOnFormBlur}
              />
              <PoundSignBox>£</PoundSignBox>
            </PriceInputContainer>
            {!wapState.isLoading ? (
              <RadioButtonGroup
                id="wap_selection"
                items={availableWapItems()}
                selectedValue={
                  windowState.internalAllocationForm.wapSelection.value
                }
                onChange={handleOnWapSelectionChange}
              />
            ) : (
              <div title="Loading waps...">
                <LoadingIndicator
                  type="circle"
                  size="sm"
                  colour="blue"
                  position="left"
                />
              </div>
            )}
            {wapState.hasError && (
              <Label color="red" allowTextWrapping>
                There was an error retrieving WAPs
              </Label>
            )}
          </Grid>
          <Grid column md={12}>
            <WindowMessage
              errorMessages={collateErrorMessages(validation)}
              infoMessages={collateInfoMessages(validation)}
            />
          </Grid>
        </Grid>
      </TabContentPrimary>
      <TabContentSecondary>
        <ButtonFooter>
          <Button
            type="submit"
            label={
              !windowState.internalAllocationForm.isSubmitting
                ? 'Submit Trade'
                : 'Loading...'
            }
            size="md"
            disabled={
              !windowState.internalAllocationForm.hasValidationTriggered ||
              !checkIfFormIsValid(validation)
            }
            onClick={handleOnSubmit}
          />
        </ButtonFooter>
      </TabContentSecondary>
    </TabContentWrapper>
  );
};

export default InternalAllocationTab;
