import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

  * {
    font-family: 'Noto Sans', sans-serif;
    box-sizing: border-box;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;

    
  }

  body {
    margin: 0;
    padding: 0;
    background-color: #e5eff8;
  }

`;

export default GlobalStyle;
