import { DateTime } from 'luxon';
import { Action } from '../actions';
import { UPDATE_HEARTBEAT } from './actions';

type Status = 'HEALTHY' | 'FAILING' | 'UNKNOWN';
export type HeartBeatState = {
  status: Status;
  mostRecent: DateTime | null;
};

const initialState: HeartBeatState = <HeartBeatState>{
  status: 'UNKNOWN',
  mostRecent: null,
};

export const heartBeat = (
  state: HeartBeatState = initialState,
  action: Action,
) => {
  switch (action.type) {
    case UPDATE_HEARTBEAT: {
      const { status, dateTime } = action.payload.data.heartBeatWasCreated;

      const convertedDate = DateTime.fromISO(dateTime);

      return {
        status,
        mostRecent: convertedDate,
      };
    }
    default:
      return state;
  }
};
