import { Product } from '@edfenergy/shift-desk-efa-calendar';
import { findIndex } from 'lodash';
import { NetOpenPosition, Period } from '../store/nop/actions';

export const findSettlementPeriodIndex = (
  periods: Period[],
  settlementPeriodHhProduct: Product | null,
) => {
  if (!settlementPeriodHhProduct) {
    return -1;
  }

  const settlementPeriodId = settlementPeriodHhProduct.toId();
  const currentSettlementIndex = findIndex(periods, {
    period: {
      periodId: settlementPeriodId,
    },
  });

  return currentSettlementIndex;
};

export const removeOutOfDateSettlementPeriods = (
  data: any[],
  index: number,
) => {
  if (index > 0) {
    return data.slice(index);
  }
  return data;
};

export const removeOutOfDateSettlements = (
  data: NetOpenPosition[],
  settlement: number,
) => {
  const [currentDay] = data;
  const { periods } = currentDay;

  const currentSettlementIndex = findIndex(periods, {
    period: {
      settlementPeriod: settlement,
    },
  });

  return currentSettlementIndex;
};
