import { NopChangeset } from '../../store/nop/actions';

const MINIMUM_PERIODS_PER_DAY = 46;

// Filters changests that don't have the full number of periods for a day
export default (changesets: NopChangeset[]): NopChangeset[] =>
  changesets.filter(
    (changeset: NopChangeset) =>
      changeset.periods.length >= MINIMUM_PERIODS_PER_DAY,
  );
