import { CalendarDay } from '@edfenergy/shift-desk-efa-calendar';
import { DateTime } from 'luxon';
import clock from '../clock/clock';
import { Y_M_D } from './dateFormatter';

const ADD_1_EFA_DAY = 1;

const EFA_NEW_DAY_STARTING_HOUR = 23;

const allocateEfaDayCorrectDate = (): string => {
  const dateTime = clock().now();

  if (dateTime.hour === EFA_NEW_DAY_STARTING_HOUR) {
    return dateTime.plus({ days: ADD_1_EFA_DAY }).toISODate();
  }

  return dateTime.toISODate();
};

export const getEfaCalendarDayDisplayForTwentyThreeHundredHours = (
  date: string,
  startTime: string,
): string => {
  const convertDate = DateTime.fromFormat(date, Y_M_D).toJSDate();

  const calendarDay = new CalendarDay(convertDate);

  const startingHour = parseFloat(startTime.split(':')[0]);

  const efaDateToCalendarDate =
    calendarDay.getCalendarDateFromStartTime(startingHour);

  const convertBackToStringDate = DateTime.fromJSDate(
    efaDateToCalendarDate,
  ).toFormat(Y_M_D);
  return convertBackToStringDate;
};

export default allocateEfaDayCorrectDate;
