import { flatten, findIndex, union, isUndefined, find } from 'lodash';
import addDays from 'date-fns/addDays';
import { Product } from '@edfenergy/shift-desk-efa-calendar';
import type {
  GridSelection,
  NetOpenPosition,
  NopHorizon,
} from '../store/nop/reducer';
import { Period, EfaDay, GridSelectionMetaData } from '../store/nop/actions';

import {
  formatDateDDMmm,
  formatDateYYYYMMDD,
} from '../common/dates/dateFormatter';
import GridConstants from '../components/Grids/utils/constants';
import clock from '../common/clock/clock';
import { Owner, PortfolioName } from '../container/Tier3Nop';
import rowPriorities, { nopNames } from './rowPriorities';
import {
  findSettlementPeriodIndex,
  removeOutOfDateSettlementPeriods,
} from './utils';

export enum TableColumnNames {
  Portfolio = 'Portfolio',
  NopEdf = 'EDF NOP',
  NopWbb = 'WBB NOP',
  NopTotal = 'Total NOP',
  Cons = 'CONS NOP',
  BMU = 'BMU',
}

export enum NopType {
  EDF = 0,
  WBB = 1,
}

export enum NopTypeRowIndex {
  ALL = 0,
  EDF = 1,
  WBB = 2,
}

export enum NopOwnerName {
  EDF = 'EDF',
  WBB = 'WBB',
}

export type NopPeriodHeader = string | number | JSX.Element | PeriodHeader;
export type NopPeriodCell = string | number | JSX.Element | PeriodVolume;

export type PeriodHeader = {
  efaDate: string;
  endTime: string;
};

export type PeriodVolume = {
  efaDate: string;
  id?: string;
  product?: number;
  startTime?: string;
  endTime: string;
  volume: number | string;
  portfolio: string;
  periodId: string;
};

export type TableData = {
  headers: NopPeriodHeader[];
  rows: NopPeriodCell[][];
};

export type NopTableProps = {
  data: TableData;
};

export type TableProps = {
  headers: (PeriodHeader | TableColumnNames)[];
  rows: NopPeriodCell[][];
};

export const createCopiedNopRow = (
  horizonDataIncludingHistory: NopHorizon,
  nopName: nopNames,
) => {
  const todaysDate = clock().now().toISODate();

  const sortedHorizonData = Object.keys(horizonDataIncludingHistory)
    .sort()
    .map((date) => horizonDataIncludingHistory[date]);

  const currentDayIndex = findIndex(sortedHorizonData, {
    date: {
      efaDate: todaysDate,
    },
  });

  const horizonData = sortedHorizonData.slice(currentDayIndex);

  const buildDatesAndNop = (nopName: nopNames) => {
    const datesAndNop = flatten(
      horizonData.map((settlements: NetOpenPosition) => {
        const { periods } = settlements;
        const result = periods.map((settlement) => {
          const { nop } = settlement;
          const { position } = ['Total', 'EDF', 'WBB'].includes(nopName)
            ? nop.filter((pos) => pos.name === nopName)[0]
            : nop
                .filter((pos) => pos.name === 'EDF')[0]
                .tier2.filter(
                  (t2pos) => t2pos.provider.name === `${nopName} NOP`,
                )[0];
          const nopPosition = position.nbp;
          return nopPosition;
        });
        return flatten(result);
      }),
    );
    return datesAndNop;
  };

  return buildDatesAndNop(nopName);
};

/**
 * Creates Table headers from NetOpenPosition Schema
 * @param data
 * @param date
 * @param currentSettlementIndex
 * @returns ['15 Sep 09:00', '15 Sep 09:30', '15 Sep 10:00']
 */
export const generateTableHeaders = (
  periods: Period[],
  selectedDate: EfaDay,
  currentSettlementIndex: number,
): PeriodHeader[] => {
  const result = periods.map((settlement: Period) => {
    const { calendarDate, efaDate } = selectedDate;

    const { endTime, settlementPeriod } = settlement.period;

    const checkEndTimes =
      endTime === GridConstants.SP47endTime ||
      endTime === GridConstants.SP48endTime;

    const dateAndEndTime =
      checkEndTimes && calendarDate
        ? `${formatDateDDMmm(calendarDate)} ${endTime}`
        : `${formatDateDDMmm(efaDate)} ${endTime}`;
    return {
      efaDate,
      endTime: `${dateAndEndTime} (${settlementPeriod})`,
    };
  });

  return removeOutOfDateSettlementPeriods(result, currentSettlementIndex);
};

/**
 * Creates tier 1 row
 * @param nopType
 * @param periods
 * @param selectedDate
 * @param currentSettlementIndex
 * @returns
 */
export const generateTier1Row = (
  nopType: number,
  periods: Period[],
  selectedDate: EfaDay,
  currentSettlementIndex: number,
): PeriodVolume[] => {
  const result = periods.map((settlement): PeriodVolume => {
    const { nop } = settlement;
    const { position, name } = { ...nop[nopType] };

    const nopPosition = position ? position.nbp : GridConstants.UndefinedNop;

    return {
      efaDate: selectedDate.efaDate,
      startTime: settlement.period.startTime,
      endTime: settlement.period.endTime,
      volume: nopPosition,
      product: settlement.period.settlementPeriod,
      id: name,
      portfolio: `${name} NOP`,
      periodId: settlement.period.periodId,
    };
  });

  return removeOutOfDateSettlementPeriods(result, currentSettlementIndex);
};

/**
 * Creates total nop row
 * @param nopType
 * @param periods
 * @param selectedDate
 * @param currentSettlementIndex
 * @returns
 */
export const generateTotalNopRow = (
  periods: Period[],
  selectedDate: EfaDay,
  currentSettlementIndex: number,
): PeriodVolume[] => {
  const result = periods.map((settlement): PeriodVolume => {
    const { nop } = settlement;
    const [edf, wbb] = nop;

    const nopPosition = edf.position.nbp + wbb.position.nbp;

    return {
      efaDate: selectedDate.efaDate,
      endTime: settlement.period.endTime,
      volume: nopPosition,
      portfolio: TableColumnNames.NopTotal,
      periodId: settlement.period.periodId,
    };
  });

  return removeOutOfDateSettlementPeriods(result, currentSettlementIndex);
};

export const generateTier1ConsumptionRow = (
  periods: Period[],
  selectedDate: EfaDay,
  currentSettlementIndex: number,
): PeriodVolume[] => {
  const result = periods.map((settlement): PeriodVolume => {
    const { nop } = settlement;
    const [EDF] = nop;
    const { tier1 } = EDF;
    const [Consumption] = tier1;

    return {
      efaDate: selectedDate.efaDate,
      endTime: settlement.period.endTime,
      volume: Consumption.position.nbp,
      portfolio: TableColumnNames.Cons,
      periodId: settlement.period.periodId,
    };
  });
  return removeOutOfDateSettlementPeriods(
    flatten(result),
    currentSettlementIndex,
  );
};

const fortmatTier2Portfolio = (tier2: string, nop: string) => {
  if (nop === NopOwnerName.WBB && tier2 === 'Gas') return 'WBB';
  if (nop === NopOwnerName.WBB) return `WBB ${tier2}`;
  return tier2;
};

// ## Tier 3 portfolio rename title ##
const fortmatTier3Portfolio = (name: string, portfolio: string) => {
  if (portfolio === 'Customer_Vol' && name === 'CustomerDemand') return 'DFS';
  if (
    portfolio === 'Customer_Vol' &&
    name === 'CustomerRenewableGenerationDemand'
  )
    return 'RGFS';
  return name;
};

export const generateTier2Rows = (
  periods: Period[],
  selectedDate: EfaDay,
  currentSettlementIndex: number,
): PeriodVolume[] => {
  const tier2Positions = periods.map((settlement) =>
    settlement.nop
      .map((nop) => {
        const { tier2 } = nop;

        return tier2
          .map((t2Pos): PeriodVolume => {
            const { position, provider } = t2Pos;
            const settlementPosition = position.gate;
            return {
              efaDate: selectedDate.efaDate,
              endTime: settlement.period.endTime,
              volume: settlementPosition,
              portfolio: fortmatTier2Portfolio(provider.name, nop.name),
              periodId: settlement.period.periodId,
            };
          })
          .flat();
      })
      .flat(),
  );

  return removeOutOfDateSettlementPeriods(
    tier2Positions,
    currentSettlementIndex,
  ).flat();
};

export const generateTier3Rows = (
  periods: Period[],
  selectedDate: EfaDay,
  currentSettlementIndex: number,
  portfolio: PortfolioName,
  owner: Owner,
): PeriodVolume[] => {
  const tier3Positions = periods.map((settlement) =>
    settlement.nop
      .filter((type) => type.name === owner)
      .map((nop) => {
        const { tier2 } = nop;

        const getTier3Data = find(tier2, {
          provider: {
            name: portfolio,
          },
        });

        if (isUndefined(getTier3Data)) {
          return [];
        }

        return getTier3Data.tier3
          .map((tier3Position): PeriodVolume => {
            const { position, name } = tier3Position;
            return {
              efaDate: selectedDate.efaDate,
              endTime: settlement.period.endTime,
              volume: position.gate,
              portfolio: fortmatTier3Portfolio(name, portfolio),
              periodId: settlement.period.periodId,
            };
          })
          .flat();
      })
      .flat(),
  );

  return removeOutOfDateSettlementPeriods(
    tier3Positions,
    currentSettlementIndex,
  ).flat();
};

export const buildTableDataFormat = (
  periods: Period[],
  date: EfaDay,
  currentSettlmentPeriodIndex: number,
) => {
  const edfNopRow = generateTier1Row(
    NopType.EDF,
    periods,
    date,
    currentSettlmentPeriodIndex,
  );

  const wbbNopRow = generateTier1Row(
    NopType.WBB,
    periods,
    date,
    currentSettlmentPeriodIndex,
  );
  const edfConsumptionNopRow = generateTier1ConsumptionRow(
    periods,
    date,
    currentSettlmentPeriodIndex,
  );

  const tier2Rows = generateTier2Rows(
    periods,
    date,
    currentSettlmentPeriodIndex,
  );

  const totalNopRow = generateTotalNopRow(
    periods,
    date,
    currentSettlmentPeriodIndex,
  );

  const rows = [
    ...totalNopRow,
    ...edfNopRow,
    ...wbbNopRow,
    ...edfConsumptionNopRow,
    ...tier2Rows,
  ];

  return rows;
};

export const findDayToDisplay = (
  currentSettlementPeriodHhProduct: Product | null,
  selectedDate: string,
  data: NopHorizon,
  gridSelections: GridSelection,
): TableProps => {
  if (isUndefined(data) || isUndefined(data[selectedDate])) {
    return {
      headers: [],
      rows: [],
    };
  }

  const selectedDatesNextDay = addDays(new Date(selectedDate), 1);
  const selectedDatesNextDayFormatted =
    formatDateYYYYMMDD(selectedDatesNextDay);

  const hasNextDay = !!data[selectedDatesNextDayFormatted];

  let secondDayHeaders: PeriodHeader[] = [];
  let secondDay: PeriodVolume[] = [];

  if (hasNextDay) {
    const { date: nextDate, periods: nextPeriods } =
      data[selectedDatesNextDayFormatted];

    secondDayHeaders = generateTableHeaders(nextPeriods, nextDate, 0);

    secondDay = hasNextDay
      ? buildTableDataFormat(nextPeriods, nextDate, 0)
      : [];
  }

  const { date, periods } = data[selectedDate];

  const currentSettlmentPeriodIndex = findSettlementPeriodIndex(
    periods,
    currentSettlementPeriodHhProduct,
  );

  const firstDayHeaders = generateTableHeaders(
    periods,
    date,
    currentSettlmentPeriodIndex,
  );

  const headers = [
    TableColumnNames.Portfolio,
    ...firstDayHeaders,
    ...secondDayHeaders,
  ];

  const firstDay = buildTableDataFormat(
    periods,
    date,
    currentSettlmentPeriodIndex,
  );

  const rows = [...firstDay, ...secondDay];

  const portfolios = union(rows.map((settlement) => settlement.portfolio));

  const portfolioRows = portfolios.map((portfolio) => {
    const positions = rows
      .filter((asset) => asset.portfolio === portfolio)
      .map((settlement) => settlement);

    return [portfolio, ...positions];
  });

  const results: (string | JSX.Element | PeriodVolume)[][] = portfolioRows.sort(
    (row1, row2) => {
      const priority1 = rowPriorities[<string>row1[0]];
      const priority2 = rowPriorities[<string>row2[0]];
      return priority1 < priority2 ? -1 : 1;
    },
  );

  const filteredRows = results.filter(
    (result) =>
      gridSelections.find(
        (selection: GridSelectionMetaData) => selection.name === result[0],
      )?.value,
  );

  return {
    headers,
    rows: filteredRows,
  };
};

export const findTier3DayToDisplay = (
  currentSettlementPeriod: Product | null,
  selectedDate: string,
  data: NopHorizon,
  portfolio: PortfolioName,
  owner: Owner,
) => {
  if (isUndefined(data) || isUndefined(data[selectedDate])) {
    return {
      headers: [],
      rows: [],
    };
  }

  const selectedDatesNextDay = addDays(new Date(selectedDate), 1);
  const selectedDatesNextDayFormatted =
    formatDateYYYYMMDD(selectedDatesNextDay);

  const hasNextDay = !!data[selectedDatesNextDayFormatted];

  let secondDayHeaders: PeriodHeader[] = [];
  let secondDay: PeriodVolume[] = [];

  if (hasNextDay) {
    const { date: nextDate, periods: nextPeriods } =
      data[selectedDatesNextDayFormatted];

    secondDayHeaders = generateTableHeaders(nextPeriods, nextDate, 0);

    secondDay = hasNextDay
      ? generateTier3Rows(nextPeriods, nextDate, 0, portfolio, owner)
      : [];
  }

  const { date, periods } = data[selectedDate];

  const currentSettlmentPeriodIndex = findSettlementPeriodIndex(
    periods,
    currentSettlementPeriod,
  );

  const firstDayHeaders = generateTableHeaders(
    periods,
    date,
    currentSettlmentPeriodIndex,
  );

  const headers = [
    TableColumnNames.BMU,
    ...firstDayHeaders,
    ...secondDayHeaders,
  ];

  const firstDay = generateTier3Rows(
    periods,
    date,
    currentSettlmentPeriodIndex,
    portfolio,
    owner,
  );

  const rows = [...firstDay, ...secondDay];

  const portfolios = union(rows.map((settlement) => settlement.portfolio));

  const portfolioRows = portfolios.map((portfolio) => {
    const positions = rows
      .filter((asset) => asset.portfolio === portfolio)
      .map((settlement) => settlement);
    return [portfolio, ...positions];
  });

  return {
    headers,
    rows: portfolioRows,
  };
};
