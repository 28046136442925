import _ from 'lodash';
import totalVolumeForPrivateTrades from '../../data/totalVolumeForPrivateTrades';
import wapForPrivateTrades from '../../data/wapForPrivateTrades';
import { Action } from '../actions';
import {
  PRIVATE_TRADES_ERROR,
  SET_PRIVATE_TRADES_AS_LOADING,
  SET_PRIVATE_TRADE_AS_SUCCESSFULLY_ALLOCATED,
  SET_PRIVATE_TRADE_AS_ALLOCATION_FAILED,
  SET_PRIVATE_TRADES_AS_PENDING,
  UPDATE_PRIVATE_TRADES,
} from './actions';

export type PrivateTradesApiResponse = {
  getPrivateTrades: PrivateTrade[] | null;
};

export type PrivateTrade = {
  productId: string;
  buyOrSell: 'BUY' | 'SELL';
  price: number;
  volume: number;
  tradeExecutionTime: string;
  tradeId: string;
  allocationStatus: 'UNALLOCATED' | 'PENDING' | 'FAILED' | 'SUCCESS';
  orderId: string;
};

export type PrivateTradeOrder = {
  orderId: string;
  buyOrSell: 'BUY' | 'SELL';
  volume: number;
  wap: number;
  trades: PrivateTrade[];
};

type IndexedPrivateTradeOrders = Record<string, PrivateTradeOrder>;

export type PrivateTradesState = {
  privateTrades: PrivateTrade[];
  isLoading: boolean;
  hasError: boolean;
  errorMessage?: string;
};

export const initialState: PrivateTradesState = {
  privateTrades: [],
  isLoading: false,
  hasError: false,
};

const appendTradeToOrder = (
  order: PrivateTradeOrder | null,
  newTrade: PrivateTrade,
): PrivateTradeOrder => {
  const trades = [...(order?.trades ?? []), newTrade];
  const volume = totalVolumeForPrivateTrades(trades);
  const wap = wapForPrivateTrades(trades);
  return {
    orderId: newTrade.orderId,
    buyOrSell: newTrade.buyOrSell,
    volume,
    wap,
    trades,
  };
};

export const getPrivateTradesForProduct = (
  trades: PrivateTrade[],
  productId: string,
): PrivateTrade[] => trades.filter((trade) => trade.productId === productId);

export const groupTradesByOrder = (
  trades: PrivateTrade[],
): PrivateTradeOrder[] => {
  const indexedOrders = trades.reduce(
    (acc: IndexedPrivateTradeOrders, trade: PrivateTrade) => ({
      ...acc,
      [trade.orderId]: appendTradeToOrder(acc[trade.orderId] ?? null, trade),
    }),
    {},
  );
  return Object.values(indexedOrders);
};

export const privateTrades = (
  state: PrivateTradesState = initialState,
  action: Action,
): PrivateTradesState => {
  switch (action.type) {
    case UPDATE_PRIVATE_TRADES: {
      const { payload }: { payload: PrivateTradesApiResponse } = action;
      return {
        privateTrades:
          payload.getPrivateTrades
            ?.map((trade) => ({
              ...trade,
              volume: _.floor(trade.volume, 2),
            }))
            .filter((trade) => trade.allocationStatus !== 'SUCCESS') ?? [],
        isLoading: false,
        hasError: false,
      };
    }

    case SET_PRIVATE_TRADES_AS_PENDING: {
      const tradesToSetToPending = action.payload
        .tradesToSetToPending as string[];

      return {
        ...state,
        privateTrades: state.privateTrades.map((trade) => ({
          ...trade,
          allocationStatus: tradesToSetToPending.includes(trade.tradeId)
            ? 'PENDING'
            : trade.allocationStatus,
        })),
      };
    }

    case SET_PRIVATE_TRADES_AS_LOADING: {
      return {
        privateTrades: [],
        isLoading: true,
        hasError: false,
      };
    }

    case SET_PRIVATE_TRADE_AS_SUCCESSFULLY_ALLOCATED: {
      const { tradeId }: { tradeId: string } = action.payload;

      const remainingUnallocatedTrades = state.privateTrades.filter(
        (trade) => trade.tradeId !== tradeId,
      );

      return {
        ...state,
        privateTrades: remainingUnallocatedTrades,
      };
    }

    case SET_PRIVATE_TRADE_AS_ALLOCATION_FAILED: {
      const { tradeId }: { tradeId: string } = action.payload;

      return {
        ...state,
        privateTrades: state.privateTrades.map((trade) => ({
          ...trade,
          allocationStatus:
            trade.tradeId === tradeId ? 'FAILED' : trade.allocationStatus,
        })),
      };
    }

    case PRIVATE_TRADES_ERROR: {
      const { payload }: { payload: string } = action;
      return {
        privateTrades: [],
        isLoading: false,
        hasError: true,
        errorMessage: payload,
      };
    }
    default:
      return state;
  }
};
