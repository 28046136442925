import AWSAppSyncClient from 'aws-appsync';
import { SubscriptionInterface } from './subscription';
import { notificationWasCreatedSubscription } from '../graphql/subscriptions';
import { NopStore } from '../store';
import { updateTradingAllowedState } from '../store/tradingAllowedState/actions';
import { TradingAllowedState } from '../store/tradingAllowedState/reducer';

export type NotificationResponse = {
  data: {
    notificationWasCreated: {
      message: string;
      dateTime: string;
      type: 'OK_TO_TRADE' | 'STOP_TRADING';
      source: 'SPOTLIGHT' | 'test';
    };
  };
};

class NotificationSubscription implements SubscriptionInterface {
  private observable: any;

  private subscription: any;

  private store: NopStore;

  constructor(client: AWSAppSyncClient<any>, store: NopStore) {
    this.store = store;
    this.observable = client.subscribe({
      query: notificationWasCreatedSubscription,
    });
  }

  start(): void {
    this.subscription = this.observable.subscribe({
      next: (response: NotificationResponse) => {
        const responsePayload = response.data.notificationWasCreated;

        if (responsePayload.source === 'SPOTLIGHT') {
          const responseAsTradingAllowedState: TradingAllowedState = {
            status: responsePayload.type,
            updatedAt: responsePayload.dateTime,
          };

          this.store.dispatch(
            updateTradingAllowedState(responseAsTradingAllowedState),
          );
        }
      },
      error: (error: any) => {
        console.log(
          `Error ocurred in subscription: NotificationSubscription`,
          error,
        );
        this.restart();
      },
    });
  }

  stop(): void {
    this.subscription.unsubscribe();
  }

  restart(): void {
    this.stop();
    this.start();
  }
}

export default NotificationSubscription;
