import { DateTime } from 'luxon';
import { Action } from '../actions';
import { UPDATE_TRADING_ALLOWED_STATE } from './actions';

export type TradingAllowedState = {
  status: 'OK_TO_TRADE' | 'STOP_TRADING';
  updatedAt: string;
};

const initialState: TradingAllowedState = {
  status: 'OK_TO_TRADE',
  updatedAt: '1970-01-01T00:00:00',
};

const tradingAllowedState = (
  state: TradingAllowedState = initialState,
  action: Action,
) => {
  switch (action.type) {
    case UPDATE_TRADING_ALLOWED_STATE: {
      const { payload }: { payload: TradingAllowedState } = action;

      if (
        DateTime.fromISO(payload.updatedAt).toMillis() >
        DateTime.fromISO(state.updatedAt).toMillis()
      ) {
        return { ...payload };
      }
      return state;
    }
    default:
      return state;
  }
};

export default tradingAllowedState;
