import styled from 'styled-components';

const ClockDisplayContainer = styled.div`
  font-size: 11px;
  text-align: center;
  position: fixed;
  top: 0;
  background-color: rgba(224, 223, 147, 0.75);
  z-index: 1000;
  padding: 5px;
  border-bottom-right-radius: 6px;
  color: black;
  text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.9);
`;

export default ClockDisplayContainer;
