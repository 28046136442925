const defaultProps = {
  margin: { top: 10, right: 7.5, bottom: 52.5, left: 47.5 },
  padding: 0,
  enableGridX: false,
  enableGridY: false,
  axisTop: null,
  axisBottom: null,
  axisLeft: null,
  axisRight: null,
  borderColor: { from: 'color', modifiers: [['darker', 1.6]] },
  labelSkipWidth: 12,
  labelSkipHeight: 12,
  enableLabel: false,
  animate: false,
  valueScale: { type: 'linear', clamp: true },
  indexScale: { type: 'band', round: false },
  isInteractive: false,
};

export default defaultProps;
