import client from '../auth/client';
import store from '../store';
import SubscriptionManager from './subscriptionManager';
import gateClosuresSubscription from './gateClosuresSubscription';
import netOpenPositionSubscription from './netOpenPositionSubscription';
import SubscriptionFactory from './subscriptionFactory';
import heartBeatSubscription from './heartBeatSubscription';
import ZapiResponseQueueSubscription from './zapiResponseQueueSubscription';
import NotificationSubscription from './notificationSubscription';

const appSubscriptions = new SubscriptionManager([
  ...new SubscriptionFactory().createFromDefinitions(client, store, [
    gateClosuresSubscription,
    netOpenPositionSubscription,
    heartBeatSubscription,
  ]),
  new ZapiResponseQueueSubscription(client, store),
  new NotificationSubscription(client, store),
]);

export default appSubscriptions;
