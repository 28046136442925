import { NopChartType } from '../../../store/charting/actions';
import { NopTypeRowIndex } from '../../../data/createTableData';

const nopTypeSelection = (
  nopChartType: NopChartType,
  nopChartTypeOptions: NopChartType[],
): number =>
  (nopChartType === nopChartTypeOptions[0] && NopTypeRowIndex.EDF) ||
  (nopChartType === nopChartTypeOptions[1] && NopTypeRowIndex.WBB) ||
  NopTypeRowIndex.ALL;

export default nopTypeSelection;
