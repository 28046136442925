import { head, isUndefined, last } from 'lodash';
import { DateTime } from 'luxon';
import type { NopHorizon } from '../../store/nop/reducer';

export const findStartDate = (data: NopHorizon): DateTime => {
  const sortedDates = Object.keys(data).sort();

  const getEarliestDay = head(sortedDates);

  if (isUndefined(getEarliestDay)) {
    return DateTime.now().startOf('day');
  }

  return DateTime.fromISO(getEarliestDay);
};

export const findEndDate = (data: NopHorizon): DateTime => {
  const sortedDates = Object.keys(data).sort();

  const getFurthestDay = last(sortedDates);

  if (isUndefined(getFurthestDay)) {
    return DateTime.now().startOf('day');
  }

  return DateTime.fromISO(getFurthestDay);
};
