import { Dispatch } from 'redux';
import { DateTime } from 'luxon';
import { updateNopGridDate } from '../store/nop/actions';
import clock from './clock/clock';
import allocateEfaDayCorrectDate from './dates/efaDate';
import store from '../store';

export default (dispatch: Dispatch): void => {
  const now = clock().now();
  const isBeginningOfEfaDay =
    now.hour === 23 && now.minute === 0 && now.second >= 0 && now.second <= 10;
  if (!isBeginningOfEfaDay) {
    return;
  }
  const currentGridDate = DateTime.fromISO(
    store.getState().netOpenPosition.gridDate,
  );
  const newGridDate = DateTime.fromISO(allocateEfaDayCorrectDate());
  if (newGridDate.minus({ day: 1 }).equals(currentGridDate)) {
    dispatch(updateNopGridDate(newGridDate.toISODate()));
  }
};
