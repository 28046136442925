import {
  DefaultAligneTimeGetter,
  DefaultCalendarTimeGetter,
  DefaultEfaTimeGetter,
  productConfig,
  ProductTimeGetter,
} from '@edfenergy/shift-desk-efa-calendar';

export default () =>
  new ProductTimeGetter(
    new DefaultEfaTimeGetter(new DefaultCalendarTimeGetter(productConfig)),
    new DefaultCalendarTimeGetter(productConfig),
    new DefaultAligneTimeGetter(productConfig),
  );
