import { NopHorizon } from '../../store/nop/reducer';
import { entriesToNopHorizon, nopHorizonToEntries } from './utils';

// Truncate a horizon to be a maximum number of days.
// This is so that as new data comes in, older data falls out. So the horizon doesn't get too big.

export default (
  horizon: NopHorizon,
  maximumDaysInHorizon: number,
): NopHorizon => {
  const entries = nopHorizonToEntries(horizon);

  const limitedEntries = entries.slice(
    Math.max(entries.length - maximumDaysInHorizon, 0),
  );

  return entriesToNopHorizon(limitedEntries);
};
