import AWSAppSyncClient from 'aws-appsync';
import { Middleware } from '@chrisharrison/simple-middleware-pipeline';
import { NopStore } from '../store';
import Subscription, { SubscriptionInterface } from './subscription';

export type SubscriptionDefinition = {
  label: string;
  graphQl: string;
  reduxAction: Function;
  middleware: Middleware[];
};

class SubscriptionFactory {
  // eslint-disable-next-line class-methods-use-this
  createFromDefinitions(
    client: AWSAppSyncClient<any>,
    store: NopStore,
    definitions: SubscriptionDefinition[],
  ): Array<SubscriptionInterface> {
    return definitions.map(
      (definition) => new Subscription(client, store, definition),
    );
  }
}

export default SubscriptionFactory;
