export const API_REQUEST = 'API_REQUEST';
export interface APIRequest<Response> {
  query: string;
  variables?: Record<string, unknown>;
  onSuccess: (response: Response) => void;
  onFailure: (response: Record<string, unknown>) => void;
  label: string;
}

export const apiAction = <T>({
  query,
  variables,
  onSuccess,
  onFailure,
}: APIRequest<T>) => ({
  type: API_REQUEST,
  payload: {
    query,
    variables,
    onSuccess,
    onFailure,
  },
});
