import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import 'react-toastify/dist/ReactToastify.css';

import { useTimer } from './hooks';

import EDFAuth from './HOC/Auth';
import { incrementCurrentTime } from './store/timing/actions';

import appSubscriptions from './subscriptions/appSubscriptions';

import initialNopQuery from './initialNopQuery';
import Dashboard from './container/Dashboard';
import Tier3Nop from './container/Tier3Nop';
import ClockDisplay from './components/ClockDisplay';
import handleGridDateUpdate from './common/handleGridDateUpdate';
import initialTradingAllowedStateQuery from './initialTradingAllowedStateQuery';

appSubscriptions.start();
initialNopQuery();
initialTradingAllowedStateQuery();

const App: React.FC = () => {
  const dispatch = useDispatch();

  useTimer(10000, (payload) => {
    dispatch(incrementCurrentTime(payload));
    handleGridDateUpdate(dispatch);
  });

  return (
    <>
      <ClockDisplay />
      <EDFAuth>
        <Router>
          <Switch>
            <Route path="/tier3/:owner/:portfolio">
              <Tier3Nop />
            </Route>
            <Route path="/">
              <Dashboard />
            </Route>
          </Switch>
        </Router>
      </EDFAuth>
    </>
  );
};

export default memo(App);
