import client from './auth/client';
import { tradingAllowedStateQuery as graphqlTradingAllowedStateQuery } from './graphql/queries';
import store from './store';
import { updateTradingAllowedState } from './store/tradingAllowedState/actions';
import { TradingAllowedState } from './store/tradingAllowedState/reducer';

type TradingAllowedStateResponse = {
  getTradingAllowedState: TradingAllowedState;
};

const tradingAllowedStateQuery = async () => {
  let tradingAllowedState = null;
  try {
    tradingAllowedState = await client.query<TradingAllowedStateResponse>({
      query: graphqlTradingAllowedStateQuery,
    });
  } catch (Error) {
    // eslint-disable-next-line no-alert
    alert('Could not retrieve trading allowed status from Spotlight');
    return;
  }

  store.dispatch(
    updateTradingAllowedState(tradingAllowedState.data.getTradingAllowedState),
  );
};

export default () => {
  tradingAllowedStateQuery();
};
