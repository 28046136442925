import React, { memo, lazy, Suspense } from 'react';
import { LoadingIndicators } from '@edfenergy/shift-desk-wallace';
import { useNetOpenPositionData } from '../hooks';
import NopTable from '../components/Grids/NopTable';
import NopBarChart from '../components/Charts/NopBarChart';
import ToastContainer from '../components/ToastContainer';
import {
  DashboardContainer,
  MainArea,
  ChartWrapper,
  GridWrapper,
  GridPositionWrapper,
} from './style';
import { ScrollableDiv } from '../styles/app.styles';
import Footer from '../components/Footer';
import StopTradingRedOverlay from '../components/StopTradingRedOverlay';
import { useTradingAllowedState } from '../store/tradingAllowedState/selector';

const TradeAllocationWindow = lazy(
  () => import('../components/TradeAllocationWindow'),
);

const Dashboard: React.FC = () => {
  const { chart } = useNetOpenPositionData();
  const tradingAllowedState = useTradingAllowedState();

  document.title = 'Net Open Position - EDF Shift Trading';

  return (
    <DashboardContainer>
      <ScrollableDiv>
        <MainArea>
          {!chart.length ? (
            <LoadingIndicators
              type="circle"
              size="md"
              colour="blue"
              position="center"
            />
          ) : (
            <>
              {tradingAllowedState.status === 'STOP_TRADING' && (
                <StopTradingRedOverlay />
              )}
              <ChartWrapper>
                <NopBarChart data={chart} data-testid="NOP_bar_chart" />
              </ChartWrapper>
              <GridPositionWrapper>
                <GridWrapper>
                  <NopTable dataType="tier2" id="NOP_table" />
                </GridWrapper>
              </GridPositionWrapper>
              <Footer />
            </>
          )}
        </MainArea>
      </ScrollableDiv>
      <Suspense fallback={false}>
        <TradeAllocationWindow />
      </Suspense>
      <ToastContainer />
    </DashboardContainer>
  );
};

export default memo(Dashboard);
