// import { DateTime } from 'luxon';
import { Action } from '../actions';
import {
  SET_ZAPI_SUBMISSIONS_AS_LOADING,
  UPDATE_ZAPI_SUBMISSIONS,
  ZAPI_SUBMISSIONS_ERROR,
} from './actions';

export type ZapiSubmissionsApiResponse = {
  getZapiSubmissions: ZapiSubmission[];
};

export type ZapiSubmissionsState = {
  zapiSubmissions: ZapiSubmission[];
  isLoading: boolean;
  hasError: boolean;
  errorMessage?: string;
};

export type ZapiSubmission = {
  zapiSubmissionId: string;
  xstring: string;
  traderId: string;
  application: string;
  tradeId: string;
  counterparty: string;
  buyOrSell: string;
  volume: number;
  price: number;
  status: 'UNALLOCATED' | 'PENDING' | 'FAILED' | 'SUCCESS';
  createdAt: string;
  updatedAt: string;
};

export const initialZapiSubmissionsState: ZapiSubmissionsState = {
  zapiSubmissions: [],
  isLoading: false,
  hasError: false,
};

export const zapiSubmissions = (
  state: ZapiSubmissionsState = initialZapiSubmissionsState,
  action: Action,
): ZapiSubmissionsState => {
  switch (action.type) {
    case UPDATE_ZAPI_SUBMISSIONS: {
      const { payload }: { payload: ZapiSubmissionsApiResponse } = action;
      return {
        zapiSubmissions: payload.getZapiSubmissions ?? [],
        isLoading: false,
        hasError: false,
      };
    }
    case SET_ZAPI_SUBMISSIONS_AS_LOADING: {
      return {
        zapiSubmissions: [],
        isLoading: true,
        hasError: false,
      };
    }
    case ZAPI_SUBMISSIONS_ERROR: {
      const { payload }: { payload: string } = action;
      return {
        zapiSubmissions: [],
        isLoading: false,
        hasError: true,
        errorMessage: payload,
      };
    }
    default:
      return state;
  }
};

export default zapiSubmissions;
