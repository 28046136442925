import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import Amplify from 'aws-amplify';
import { onServiceWorkerUpdate } from '@3m1/service-worker-updater';
import {
  setLoggerClient,
  LoggerClient,
  LogLevel,
  Console,
} from '@edfenergy/shift-desk-monitoring';

import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import GlobalStyle from './theme/globalStyle';
import store from './store';

import { getGateClosures } from './store/gateClosures/actions';
import { gateClosuresQuery } from './graphql/queries';
import config from './common/config';

setLoggerClient(
  new LoggerClient(
    {
      isLocal: false,
      level: config.log.level as LogLevel, // TODO: levels don't work with Console yet - need to update monitor package
    },
    undefined,
    new Console(),
  ),
);

Amplify.configure(config.AppSync);

store.dispatch(getGateClosures(gateClosuresQuery));

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <GlobalStyle />
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: onServiceWorkerUpdate,
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
