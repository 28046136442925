import React, { memo, useState } from 'react';
import { Icon } from '@blueprintjs/core';

import convertToPasteableRow from './utils/formatter';
import { createCopiedNopRow } from '../../data/createTableData';

import { useNopState } from '../../store/nop/selector';
import { IconButton } from './style';
import { nopNames } from '../../data/rowPriorities';

export type CopyNopTableRowProps = {
  nopName: nopNames;
};

const CopyNopTableRow: React.FC<CopyNopTableRowProps> = (props) => {
  const [copiedNopRow, setCopiedNopRow] = useState<boolean>(false);
  const horizonData = useNopState();
  const { nopName } = props;
  const onClickEvent = () => {
    navigator.clipboard.writeText(
      convertToPasteableRow(
        nopName.concat(' NOP'),
        createCopiedNopRow(horizonData, nopName),
        2,
      ),
    );
    setCopiedNopRow(true);
    setTimeout(() => {
      setCopiedNopRow(false);
    }, 1500);
  };

  return (
    <IconButton type="button" onClick={onClickEvent}>
      <Icon
        icon={copiedNopRow ? 'tick' : 'duplicate'}
        size={12}
        htmlTitle="Copy NOP Row"
        style={{ pointerEvents: 'none' }}
      />
    </IconButton>
  );
};

export default memo(CopyNopTableRow);
