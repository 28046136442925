import React from 'react';
import {
  StyledFormLabel,
  StyledFormInput,
  InputContainer,
  TextLabel,
} from './style';

import InputSymbol from './InputSymbol';

export type DefaultValue = string | number;

type InputFieldType = 'text' | 'number';

export interface TextInputProps {
  onTextInput: (e: string) => void;
  fieldName: string;
  initialValue: DefaultValue;
  units?: string;
  htmlId: string;
  disabled?: boolean;
  hasError: boolean;
  type: InputFieldType;
  step?: string;
  isPersistentValue?: boolean;
  'aria-label'?: string;
  onBlur?: (e: string) => void;
  onFocus?: (e: string) => void;
}

export type StyledFormInputProps = {
  units?: string;
  hasError: boolean;
};

const InputField: React.FC<TextInputProps> = (props) => {
  const {
    onTextInput,
    type,
    step,
    fieldName,
    units,
    initialValue,
    htmlId,
    disabled,
    hasError,
    isPersistentValue,
    'aria-label': ariaLabel,
    onBlur,
    onFocus,
  } = props;
  const handleChange = (inputValue: string) => {
    if (isPersistentValue) {
      localStorage.setItem(fieldName, JSON.stringify(inputValue));
    }
    onTextInput(inputValue);
  };

  return (
    <>
      <StyledFormLabel htmlFor={htmlId}>
        <TextLabel>{fieldName}</TextLabel>
        <InputContainer>
          <StyledFormInput
            type={type}
            step={step}
            id={htmlId}
            aria-label={ariaLabel}
            value={initialValue}
            onChange={(e) => handleChange(e.target.value)}
            units={units}
            disabled={disabled}
            hasError={hasError}
            onBlur={onBlur ? (e) => onBlur(e.target.value) : undefined}
            onFocus={onFocus ? (e) => onFocus(e.target.value) : undefined}
          />
          {units ? <InputSymbol units={units} hasError={hasError} /> : null}
        </InputContainer>
      </StyledFormLabel>
    </>
  );
};

export default InputField;
