import React from 'react';
import {
  withServiceWorkerUpdater,
  ServiceWorkerUpdaterProps,
} from '@3m1/service-worker-updater';
import { Button } from '@edfenergy/shift-desk-wallace';
import AppUpdateNotificationContainer from './styles';

export const AppUpdateNotificationComponent = (
  props: ServiceWorkerUpdaterProps,
) => {
  const { newServiceWorkerDetected, onLoadNewServiceWorkerAccept } = props;
  return newServiceWorkerDetected ? (
    <AppUpdateNotificationContainer>
      <span>New version available </span>
      <Button
        label="Update"
        type="button"
        size="sm"
        onClick={onLoadNewServiceWorkerAccept}
      />
    </AppUpdateNotificationContainer>
  ) : null; // If no update is available, render nothing
};

export default withServiceWorkerUpdater(AppUpdateNotificationComponent);
