import { SubscriptionInterface } from './subscription';

class SubscriptionManager {
  subscriptions: Array<SubscriptionInterface>;

  constructor(subscriptions: Array<SubscriptionInterface>) {
    this.subscriptions = subscriptions;
  }

  start(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.start();
    });
  }

  stop(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.stop();
    });
  }

  restart(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.restart();
    });
  }
}

export default SubscriptionManager;
