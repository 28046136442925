import type { NetOpenPosition, NopHorizon } from '../../store/nop/reducer';

export type NetOpenPositionHorizonEntry = [string, NetOpenPosition];

export const nopHorizonToEntries = (
  horizon: NopHorizon,
): NetOpenPositionHorizonEntry[] => Object.entries(horizon);

export const entriesToNopHorizon = (
  entires: NetOpenPositionHorizonEntry[],
): NopHorizon =>
  entires.reduce(
    (acc: NopHorizon, [efaDate, dayData]: NetOpenPositionHorizonEntry) => ({
      ...acc,
      [efaDate]: dayData,
    }),
    {},
  );
