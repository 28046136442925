import React from 'react';
import Select, { Props as ReactSelectProps } from 'react-select';
import { SelectListContainer, reactSelectComponentStyles } from './styles';

type SelectListProps = {
  id: string;
  reactSelectProps: ReactSelectProps;
};

const SelectList: React.FC<SelectListProps> = ({ id, reactSelectProps }) => {
  const styles = { ...reactSelectComponentStyles, ...reactSelectProps.styles };
  const props = {
    ...reactSelectProps,
    styles,
  };
  return (
    <SelectListContainer data-testid={id} className="">
      <Select {...props} />
    </SelectListContainer>
  );
};

export default SelectList;
