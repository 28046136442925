import styled from 'styled-components';

const AppUpdateNotificationContainer = styled.div`
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  border-bottom: 1px solid #d5d5d5;
  background: #d9e9f8;
  padding: 5px 10px;
`;

export default AppUpdateNotificationContainer;
