import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import { Functions } from '@edfenergy/res-agentui-sso-auth';
import config from '../common/config';

const client = new AWSAppSyncClient(
  {
    url: config.AppSync.aws_appsync_graphqlEndpoint as string,
    region: config.AppSync.aws_appsync_region,
    auth: {
      type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
      jwtToken: async () => {
        const t = (await Functions.getCurrentSesssion())
          .getAccessToken()
          .getJwtToken();
        return t;
      },
    },
    disableOffline: true,
    cacheOptions: {
      addTypename: false,
    },
  },
  {
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache',
      },
    },
  },
);

export default client;
