import { logger } from '@edfenergy/shift-desk-monitoring';
import {
  useNopState,
  useHorizonWindowStatusState,
} from '../store/nop/selector';

import { createChartData } from '../data';

import { useGateClosuresState } from '../store/gateClosures/selector';
import {
  useChartRangeState,
  useChartNopTypeState,
} from '../store/charting/selector';

import { NopChartData } from '../components/Charts/NopBarChart';
import { findStartDate } from '../common/dates/nopDateFinder';
import clock from '../common/clock/clock';

type NetOpenPositionUIData = {
  chart: NopChartData[];
};

const useNetOpenPositionData = (): NetOpenPositionUIData => {
  try {
    const netOpenPositionHorizon = useNopState();
    const chartRange = useChartRangeState();
    const { currentGateDetails } = useGateClosuresState();
    const horizonWindowOn = useHorizonWindowStatusState();

    const horizonStartDate = findStartDate(netOpenPositionHorizon);

    const chartDate = horizonWindowOn
      ? clock().now().toISODate()
      : horizonStartDate.toISODate();

    const nopChartType = useChartNopTypeState();

    if (!netOpenPositionHorizon) {
      return {
        chart: [],
      };
    }

    const defaultSettlementPeriod = 1;
    const settlement = currentGateDetails?.period || defaultSettlementPeriod;

    const chart = createChartData(
      netOpenPositionHorizon,
      chartRange,
      settlement,
      horizonWindowOn,
      nopChartType,
      chartDate,
    );

    return {
      chart,
    };
  } catch (err) {
    logger.error(String(err));
    return {
      chart: [],
    };
  }
};

export default useNetOpenPositionData;
