import { Direction, getTrackBackground } from 'react-range';
import styled from 'styled-components';
import { RangeBarOrientation } from '.';

type RangeBar = {
  orientation: RangeBarOrientation;
  cursor: string;
};

type RangeBarMark = {
  orientation: RangeBarOrientation;
  index: number;
  start: number;
  end: number;
  step: number;
  colour: string;
  cursor: string;
};

type RangeBarTrackLine = {
  values: number[];
  min: number;
  max: number;
  orientation: RangeBarOrientation;
  colour: string;
  cursor: string;
};

type RangeBarThumbGrip = {
  orientation: RangeBarOrientation;
  isDragged: boolean;
  colour: string;
  cursor: string;
};

const Container = styled.div<RangeBar>`
  display: flex;

  ${({ orientation, cursor }) =>
    orientation === RangeBarOrientation.Horizontal
      ? `justify-content: 'center';
         flexWrap: 'wrap';
         cursor: ${cursor};`
      : `align-items: center;
         height: 100%;
         flex-direction: column;
         cursor: ${cursor};`}
`;

const Mark = styled.div<RangeBarMark>`
  left: 0px;
  top: 11.5px;

  ${({ orientation, index, start, end, step, colour, cursor }) =>
    `background-color: ${
      index * step > start && index * step < end ? `${colour}` : '#ccc'
    };
     cursor: ${cursor};
     width: ${orientation === RangeBarOrientation.Horizontal ? '1px' : '5px'};
     height: ${
       orientation === RangeBarOrientation.Horizontal ? '6.5px' : '1px'
     };`}
`;

const Track = styled.div<RangeBar>`
  flex-grow: 1;
  display: flex;

  ${({ orientation, cursor }) =>
    `cursor: ${cursor};
     width: ${orientation === RangeBarOrientation.Horizontal ? '100%' : '24px'};
     height: ${
       orientation === RangeBarOrientation.Horizontal ? '24px' : '100%'
     };`}
`;

const TrackLine = styled.div<RangeBarTrackLine>`
  align-self: center;

  ${({ values, min, max, orientation, colour, cursor }) =>
    `width: ${orientation === RangeBarOrientation.Horizontal ? '100%' : '1px'};
     height: ${orientation === RangeBarOrientation.Horizontal ? '1px' : '100%'};
     cursor: ${cursor};
     background: ${getTrackBackground({
       values,
       colors: ['#ccc', `${colour}`, '#ccc'],
       min,
       max,
       direction:
         orientation === RangeBarOrientation.Horizontal
           ? Direction.Right
           : Direction.Down,
     })}`}
`;

const Thumb = styled.div<RangeBar>`
  border: solid 1px silver;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  display: flex;

  ${({ orientation, cursor }) =>
    `cursor: ${cursor};
     width: ${orientation === RangeBarOrientation.Horizontal ? '16px' : '24px'};
     height: ${
       orientation === RangeBarOrientation.Horizontal ? '24px' : '16px'
     };`}
`;

const ThumbGrip = styled.div<RangeBarThumbGrip>`
  ${({ orientation, isDragged, colour, cursor }) =>
    `background-color: ${isDragged ? `${colour}` : '#CCC'};
     cursor: ${cursor};
     width: ${orientation === RangeBarOrientation.Horizontal ? '1px' : '14px'};
     height: ${
       orientation === RangeBarOrientation.Horizontal ? '14px' : '1px'
     };`}
`;

export default Container;

export { Mark, Track, TrackLine, Thumb, ThumbGrip };
