import { EfaDay } from '@edfenergy/shift-desk-efa-calendar';
import { parseISO } from 'date-fns';
import React from 'react';
import { Bars } from './ResponsiveBarChart';
import Svg, { Line } from './style';
import getAxisTicks from './chartComputations';

export type GridProps = {
  width: number;
  height: number;
  bars: Bars[];
  maxValue: number;
  minValue: number;
  verticalAxisTicks: number;
  yScale: (volume: number) => number;
};

const LineGrid: React.FC<GridProps> = (props) => {
  const { width, height, bars, minValue, maxValue, verticalAxisTicks, yScale } =
    props;
  const horizontalLineTicks = getAxisTicks(
    minValue,
    maxValue,
    verticalAxisTicks,
  );

  return (
    <>
      <Svg width={width} height={height}>
        {bars.map((bar: Bars) => {
          const { key, x, data } = bar;
          const { date, time } = data.data;
          const period = time;

          const efaBlock = new EfaDay(
            parseISO(date as string),
          ).getPeriodEfaBlock(period);

          const isSolidLine = efaBlock?.periodIndex === 1;
          const isDashedLine = efaBlock?.periodIndex === 5;

          if (isSolidLine)
            return (
              <Line
                key={key}
                opacity="1"
                x1={x}
                x2={x}
                y1="0"
                y2="100%"
                stroke="#bbbbbb"
                strokeDasharray="0"
                strokeDashoffset="0"
              />
            );

          if (isDashedLine)
            return (
              <Line
                key={key}
                opacity="1"
                x1={x}
                x2={x}
                y1="0"
                y2="100%"
                stroke="#bbbbbb"
                strokeDasharray="6 4"
                strokeDashoffset="3"
              />
            );
          return <></>;
        })}

        {horizontalLineTicks.map((tick: number) => (
          <Line
            key={tick}
            x1={0}
            x2={width}
            y1={yScale(tick)}
            y2={yScale(tick)}
            stroke="#cccccc"
            strokeDasharray="2 2"
            strokeDashoffset="0"
          />
        ))}
      </Svg>
    </>
  );
};

export default LineGrid;
