import { Action } from '../actions';
import { SET_TRADER_ID } from './actions';

export type UserState = {
  traderID: string;
};

const initialState = <UserState>{
  traderID: '',
};

export const user = (state: UserState = initialState, action: Action) => {
  switch (action.type) {
    case SET_TRADER_ID: {
      const { payload } = action;

      return { ...state, traderID: payload };
    }
    default:
      return state;
  }
};
