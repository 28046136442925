import { Product } from '@edfenergy/shift-desk-efa-calendar';
import { Action, Dispatch } from 'redux';
import { InternalTransferCounterparty } from '../../components/TradeAllocationWindow/Tabs/InternalAllocation/submitInternalAllocation';
import setWapBasedOnSelection from '../../data/setWapBasedOnSelection';
import suggestInternalAllocationVolumeAndSellDirection from '../../data/suggestInternalAllocationVolumeAndSellDirection';
import AppState from '../AppState';

import store from '../index';
import {
  setTradeAllocationWindowState,
  SET_TRADE_ALLOCATION_WINDOW_STATE,
} from '../tradeAllocation/actions';
import { TradeAllocationWindowState } from '../tradeAllocation/reducer';

interface MiddleWareAction extends Action {
  payload: TradeAllocationWindowState;
}

const calculateVolumeAndSellDirection = (
  payload: TradeAllocationWindowState,
  counterparty?: InternalTransferCounterparty,
): TradeAllocationWindowState => {
  const { netOpenPosition } = store.getState() as AppState;

  const suggestedVolumeAndDirection =
    suggestInternalAllocationVolumeAndSellDirection(
      netOpenPosition.nop,
      Product.fromId(payload.productList.selected?.value as string),
      counterparty,
    );

  const volume = (suggestedVolumeAndDirection.volume ?? 0).toString();
  const { edfBuysOrSells } = suggestedVolumeAndDirection;

  return {
    ...payload,
    internalAllocationForm: {
      ...payload.internalAllocationForm,
      volume: {
        ...payload.internalAllocationForm.volume,
        value: volume,
        initialValue: volume,
      },
      edfBuysOrSells: {
        ...payload.internalAllocationForm.edfBuysOrSells,
        value: edfBuysOrSells,
      },
      price: {
        ...payload.internalAllocationForm.price,
        value: '',
      },
    },
  };
};

const setTradeAllocationWindowStateMiddleware =
  () =>
  (next: Dispatch<MiddleWareAction>) =>
  async (action: MiddleWareAction) => {
    if (action.type !== SET_TRADE_ALLOCATION_WINDOW_STATE) {
      return next(action);
    }

    const { payload } = action;

    const selectedProduct = payload.productList.selected?.value;
    const selectedWap = payload.internalAllocationForm.wapSelection.value;
    const selectedCounterparty = payload.counterpartyList.selected?.value as
      | InternalTransferCounterparty
      | undefined;

    const { tradeAllocation, wapState } = store.getState() as AppState;

    const hasSelectedProductChanged =
      selectedProduct !== tradeAllocation.productList.selected?.value;

    const hasWapSelectionChanged =
      selectedWap !== tradeAllocation.internalAllocationForm.wapSelection.value;

    const hasCounterpartySelectionChanged =
      selectedCounterparty !== tradeAllocation.counterpartyList.selected?.value;

    // re-calculate suggested volume if selected product or counterparty has changed
    const stateChangeForVolumeSuggestion =
      selectedProduct !== undefined &&
      (hasSelectedProductChanged || hasCounterpartySelectionChanged)
        ? calculateVolumeAndSellDirection(payload, selectedCounterparty)
        : {};

    const stateChangeForWapSuggestion =
      (selectedWap !== null && hasWapSelectionChanged) ||
      (hasSelectedProductChanged &&
        payload.internalAllocationForm.wapSelection.value !== null)
        ? setWapBasedOnSelection(wapState.waps, payload, selectedWap)
        : {};

    return next(
      setTradeAllocationWindowState({
        ...payload,
        ...stateChangeForVolumeSuggestion,
        ...stateChangeForWapSuggestion,
      }),
    );
  };

export default setTradeAllocationWindowStateMiddleware;
