import React, { useState, memo } from 'react';
import { AuthContainer } from '@edfenergy/res-agentui-sso-auth';
import { useDispatch } from 'react-redux';
import { Header, Toggle } from '@edfenergy/shift-desk-wallace';

import config from '../common/config';
import clock from '../common/clock/clock';
import StatusPanel from '../components/Status';

import { nopUpdateWindowStatus, updateNopGridDate } from '../store/nop/actions';
import { useNopState } from '../store/nop/selector';

import { findStartDate } from '../common/dates/nopDateFinder';
import AppUpdateNotification from '../components/AppUpdateNotification';
import NetworkStatusIndicator from '../components/NetworkStatusIndicator';
import {
  HeaderToolBarContainer,
  HeaderToolBarItem,
  MainLayoutContainer,
  HeaderContainer,
  DashboardContainer,
} from './style';

type AuthProps = {
  children: React.ReactNode;
};

const Auth = ({ children }: AuthProps) => {
  const dispatch = useDispatch();
  const [horizonWindowOn, setHorizonWindowFlag] = useState(true);

  const nopData = useNopState();

  const toggleHorizonWindow = () => {
    setHorizonWindowFlag(!horizonWindowOn);
    dispatch(nopUpdateWindowStatus(!horizonWindowOn));

    const selectedDate = horizonWindowOn
      ? findStartDate(nopData)
      : clock().now();

    dispatch(updateNopGridDate(selectedDate.toISODate()));
  };

  return (
    <AuthContainer
      autoLogin
      amplifyConfig={config}
      renderer={({ errorMessage, defaultRenderer }) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {errorMessage ? (
            <div style={{ width: 500, height: 200 }}>
              <p>
                You don&apos;t appear to have access to the active directory
                needed to use Shift Desk Platform. Don&apos;t worry, this is
                easy to fix. Please raise a request via{' '}
                <a href="https://edfenergy.service-now.com/myit/?id=sc_cat_item&sys_id=436ba7badb244c10b33d2b43059619bb">
                  Service Now
                </a>
                .
              </p>
              <p>
                If you require any assistance with the raising of the request
                then please contact the{' '}
                <a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}>
                  {process.env.REACT_APP_SUPPORT_EMAIL}
                </a>
                .
              </p>
            </div>
          ) : (
            <div
              style={{
                width: 500,
                height: 200,
                textAlign: 'center',
                marginTop: '30px',
              }}
            >
              {defaultRenderer()}
            </div>
          )}
        </div>
      )}
    >
      <MainLayoutContainer>
        <HeaderContainer>
          <Header id="NOP_header" size="xs">
            <HeaderToolBarContainer>
              <HeaderToolBarItem>
                <NetworkStatusIndicator />
              </HeaderToolBarItem>
              <HeaderToolBarItem>
                <StatusPanel />
              </HeaderToolBarItem>
              <HeaderToolBarItem>
                <Toggle
                  size="sm"
                  state={horizonWindowOn}
                  name="nop_data_toggle"
                  id="nop_data_toggle"
                  action={toggleHorizonWindow}
                  text="Chase"
                />
              </HeaderToolBarItem>
            </HeaderToolBarContainer>
          </Header>
        </HeaderContainer>
        <AppUpdateNotification />
        <DashboardContainer>{children}</DashboardContainer>
      </MainLayoutContainer>
    </AuthContainer>
  );
};

export default memo(Auth);
