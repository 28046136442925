import styled from 'styled-components';

enum TableCellHighlight {
  Highlight1,
  Highlight2,
  Highlight3,
}

type TableContainerProps = {
  minWidth?: number;
};

type TableCellProps = {
  active?: boolean;
  selected?: boolean;
  firstColumnSeparate?: boolean;
  highlight?: TableCellHighlight;
  freezeFirstColumn?: boolean;
  minCellWidth?: number;
};

type TableRowProps = {
  isHighlighted?: boolean;
};

type TableHeaderProps = {
  highlight?: TableCellHighlight;
  freezeFirstColumn?: boolean;
  minHeaderCellWidth?: number;
};

const white = '#FFFFFF';
const lightGrey = '#E5E5E5';
const amber = '#FF9E24';

const highlight1 = '#188CFC';
const highlight2 = '#D9E9F8';
const highlight3 = '#DADADA';

const TableContainer = styled.table<TableContainerProps>`
  border-collapse: collapse;
  color: #${({ theme }) => theme.colors.table.internal.text};

  border-spacing: 0;

  ${({ minWidth }) =>
    minWidth &&
    `
    min-width: ${minWidth}px;
  `}
`;

const THead = styled.thead`
  background: #${({ theme }) => theme.colors.table.internal.header};
  box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.25);
`;

const Th = styled.th<TableHeaderProps>`
  ${({ minHeaderCellWidth }) =>
    minHeaderCellWidth &&
    `
  min-width: ${minHeaderCellWidth}px;
  `}
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  align-items: center;
  letter-spacing: 0.25px;
  text-align: right;
  padding-left: 5px;

  background: #${({ theme }) => theme.colors.table.internal.header};
  box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.25);

  ${({ highlight }) =>
    highlight === TableCellHighlight.Highlight1 &&
    `
    border: solid ${highlight1};
    border-width: 1.5px 1.5px 0px 1.5px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  `};

  ${({ highlight }) =>
    highlight === TableCellHighlight.Highlight2 &&
    `
    background: ${highlight2};
  `};

  ${({ highlight }) =>
    highlight === TableCellHighlight.Highlight3 &&
    `
    background: ${highlight3};
  `};

  ${({ freezeFirstColumn }) =>
    freezeFirstColumn &&
    `
    &:first-child {
      position: sticky;
      left: 0;
      z-index: 2 !important;
    }
  `}
`;

const TBody = styled.tbody``;

const Tr = styled.tr<TableRowProps>`
  background-color: #${({ theme }) => theme.colors.table.internal.row};

  ${({ isHighlighted }) =>
    isHighlighted &&
    `
    font-weight: bold;
  `}

  &:nth-child(even) {
    background-color: #f1f1f1;
  }
`;

const Td = styled.td<TableCellProps>`
  ${({ minCellWidth }) =>
    minCellWidth &&
    `
  min-width: ${minCellWidth}px;
  `}
  font-style: inherit;
  font-weight: inherit;
  font-size: 14px;
  line-height: 30px;
  align-items: center;
  text-align: right;
  padding-left: 5px;

  ${({ active }) =>
    active &&
    `
    background: #FFE1A3;
    box-shadow: inset 0px -1px 0px ${lightGrey};
  `}

  ${({ selected }) =>
    selected &&
    `
    box-shadow: inset -2px -2px 0px ${amber}, inset 2px 2px 0px ${amber};
  `}

  ${({ highlight }) =>
    highlight === TableCellHighlight.Highlight1 &&
    `
    border: solid ${highlight1};
    border-width: 0px 1.5px 0px 1.5px;
    box-shadow: inset 0px -1px 0px ${lightGrey};
  `}

  ${({ highlight }) =>
    highlight === TableCellHighlight.Highlight2 &&
    `
    background: ${highlight2};
    box-shadow: inset 0px -1px 0px ${lightGrey};
  `}

  ${({ highlight }) =>
    highlight === TableCellHighlight.Highlight3 &&
    `
    background: ${highlight3};
    box-shadow: inset 0px -1px 0px ${lightGrey};
  `}

  position: sticky;
  left: 0;
  z-index: 1;

  ${({ firstColumnSeparate }) =>
    firstColumnSeparate &&
    `
    &:first-child {
      background-color: ${white};
      font-weight: bold;
      text-align: left;
      padding-left: 10px;
      padding-right: 10px;
      border-bottom: 1px solid #D3D3D3;
      border-right: 1px solid #D3D3D3;
      min-width: 156px;
    }
  `}

  ${({ freezeFirstColumn }) =>
    freezeFirstColumn &&
    `
    &:first-child {
      position: sticky;
      left: 0;
      z-index: 2 !important;
    }
  `}
`;

export default TableContainer;

export { TableContainer, Th, THead, TBody, Tr, Td, TableCellHighlight };

export type { TableContainerProps, TableCellProps };
