import { LoadingIndicators } from '@edfenergy/shift-desk-wallace';
import styled from 'styled-components';

export const ScrollableDiv = styled.div`
  overflow: auto;
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    background-color: #d3d3d3;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #969595;
  }
  width: 100%;
`;

export const LoadingIndicator = styled(LoadingIndicators)`
  margin-top: 0;
  margin-bottom: 0;
`;
