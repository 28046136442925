import styled from 'styled-components';

type LineProps = {
  stroke: any;
  strokeDasharray: string;
  strokeDashoffset: string;
};

type IsPosititveNegativeNumber = { positionValue: number };

type ChartMarkerProps = {
  colour: string;
};

type TooltipProps = {
  height: number;
};

const Svg = styled.svg``;

const Rect = styled.rect`
  fill: none;
  stroke-width: 1;
  stroke: #e5e5e5;
`;

const ChartMarker = styled.rect<ChartMarkerProps>`
  fill: #${({ colour }) => colour};
  opacity: 0.5;
  stroke-width: 0;
`;

const Line = styled.line<LineProps>`
  stroke: ${({ stroke }) => stroke};
  stroke-width: 1;
  stroke-dasharray: ${({ strokeDasharray }) => strokeDasharray};
  stroke-dashoffset: ${({ strokeDashoffset }) => strokeDashoffset};
`;

const Text = styled.text`
  fill: #333;
  font-size: 0.7rem;
`;

const TSpan = styled.tspan`
  fill: #333;
  font-size: 0.7rem;
`;

const CurrentTimeLine = styled.line`
  stroke: #188cfc;
  stroke-width: 2;
`;

const checkIfPositiveOrNegativeNumber = (value: number): boolean =>
  Math.sign(value) < 0;

const ToolTip = styled.rect<TooltipProps>`
  width: 150px;
  height: ${({ height }) => height}px;
  fill: #ffffff;
  color: #000000;
  shape-rendering: crispEdges;
  z-index: 2 !important;
  stroke: #dadada;
  stroke-width: 1;
`;

const ToolTipTSpan = styled.tspan<IsPosititveNegativeNumber>`
  fill: ${({ positionValue }) =>
    checkIfPositiveOrNegativeNumber(positionValue) ? '#F60804' : '#000'};
`;

export default Svg;

export {
  Svg,
  Rect,
  Line,
  Text,
  TSpan,
  ChartMarker,
  CurrentTimeLine,
  ToolTip,
  ToolTipTSpan,
};
