import React from 'react';
import getAxisTicks from './chartComputations';
import { Text } from './style';

export interface VerticalAxisProps {
  height: number;
  yScale: (volume: number) => number;
  minValue: number;
  maxValue: number;
  verticalAxisTicks: number;
}

const VerticalAxis: React.FC<VerticalAxisProps> = (props) => {
  const { height, yScale, minValue, maxValue, verticalAxisTicks } = props;

  const ticks = getAxisTicks(minValue, maxValue, verticalAxisTicks);

  return (
    <>
      <g>
        <Text
          x="0"
          y="0"
          dy="0"
          textAnchor="middle"
          dominantBaseline="central"
          transform={`translate(${-45},${height / 2}) rotate(-90)`}
        >
          MW
        </Text>

        {ticks
          .map((tick) => ({ tick, y: yScale(tick) }))
          .filter((yTick) => yTick.y < height && yTick.y > 0)
          .map((yTick) => (
            <g key={yTick.tick}>
              <line x1={-5} x2={0} y1={yTick.y} y2={yTick.y} stroke="#bbbbbb" />
              <Text
                x="0"
                y="0"
                dy="0"
                textAnchor="end"
                dominantBaseline="central"
                transform={`translate(${-10},${yTick.y})`}
              >
                {yTick.tick}
              </Text>
            </g>
          ))}
      </g>
    </>
  );
};

export default VerticalAxis;
