import React, { useState } from 'react';
import { DateTime } from 'luxon';
import NetworkStatusIndicatorContainer from './style';
import { determineConnectionLevel } from '.';
import { useHeartBeatState } from '../../store/heartbeat/selector';
import { useTimer } from '../../hooks';
import clock from '../../common/clock/clock';

const WEAK_CONNECTION_SECONDS = 60;
const NONE_CONNECTION_SECONDS = 120;

// eslint-disable-next-line
export enum ConnectionLevel {
  // eslint-disable-next-line
  Good = 'Connected',
  // eslint-disable-next-line
  Weak = 'Checking Connection',
  // eslint-disable-next-line
  None = 'Disconnected',
  // eslint-disable-next-line
  Initializing = 'Initializing',
}

const NetworkStatusIndicator = () => {
  const { mostRecent } = useHeartBeatState();
  const [time, setTime] = useState<DateTime>(clock().now().toLuxon());
  useTimer(1000, () => {
    setTime(clock().now().toLuxon());
  });
  const connectionLevel = determineConnectionLevel(
    mostRecent,
    time,
    WEAK_CONNECTION_SECONDS,
    NONE_CONNECTION_SECONDS,
  );

  return (
    <NetworkStatusIndicatorContainer
      connectionLevel={connectionLevel}
      title={connectionLevel}
    />
  );
};
export default NetworkStatusIndicator;
