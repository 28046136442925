import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Wallace as WallaceWrapper } from '@edfenergy/shift-desk-wallace';
import {
  TableContainer,
  THead,
  Th,
  TBody,
  Tr,
  Td,
  TableCellHighlight,
} from './styles';

export interface TableProps<THeader, TCell> {
  id: string;
  headers: (string | number | JSX.Element | THeader)[];
  rows: (string | number | JSX.Element | TCell)[][];
  minWidth?: number;
  minHeaderCellWidth?: number;
  minCellWidth?: number;
  active?: boolean;
  firstColumnSeparate?: boolean;
  // eslint-disable-next-line no-unused-vars
  getHeaderValue?: (header: THeader) => string | number | JSX.Element;
  // eslint-disable-next-line no-unused-vars
  getCellValue?: (cell: TCell) => string | number | JSX.Element;
  getKeyForHeader?: (index: number) => string;
  getKeyForRow?: (index: number) => string;
  getKeyForCell?: (rowIndex: number, cellIndex: number) => string;
  getHeaderCellHighlight?: // eslint-disable-next-line no-unused-vars
  | ((
        header: string | number | JSX.Element | THeader,
      ) => TableCellHighlight | undefined)
    | null;
  getBodyCellHighlight?: // eslint-disable-next-line no-unused-vars
  | ((
        cell: string | number | JSX.Element | TCell,
      ) => TableCellHighlight | undefined)
    | null;
  freezeFirstColumn?: boolean;
  rowIndexHighlight?: number;
}

const Table = <THeader extends any, TCell extends any>(
  props: TableProps<THeader, TCell>,
) => {
  const {
    id,
    headers,
    rows,
    minWidth,
    minHeaderCellWidth,
    minCellWidth,
    active,
    firstColumnSeparate,
    getHeaderValue,
    getCellValue,
    getKeyForHeader,
    getKeyForRow,
    getKeyForCell,
    getHeaderCellHighlight,
    getBodyCellHighlight,
    freezeFirstColumn,
    rowIndexHighlight,
  } = props;

  return (
    <WallaceWrapper>
      <TableContainer data-testid={id} minWidth={minWidth}>
        <THead>
          <Tr>
            {headers.map((header, headerIndex) => {
              const keyForHeader = getKeyForHeader
                ? getKeyForHeader(headerIndex)
                : `headers.map_${headerIndex}_${uuidv4()}`;
              return (
                <Th
                  key={keyForHeader}
                  highlight={
                    getHeaderCellHighlight
                      ? getHeaderCellHighlight(header)
                      : undefined
                  }
                  freezeFirstColumn={freezeFirstColumn}
                  data-testid={`${id}_th_${headerIndex}`}
                  minHeaderCellWidth={minHeaderCellWidth}
                >
                  {!getHeaderValue ||
                  typeof header === 'number' ||
                  typeof header === 'string' ||
                  React.isValidElement(header as JSX.Element)
                    ? (header as string | number | JSX.Element)
                    : getHeaderValue(header as THeader)}
                </Th>
              );
            })}
          </Tr>
        </THead>
        <TBody>
          {rows.map((row, rowIndex) => {
            const keyForRow = getKeyForRow
              ? getKeyForRow(rowIndex)
              : `firstRowCell_${rowIndex}_${uuidv4()}`;
            const isHighlighted = rowIndexHighlight === rowIndex;
            return (
              <Tr
                key={keyForRow}
                data-testid={`${id}_tr_${rowIndex}`}
                isHighlighted={isHighlighted}
              >
                {row.map((cell, cellIndex) => {
                  const keyForCell = getKeyForCell
                    ? getKeyForCell(rowIndex, cellIndex)
                    : `row.map_${rowIndex}_${cellIndex}_${uuidv4()}`;

                  return (
                    <Td
                      key={keyForCell}
                      data-testid={`${id}_td_${rowIndex}_${cellIndex}`}
                      active={active}
                      firstColumnSeparate={firstColumnSeparate}
                      highlight={
                        getBodyCellHighlight
                          ? getBodyCellHighlight(cell)
                          : undefined
                      }
                      freezeFirstColumn={freezeFirstColumn}
                      minCellWidth={minCellWidth}
                    >
                      {!getCellValue ||
                      typeof cell === 'number' ||
                      typeof cell === 'string' ||
                      React.isValidElement(cell as JSX.Element)
                        ? (cell as string | number | JSX.Element)
                        : getCellValue(cell as TCell)}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </TBody>
      </TableContainer>
    </WallaceWrapper>
  );
};

export default Table;
