import React, { memo, useState } from 'react';
import { Range, Direction } from 'react-range';
import { RangePercent } from '../../store/charting/actions';
import Container, { Mark, Thumb, ThumbGrip, Track, TrackLine } from './styles';
import { NopChartActiveControls } from '../Charts/NopBarChart';

export enum RangeBarOrientation {
  Horizontal,
  Vertical,
}

type RangeBarProps = {
  orientation: RangeBarOrientation;
  startPercent: number;
  endPercent: number;
  stepPercent: number;
  onChange: (range: RangePercent) => void;
  colour: string;
  cursor: string;
};

const RangeBar: React.FC<RangeBarProps> = (props: RangeBarProps) => {
  const {
    orientation,
    startPercent,
    endPercent,
    stepPercent,
    onChange,
    colour,
    cursor,
  } = props;

  const values = [startPercent, endPercent];

  const [grabber, setGrabber] = useState(NopChartActiveControls.scrollControl);

  const min = 0;
  const max = 100;

  return (
    <Container orientation={orientation} cursor={cursor}>
      <Range
        draggableTrack
        direction={
          orientation === RangeBarOrientation.Horizontal
            ? Direction.Right
            : Direction.Down
        }
        values={values}
        step={stepPercent}
        min={min}
        max={max}
        onChange={(values: number[]) => {
          const [start, end] = values;
          onChange({ start, end });
        }}
        renderMark={({ props, index }) => (
          <Mark
            {...props}
            style={{
              ...props.style,
            }}
            orientation={orientation}
            index={index}
            start={values[0]}
            end={values[1]}
            step={stepPercent}
            colour={colour}
            cursor={cursor}
          />
        )}
        renderTrack={({ props, children, isDragged }) => (
          <Track
            cursor={cursor}
            orientation={orientation}
            role="none"
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            onMouseDownCapture={() =>
              setGrabber(NopChartActiveControls.scrollingControl)
            }
            onMouseUpCapture={() =>
              setGrabber(NopChartActiveControls.scrollControl)
            }
            style={{
              ...props.style,
              cursor:
                (isDragged &&
                  orientation === RangeBarOrientation.Vertical &&
                  NopChartActiveControls.arrowVertical) ||
                (isDragged &&
                  orientation === RangeBarOrientation.Horizontal &&
                  NopChartActiveControls.arrowHorizontal) ||
                (grabber === NopChartActiveControls.scrollControl &&
                  NopChartActiveControls.scrollControl) ||
                NopChartActiveControls.scrollingControl,
            }}
          >
            <TrackLine
              ref={props.ref}
              orientation={orientation}
              values={values}
              min={min}
              max={max}
              colour={colour}
              cursor={cursor}
            >
              {children}
            </TrackLine>
          </Track>
        )}
        renderThumb={({ props, isDragged }) => (
          <Thumb
            {...props}
            orientation={orientation}
            cursor={cursor}
            style={{
              ...props.style,
              cursor:
                (orientation === RangeBarOrientation.Vertical &&
                  NopChartActiveControls.arrowVertical) ||
                NopChartActiveControls.arrowHorizontal,
            }}
          >
            <ThumbGrip
              orientation={orientation}
              isDragged={isDragged}
              colour={colour}
              cursor={cursor}
              style={{
                ...props.style,
                cursor:
                  (orientation === RangeBarOrientation.Vertical &&
                    NopChartActiveControls.arrowVertical) ||
                  NopChartActiveControls.arrowHorizontal,
              }}
            />
          </Thumb>
        )}
      />
    </Container>
  );
};

export default memo(RangeBar);
