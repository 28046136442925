import { DateTime } from 'luxon';
import { Clock, AdvancingClock } from '@edfenergy/shift-desk-qa-clock-change';
import { CalendarDateTime } from '@edfenergy/shift-desk-efa-calendar';
import overrideTime from './overrideTime';

export const CLOCK_OVERRIDE_ENV_VAR_NAME =
  'REACT_APP_CLOCK_STARTING_TIME_OVERRIDE';

const dateTimeFromEnv = (): DateTime => {
  const timeString = (() => {
    if (overrideTime) {
      return overrideTime;
    }
    return process.env[CLOCK_OVERRIDE_ENV_VAR_NAME];
  })();
  return DateTime.fromISO(String(timeString), { zone: 'UTC' });
};

const determineTimeFromEnvironment = (): CalendarDateTime => {
  const dateTime = dateTimeFromEnv();

  if (dateTime.isValid) {
    return new CalendarDateTime(dateTime);
  }
  return new CalendarDateTime(DateTime.now());
};

const timeFromEnvironment = determineTimeFromEnvironment();
const appInstantiationDate = new CalendarDateTime(DateTime.now());

const clock = (reboot: boolean = false): Clock => {
  if (reboot) {
    return new AdvancingClock(
      determineTimeFromEnvironment(),
      new CalendarDateTime(DateTime.now()),
    );
  }
  return new AdvancingClock(timeFromEnvironment, appInstantiationDate);
};

export const isClockMocked = (): boolean => dateTimeFromEnv().isValid;

export default clock;
