/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Form, Label, Radio } from './styles';

interface ToggleRadioButtonProps {
  checked: boolean;
  text: string;
}

interface ToggleRadioButtonGroupProps<
  TToggleRadioButtonProps extends ToggleRadioButtonProps,
> {
  items: TToggleRadioButtonProps[];
  onChange: (item: TToggleRadioButtonProps, checked: boolean) => void;
}

const ToggleRadioButtonGroup = <
  TToggleRadioButtonProps extends ToggleRadioButtonProps,
>(
  props: ToggleRadioButtonGroupProps<TToggleRadioButtonProps>,
) => {
  const { items, onChange } = props;

  return (
    <Form>
      {items.map((item, i) => {
        const key = `${item.text.replace(/[^a-z0-9]/gi, '')}_${i}`;
        const radioId = `toggleRadioButtonGroup_item_${key}`;
        return (
          <React.Fragment key={key}>
            <Radio
              data-testid={`Horizon_${item.text}`}
              type="radio"
              name="itemGroup"
              id={radioId}
              checked={!!item.checked}
              onChange={(e) => onChange(item, e.target.checked)}
            />
            <Label htmlFor={radioId}>{item.text}</Label>
          </React.Fragment>
        );
      })}
    </Form>
  );
};

export default ToggleRadioButtonGroup;
export type { ToggleRadioButtonGroupProps };
