import styled from 'styled-components';

const ToolBarContainer = styled.div`
  font-size: 12px;
  background: #f5f5f5;
  align-items: center;
  display: flex;

  > * {
    margin: 0px 7.5px 0px 0px;

    &:last-child {
      margin: 0px 0px 0px 0px;
    }
  }
`;

export default ToolBarContainer;
