import { InternalTransferCounterparty } from '../../components/TradeAllocationWindow/Tabs/InternalAllocation/submitInternalAllocation';
import { Action } from '../actions';
import {
  CLEAR_SELECTED_TRADES,
  SET_TRADE_ALLOCATION_WINDOW_STATE,
} from './actions';

export type FormField = {
  initialValue: string;
  value: string;
};

export type ProductSelect = {
  selected?: ProductSelectOption;
  options: ProductSelectOption[];
};

export type CounterpartySelect = {
  selected?: CounterpartySelectOption;
  options: CounterpartySelectOption[];
};

export type ProductSelectOption = {
  value: string;
  label: string;
};

export type CounterpartySelectOption = {
  value: InternalTransferCounterparty;
  label: string;
};

export type InternalAllocationForm = {
  traderId: FormField;
  edfBuysOrSells: FormField;
  volume: FormField;
  price: FormField;
  wapSelection: WapFormSelection;
  hasValidationTriggered: boolean;
  isSubmitting?: boolean;
};

export type PxAllocationForm = {
  selectedTradeIds: string[];
  isSubmitting: boolean;
  totalVolumeToAllocateToWbb: FormField;
  totalVolumeToAllocateToBatteries: FormField;
  totalVolumeToAllocateToGasPeaker: FormField;
  hasValidationTriggered: boolean;
};

export type WapType = 'publicWap' | 'privateBuyWap' | 'privateSellWap';

export type WapFormSelection = {
  initialValue: number | null;
  value: number | null;
};

export type ValidationField = { isInvalid: boolean; message: string };

export type TradeAllocationWindowState = {
  isWindowOpen: boolean;
  productId: string | null;
  productList: ProductSelect;
  counterpartyList: CounterpartySelect;
  internalAllocationForm: InternalAllocationForm;
  pxAllocationForm: PxAllocationForm;
};

export const initialTradeAllocationWindowState: TradeAllocationWindowState = {
  isWindowOpen: false,
  productId: null,
  productList: {
    selected: undefined,
    options: [],
  },
  counterpartyList: {
    selected: undefined,
    options: [],
  },
  internalAllocationForm: {
    traderId: {
      initialValue: '',
      value: '',
    },
    edfBuysOrSells: {
      initialValue: '',
      value: '',
    },
    volume: {
      initialValue: '',
      value: '',
    },
    price: {
      initialValue: '',
      value: '',
    },
    wapSelection: {
      initialValue: null,
      value: null,
    },
    hasValidationTriggered: false,
    isSubmitting: false,
  },
  pxAllocationForm: {
    selectedTradeIds: [],
    isSubmitting: false,
    totalVolumeToAllocateToWbb: {
      initialValue: '',
      value: '',
    },
    totalVolumeToAllocateToBatteries: {
      initialValue: '',
      value: '',
    },
    totalVolumeToAllocateToGasPeaker: {
      initialValue: '',
      value: '',
    },
    hasValidationTriggered: false,
  },
};

export const tradeAllocation = (
  state: TradeAllocationWindowState = initialTradeAllocationWindowState,
  action: Action,
): TradeAllocationWindowState => {
  switch (action.type) {
    case SET_TRADE_ALLOCATION_WINDOW_STATE: {
      const { payload } = action;
      return payload;
    }
    case CLEAR_SELECTED_TRADES: {
      return {
        ...state,
        pxAllocationForm: {
          ...state.pxAllocationForm,
          selectedTradeIds: [],
          totalVolumeToAllocateToWbb: {
            initialValue: '',
            value: '',
          },
          totalVolumeToAllocateToBatteries: {
            initialValue: '',
            value: '',
          },
          totalVolumeToAllocateToGasPeaker: {
            initialValue: '',
            value: '',
          },
        },
      };
    }
    default:
      return state;
  }
};
