import { Action } from '../actions';
import { apiAction } from '../api/actions';
import type { GateClosures } from './reducer';

export const ADD_GATECLOSURES = 'ADD_GATECLOSURES';
export const GATECLOSURES_ERROR = 'GATECLOSURES_ERROR';
export const UPDATE_GATECLOSURES = 'UPDATE_GATECLOSURES';

export type GateClosuresPayload = {
  data: {
    getCurrentGateClosures: GateClosures;
  };
};

export type UpdateGateClosuresPayload = {
  data: {
    currentGateClosuresWasUpdated: GateClosures;
  };
};

export const addGateClosures = (payload: GateClosuresPayload): Action => ({
  type: ADD_GATECLOSURES,
  payload,
});

export const updatedGateClosures = (
  payload: UpdateGateClosuresPayload,
): Action => ({
  type: UPDATE_GATECLOSURES,
  payload,
});

export const gateClosuresError = (payload: string) => ({
  type: GATECLOSURES_ERROR,
  payload,
});

export const getGateClosures = (query: string): Action => {
  const action = apiAction<GateClosuresPayload>({
    query,
    onSuccess: (res) => addGateClosures(res),
    onFailure: (x) => gateClosuresError(`Gate Closures error: ${x}`),
    label: UPDATE_GATECLOSURES,
  });

  return action;
};
