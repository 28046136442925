import React from 'react';
import { Owner, PortfolioName } from '../../container/Tier3Nop';
import { PeriodVolume } from '../../data/createTableData';
import { nopNames } from '../../data/rowPriorities';
import { CounterpartySelectOption } from '../../store/tradeAllocation/reducer';

import CopyRow from './CopyRow';
import displayNames from './displayNames';
import PopOutTier3 from './PopOut';

import { TradeAllocationButton } from './style';
import { ModifierFn } from './utils/cellModifier';

export const getEdfCopyNopRowButton = (nopName: nopNames): JSX.Element => (
  <>
    <span>{`${nopName} NOP`}</span>
    <CopyRow nopName={nopName} />
  </>
);

export const tier3PopOutButton = (
  owner: Owner,
  name: PortfolioName,
): JSX.Element => {
  const displayName = displayNames[owner][name];

  return (
    <>
      <span>{displayName}</span>
      <PopOutTier3 owner={owner} tierType={name} />
    </>
  );
};

export const clickableCell = (
  period: PeriodVolume,
  selectedCounterparty: CounterpartySelectOption,
  onClickCallbackFn: ModifierFn,
): JSX.Element => {
  const { startTime, endTime, efaDate, periodId } = period;
  const onClickTradeAllocation = () => {
    onClickCallbackFn(periodId, selectedCounterparty);
  };

  return (
    <TradeAllocationButton
      role="button"
      tabIndex={0}
      onClick={() => onClickTradeAllocation()}
      onKeyDown={() => onClickTradeAllocation()}
      data-starttime={startTime}
      data-endttime={endTime}
      data-efadate={efaDate}
      data-periodid={periodId}
    >
      {period.volume}
    </TradeAllocationButton>
  );
};
