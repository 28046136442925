import gql from 'graphql-tag';

const netOpenPositionSubscription = gql(`
  subscription NopChangesetWasCreated {
    nopChangesetWasCreated {
      id
      efaDate
      name
      tier
      parent
      periods {
        periodId
        nbp
        gate
        metadata {
          periodNumber
          startTime
          endTime
        }
      }
    }
  }
`);

const gateClosuresSubscription = gql(`
  subscription CurrentGateClosuresWasUpdated {
    currentGateClosuresWasUpdated {
      currentGateDetails {
        date
        endTime
        period
        startTime
        time
      }
      marketGatePeriod {
        date
        endTime
        period
        startTime
      }
      pnGatePeriod {
        date
        endTime
        period
        startTime
      }
    }
  }
`);

const heartBeatSubscription = gql(`
  subscription HeartBeatWasCreated {
    heartBeatWasCreated {
      status
      dateTime
      source
    }
  }
`);

const zapiResponseWasCreatedSubscription = gql(`
  subscription ZapiResponseWasCreated {
    zapiResponseWasCreated {
      message
      status
      correlationId
    }
  }
`);

const notificationWasCreatedSubscription = gql(`
subscription NotificationWasCreated {
  notificationWasCreated {
    message
    dateTime
    type
    source
  }
}
`);

export {
  netOpenPositionSubscription,
  gateClosuresSubscription,
  heartBeatSubscription,
  zapiResponseWasCreatedSubscription,
  notificationWasCreatedSubscription,
};
