/* eslint-disable @typescript-eslint/no-unused-vars */
import { logger } from '@edfenergy/shift-desk-monitoring';
import store from './store';
import client from './auth/client';
import { nopQuery as graphqlNopQuery } from './graphql/queries';
import { addNopChangesets, NopChangeset } from './store/nop/actions';

type NopQueryResponse = {
  getNop: {
    changesets: NopChangeset[];
    nextToken: string | null;
  } | null;
};

const INITIAL_CONCURRENT_QUERIES = 15;

const nopQuery = (
  lastChangesets: NopChangeset[] = [],
  token: string | undefined = undefined,
) => {
  const variables = {
    limit: 100,
    token,
  };

  let queries = [];

  if (!token) {
    // Perform initial queries concurrently
    queries = Array(INITIAL_CONCURRENT_QUERIES)
      .fill(null)
      .map((_, i) =>
        client.query<NopQueryResponse>({
          query: graphqlNopQuery,
          variables: {
            limit: variables.limit,
            token: btoa(String(i * variables.limit)),
          },
        }),
      );
  } else {
    queries = [
      client.query<NopQueryResponse>({ query: graphqlNopQuery, variables }),
    ];
  }

  Promise.all(queries)
    .then((responses) => {
      const mergedChangesets = responses.reduce(
        (acc: NopChangeset[], response) => [
          ...acc,
          ...(response.data.getNop?.changesets ?? []),
        ],
        lastChangesets,
      );
      const { nextToken } = responses[responses.length - 1].data.getNop ?? {
        nextToken: null,
      };

      if (nextToken) {
        nopQuery(mergedChangesets, nextToken);
      } else {
        store.dispatch(addNopChangesets(mergedChangesets));
      }
    })
    .catch((error) => {
      logger.error('error', error);
    });
};

export default () => {
  nopQuery();
  // uncomment this line and comment above line to use fake NOP data
  // note: this doesn't work as of new counterparties (Batteries and Gas Peaker) - needs to be fixed
  // mockedNopQuery();
  // if (isClockMocked() === false) {
  //   nopQuery();
  // } else {
  //   mockedNopQuery();
  // }
};
