/* eslint-disable prettier/prettier */
import React from 'react';
import { EfaDay } from '@edfenergy/shift-desk-efa-calendar';
import { format, parseISO } from 'date-fns';

import { ChartRangeKind } from '../../store/charting/actions';
import { useChartRangeState } from '../../store/charting/selector';
import { Bars } from './ResponsiveBarChart';
import { Text, TSpan } from './style';
import { getAxisRepresentativeItems } from './chartComputations';

export interface HorizontalAxisProps {
  bars: Bars[];
  height: number;
}

const HorizontalAxis: React.FC<HorizontalAxisProps> = (props) => {
  const { bars, height } = props;
  const chartRange = useChartRangeState();

  // Note: since bars contain two assumably equal sets of data - one for EDF and one for WBB - we only need one of them for the dates and periods.
  const representativeBars = getAxisRepresentativeItems(bars, 2);

  return (
    <>
      {representativeBars.map((bar: Bars) => {
        const { data, key } = bar.data;
        const { date, startTime, time } = data;
        const { x } = bar;
        const period = time;

        const parsedDate = parseISO(date as string);
        const efaBlockInfo = new EfaDay(parsedDate).getPeriodEfaBlock(period);

        const { blockIndex, periodIndex } = efaBlockInfo ?? {};

        const startingBlockIndex = 1;
        const midDayBlockIndex = 4;
        const startingPeriodIndex = 1;

        const showRegularLabel =
          chartRange.rangeKind === ChartRangeKind.Days7
            ? (blockIndex === startingBlockIndex ||
                blockIndex === midDayBlockIndex) &&
              periodIndex === startingPeriodIndex
            : periodIndex === startingPeriodIndex;

        if (showRegularLabel) {
          return (
            <g key={key}>
              <rect
                width="45"
                height="27.5"
                fill="#f9f9f9"
                transform={`translate(${x - 22.5},${height + 15})`}
              />
              <Text
                x="0"
                y="0"
                dy="0"
                textAnchor="middle"
                dominantBaseline="middle"
                transform={`translate(${x},${height + 25})`}
              >
                <TSpan x="0" dy="0">
                  {format(parsedDate, 'dd MMM')}
                </TSpan>
                <TSpan x="0" dy="1.0em">
                  {startTime}
                </TSpan>
              </Text>
            </g>
          );
        }
        return <></>;
      })}
    </>
  );
};

export default HorizontalAxis;
