import styled from 'styled-components';

export const HeaderToolBarContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0 30px;
  margin-left: auto;
`;

export const HeaderToolBarItem = styled.div`
  color: white;
`;

export const MainLayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const HeaderContainer = styled.div``;

export const DashboardContainer = styled.div`
  flex: 1;
  overflow-y: hidden;
  height: 100%;
`;
