import styled from 'styled-components';

interface SelectListProps {}

// eslint-disable-next-line import/prefer-default-export
export const SelectListContainer = styled.div<SelectListProps>``;

export const reactSelectComponentStyles = {
  control: (provided: any) => ({
    ...provided,
    borderRadius: 0,
    padding: '2px',
    fontSize: '14px',
    minHeight: '20px',
    border: '1px solid black',
    boxShadow: 'none',
    '&:hover': {
      border: '1px solid black',
    },
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    padding: '0 2px',
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    padding: '0 2px',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    padding: '0 2px',
    fontWeight: 'bold',
  }),
  input: (provided: any) => ({
    ...provided,
    margin: 0,
    padding: 0,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  menuList: (provided: any) => ({
    ...provided,
    border: '1px solid black',
    margin: 0,
    padding: 0,
    borderTop: 0,
    fontSize: '14px',
  }),
  menu: (provided: any) => ({
    ...provided,
    margin: 0,
    borderRadius: 0,
    padding: 0,
  }),
  option: (provided: any) => ({
    ...provided,
    padding: '3px 8px',
    backgroundColor: 'hsl(0 0% 100%)',
    color: 'black',
    '&:hover': {
      backgroundColor: 'hsl(0 0% 90%)',
    },
  }),
};
