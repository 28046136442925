/* eslint-disable no-unused-vars */
import { LoadingIndicators } from '@edfenergy/shift-desk-wallace';
import { isEmpty } from 'lodash';
import React, { memo, useState } from 'react';
import { useDispatch } from 'react-redux';

import ChartToolBar from '../ChartToolBar';
import CurrentSettlementMarker from './CurrentSettlementMarker';
import CurrentTimeMarker from './CurrentTimeMarker';
import TimeChartGrid from './LineGrid';
import ChartToolTip from './ToolTip';
import ResponsiveBarChart, { BarId, ChartData } from './ResponsiveBarChart';
import HorizontalAxis from './HorizontalAxis';
import RangeBar, { RangeBarOrientation } from '../RangeBar';
import {
  RangePercent,
  setChartHorizontalZoom,
  setChartVerticalZoom,
} from '../../store/charting/actions';
import {
  useChartNopTypeState,
  useChartRangeState,
  useChartZoomState,
} from '../../store/charting/selector';
import VerticalAxis from './VerticalAxis';
import { getMinMaxValues } from './chartComputations';
import GridContainer, {
  GridCellChart,
  GridCellToolBar,
  GridCellHorizontalRangeBar,
  GridCellVerticalRangeBar,
} from './NopBarChart.styles';

export type NopChartData = ChartData & {
  date: string;
  efaDate: string;
  time: number;
  barId: BarId;
  EDF: number;
  EDFColor: string;
  WBB: number;
  WBBColor: string;
};

export type NopChartProps = {
  data: NopChartData[];
  unitTestWorkaround?: boolean;
};

export enum NopChartActiveControls {
  colour = '#548BF4',
  cursor = '',
  arrowVertical = 's-resize',
  arrowHorizontal = 'e-resize',
  scrollControl = 'grab',
  scrollingControl = 'grabbing',
}

export enum NopChartDisabledControls {
  colour = '#dedede',
  cursor = 'not-allowed !important',
}

const NopBarChart: React.FC<NopChartProps> = (props) => {
  const { data, unitTestWorkaround } = props;
  const { horizontalZoom, verticalZoom } = useChartZoomState();
  const { rangeKind } = useChartRangeState();
  const [prevRangeKind, setPrevRangeKind] = useState(rangeKind);

  const nopChartType = useChartNopTypeState();

  const dispatch = useDispatch();

  if (prevRangeKind !== rangeKind) {
    const defaultZoom = { start: 0, end: 100 };
    dispatch(setChartHorizontalZoom({ data: defaultZoom }));
    dispatch(setChartVerticalZoom({ data: defaultZoom }));
    setPrevRangeKind(rangeKind);
  }

  const verticalAxisTicks = 8;

  const gapFactor = 0.5;

  const { minValue, maxValue } = getMinMaxValues(
    data,
    nopChartType,
    verticalAxisTicks,
    verticalZoom,
    gapFactor,
  );

  const minIndex = (data.length / 100) * horizontalZoom.start;
  const maxIndex = (data.length / 100) * horizontalZoom.end;

  const horizontallyZoomedData = data.slice(minIndex, maxIndex);

  const onChangeHorizontal = (range: RangePercent) => {
    dispatch(setChartHorizontalZoom({ data: range }));
  };

  const onChangeVertical = (range: RangePercent) => {
    dispatch(setChartVerticalZoom({ data: range }));
  };

  return isEmpty(data) ? (
    <LoadingIndicators
      type="circle"
      size="md"
      colour="blue"
      position="center"
    />
  ) : (
    <GridContainer>
      <GridCellToolBar>
        <ChartToolBar />
      </GridCellToolBar>
      <GridCellVerticalRangeBar>
        {unitTestWorkaround ? (
          <></>
        ) : (
          <RangeBar
            data-testid="vertical_zoom_range_bar"
            orientation={RangeBarOrientation.Vertical}
            startPercent={verticalZoom.start}
            endPercent={verticalZoom.end}
            stepPercent={5}
            onChange={onChangeVertical}
            colour={NopChartActiveControls.colour}
            cursor={NopChartActiveControls.cursor}
          />
        )}
      </GridCellVerticalRangeBar>
      <GridCellChart>
        <ResponsiveBarChart
          data={horizontallyZoomedData}
          minValue={minValue}
          maxValue={maxValue}
          verticalAxisTicks={verticalAxisTicks}
          index="barId"
          keys={['EDF', 'WBB']}
          layers={[
            TimeChartGrid,
            'bars',
            HorizontalAxis,
            VerticalAxis,
            CurrentSettlementMarker,
            CurrentTimeMarker,
            ChartToolTip,
          ]}
        />
      </GridCellChart>
      <GridCellHorizontalRangeBar>
        {unitTestWorkaround ? (
          <></>
        ) : (
          <RangeBar
            data-testid="horizontal_zoom_range_bar"
            orientation={RangeBarOrientation.Horizontal}
            startPercent={horizontalZoom.start}
            endPercent={horizontalZoom.end}
            stepPercent={2.5}
            onChange={onChangeHorizontal}
            colour={NopChartActiveControls.colour}
            cursor={NopChartActiveControls.cursor}
          />
        )}
      </GridCellHorizontalRangeBar>
    </GridContainer>
  );
};

export default memo(NopBarChart);
