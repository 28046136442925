import { NopChartType } from '../../../store/charting/actions';

/* eslint-disable no-unused-vars */
enum ColoursEdf {
  Positive = '#006400',
  Negative = '#C73142',
  Neutral = '#DADADA',
}

enum ColoursWbb {
  Positive = '#66a266',
  Negative = '#db6c78',
  Neutral = '#DADADA',
}

const positiveNegativeColour = (
  value: number,
  nopType: string,
): ColoursEdf | ColoursWbb => {
  if (nopType === NopChartType.EDF) {
    if (Math.sign(value) > 0) {
      return ColoursEdf.Positive;
    }
    if (Math.sign(value) < 0) {
      return ColoursEdf.Negative;
    }
  }
  if (nopType === NopChartType.WBB) {
    if (Math.sign(value) > 0) {
      return ColoursWbb.Positive;
    }
    if (Math.sign(value) < 0) {
      return ColoursWbb.Negative;
    }
  }
  return ColoursEdf.Neutral;
};

export { positiveNegativeColour, ColoursEdf, ColoursWbb };
