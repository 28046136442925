import { useEffect } from 'react';
import { CurrentTimeIncrementPayload } from '../store/timing/actions';

const useTimer = (
  intervalMs: number,
  callback: (payload: CurrentTimeIncrementPayload) => void,
) => {
  useEffect(() => {
    const intervalId = setInterval(() => {
      callback({ data: { incrementMs: intervalMs } });
    }, intervalMs);
    return () => clearTimeout(intervalId);
  });
};

export default useTimer;
