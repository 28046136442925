import styled from 'styled-components';

const Message = styled.span`
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.8px;
`;

const ErrorMessage = styled(Message)`
  color: #f00;
`;

const DisplayMessage = styled(Message)`
  color: #000;
`;

export { ErrorMessage, DisplayMessage };
