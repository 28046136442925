import React from 'react';
import { composeBarId } from '../../data/createChartData';
import { ChartRangeKind } from '../../store/charting/actions';
import { useChartRangeState } from '../../store/charting/selector';
import { useGateClosuresState } from '../../store/gateClosures/selector';
import { BarId, LineMarkerProps } from './ResponsiveBarChart';
import { ChartMarker, Text } from './style';

const CurrentSettlementMarker: React.FC<LineMarkerProps> = (props) => {
  const { bars, height, xScale } = props;

  const { currentGateDetails, marketGatePeriod, pnGatePeriod } =
    useGateClosuresState();
  const chartRange = useChartRangeState();

  let currentGateId: BarId | null = composeBarId(
    currentGateDetails?.date ?? '',
    currentGateDetails?.startTime ?? '',
    currentGateDetails?.period ?? 0,
  );

  let currentMarketGateId: BarId | null = composeBarId(
    marketGatePeriod?.date ?? '',
    marketGatePeriod?.startTime ?? '',
    marketGatePeriod?.period ?? 0,
  );

  let currentPnGateId: BarId | null = composeBarId(
    pnGatePeriod?.date ?? '',
    pnGatePeriod?.startTime ?? '',
    pnGatePeriod?.period ?? 0,
  );

  if (bars.filter((x) => x.data.indexValue === currentGateId).length === 0) {
    currentGateId = null;
  }

  if (
    bars.filter((x) => x.data.indexValue === currentMarketGateId).length === 0
  ) {
    currentMarketGateId = null;
  }

  if (bars.filter((x) => x.data.indexValue === currentPnGateId).length === 0) {
    currentPnGateId = null;
  }

  const barWidth = bars.length ? bars[0].width : 0;

  const showBottomPeriodMarkers =
    chartRange.rangeKind === ChartRangeKind.Hours12 ||
    chartRange.rangeKind === ChartRangeKind.Hours24;

  return (
    <>
      {showBottomPeriodMarkers && currentGateId ? (
        <g transform={`translate(${xScale(currentGateId)},${height})`}>
          <rect x={0} y={0} width={barWidth} height={15} fill="#B9D8F6" />
          <Text
            x={barWidth / 2}
            y={2.5}
            textAnchor="middle"
            dominantBaseline="hanging"
          >
            {currentGateDetails?.period}
          </Text>
        </g>
      ) : (
        <></>
      )}

      {currentMarketGateId ? (
        <ChartMarker
          data-testid="currentMarketGatePeriod_marker"
          x={xScale(currentMarketGateId)}
          width={barWidth}
          height={height}
          colour="B9D8F6"
        />
      ) : (
        <></>
      )}

      {showBottomPeriodMarkers && currentMarketGateId ? (
        <g transform={`translate(${xScale(currentMarketGateId)},${height})`}>
          <rect x={0} y={0} width={barWidth} height={15} fill="#D3D3D3" />
          <Text
            x={barWidth / 2}
            y={2.5}
            textAnchor="middle"
            dominantBaseline="hanging"
          >
            {marketGatePeriod?.period}
          </Text>
        </g>
      ) : (
        <></>
      )}

      {currentPnGateId ? (
        <ChartMarker
          data-testid="currentPnGatePeriod_marker"
          x={xScale(currentPnGateId)}
          width={barWidth}
          height={height}
          colour="9D9D9D"
        />
      ) : (
        <></>
      )}

      {showBottomPeriodMarkers && currentPnGateId ? (
        <g transform={`translate(${xScale(currentPnGateId)},${height})`}>
          <rect x={0} y={0} width={barWidth} height={15} fill="#D3D3D3" />
          <Text
            x={barWidth / 2}
            y={2.5}
            textAnchor="middle"
            dominantBaseline="hanging"
          >
            {pnGatePeriod?.period}
          </Text>
        </g>
      ) : (
        <></>
      )}
    </>
  );
};

export default CurrentSettlementMarker;
