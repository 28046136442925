import styled from 'styled-components';

export const DashboardContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const MainArea = styled.div`
  flex: 1;
  overflow-x: hidden;
  padding: 10px;
`;

export const ChartWrapper = styled.div`
  height: 350px;
  background-color: white;
  margin-bottom: 10px;
  resize: vertical;
  overflow-y: auto;
  box-shadow: 1px 1px 4px 0 rgb(16 54 122 / 32%);
`;

export const GridPositionWrapper = styled.div`
  position: relative;
  min-height: 400px;
`;

export const GridWrapper = styled.div`
  background-color: white;
  box-shadow: 1px 1px 4px 0 rgb(16 54 122 / 32%);
`;

export const Tier3Container = styled.div`
  padding: 10px;
  height: 100%;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    background-color: #d3d3d3;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #969595;
  }
`;

export const Tier3Heading = styled.h1`
  font-size: 14px;
  font-weight: bold;
`;
