import * as React from 'react';

import { StyledUnitDiv } from './style';

export type InputSymbolProps = {
  units?: string;
  hasError: boolean;
};

const InputSymbol: React.FC<InputSymbolProps> = (props) => {
  const { units, hasError } = props;
  return <StyledUnitDiv hasError={hasError}>{units}</StyledUnitDiv>;
};

export default InputSymbol;
