import { logger } from '@edfenergy/shift-desk-monitoring';
import client from '../../../../auth/client';
import { createInternalPowerTransfer } from '../../../../graphql/mutations';

export type CreateInternalPowerTransferFromTo = {
  from: CreateInternalPowerTransferDirectionInput;
  to: CreateInternalPowerTransferDirectionInput;
};

export type CreateInternalPowerTransferDirectionInput =
  | 'EDFE'
  | 'WBB'
  | 'Batteries'
  | 'Gas_Peaker';

export type InternalTransferCounterparty = Exclude<
  CreateInternalPowerTransferDirectionInput,
  'EDFE'
>;

export type CreateInternalPowerTransferInput = {
  from: CreateInternalPowerTransferDirectionInput;
  to: CreateInternalPowerTransferDirectionInput;
  productId: string;
  trader: string;
  price: number;
  volume: number;
};

export const submitInternalAllocation = async (
  order: CreateInternalPowerTransferInput,
) =>
  client
    .mutate({
      mutation: createInternalPowerTransfer,
      variables: order,
    })
    .catch((error) => {
      logger.error('error', error);
    });
