import _ from 'lodash';
import { PrivateTrade } from '../store/privateTrades/reducer';

const totalVolumeForPrivateTrades = (privateTrades: PrivateTrade[]): number => {
  const totalVolume = privateTrades.reduce(
    (acc, trade) => acc + trade.volume,
    0,
  );
  return _.round(totalVolume, 2);
};

export default totalVolumeForPrivateTrades;
