import styled from 'styled-components';

const GridContainer = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: auto auto auto;
  grid-template-columns: auto auto;
`;

const GridCellToolBar = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 1;
  margin: 7.5px 50px 0px 7.5px;
`;

const GridCellHorizontalRangeBar = styled.div`
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 3;
  grid-row-end: 3;
  margin: 0px 14.25px 5px 27.5px;
`;

const GridCellVerticalRangeBar = styled.div`
  grid-column-start: 1;
  grid-column-start: 1;
  grid-row-start: 2;
  grid-row-end: 2;
  margin: 17.5px 0px 60px 17.5px;
`;

const GridCellChart = styled.div`
  grid-row-start: 2;
  grid-row-end: 2;
  grid-column-start: 2;
  grid-column-end: 2;
  overflow: hidden;
`;

export default GridContainer;
export {
  GridCellToolBar,
  GridCellHorizontalRangeBar,
  GridCellVerticalRangeBar,
  GridCellChart,
};
