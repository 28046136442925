import React from 'react';
import { useStatusState } from '../../store/nop/selector';
import { LoadingIndicator } from '../../styles/app.styles';

const StatusPanel: React.FC<{}> = () => {
  const status = useStatusState();

  const title = status?.receivedAt?.isValid
    ? `Last NOP update received at: ${status.receivedAt.toFormat(
        'HH:mm:ss dd/MM/yyyy',
      )}`
    : 'Loading...';

  const body = status?.receivedAt?.isValid ? (
    <>{status.receivedAt.toFormat('HH:mm:ss (d MMM)')}</>
  ) : (
    <LoadingIndicator type="circle" size="sm" colour="gray" position="center" />
  );

  return (
    <div title={title} style={{ fontSize: 12 }} data-testid="NOP_status">
      {body}
    </div>
  );
};

export default StatusPanel;
