import React, { memo, useState } from 'react';
import { Icon } from '@blueprintjs/core';
import NewWindow from 'react-new-window';

import { IconButton } from './style';
import { Owner, PortfolioName } from '../../container/Tier3Nop';

export type PopOutProps = {
  tierType: PortfolioName;
  owner: Owner;
};

const PopOutTier3: React.FC<PopOutProps> = (props) => {
  const { tierType, owner } = props;
  const [popOut, setPopOut] = useState<boolean>(false);

  const url = `/tier3/${owner}/${tierType}`;

  const onClickEvent = () => {
    setPopOut(!popOut);
  };

  return (
    <>
      <IconButton type="button" onClick={onClickEvent}>
        <Icon
          icon={popOut ? 'cross' : 'document-open'}
          size={12}
          htmlTitle={popOut ? 'Close the opened window' : `Open: ${url}`}
          style={{ pointerEvents: 'none' }}
        />
      </IconButton>
      {popOut && (
        <NewWindow
          url={url}
          onUnload={onClickEvent}
          features={{ left: 200, top: 200, width: 1000, height: 400 }}
        />
      )}
    </>
  );
};

export default memo(PopOutTier3);
