import AWSAppSyncClient from 'aws-appsync';
import { Pipeline } from '@chrisharrison/simple-middleware-pipeline';
import { NopStore } from '../store';
import { SubscriptionDefinition } from './subscriptionFactory';

export interface SubscriptionInterface {
  start: () => void;
  stop: () => void;
  restart: () => void;
}

class Subscription implements SubscriptionInterface {
  store: NopStore;

  definition: SubscriptionDefinition;

  observable: any;

  subscription: any;

  constructor(
    client: AWSAppSyncClient<any>,
    store: NopStore,
    definition: SubscriptionDefinition,
  ) {
    this.store = store;
    this.definition = definition;
    this.observable = client.subscribe({ query: definition.graphQl });
  }

  start(): void {
    const middlewarePipeline = new Pipeline(this.definition.middleware);
    this.subscription = this.observable.subscribe({
      next: (data: any) => {
        const pipelinedData = middlewarePipeline.run(() => data);

        if (pipelinedData !== undefined) {
          this.store.dispatch(this.definition.reduxAction(pipelinedData));
        }
      },
      error: (error: any) => {
        console.log(
          `Error ocurred in subscription: ${this.definition.label}`,
          error,
        );
        this.restart();
      },
    });
  }

  stop(): void {
    this.subscription.unsubscribe();
  }

  restart(): void {
    this.stop();
    this.start();
  }
}

export default Subscription;
