import { useSelector } from 'react-redux';
import AppState from '../AppState';

export const chartRangeSelector = (state: AppState) => state.chart.chartRange;

export const chartZoomSelector = (state: AppState) => state.chart.chartZoom;

export const chartNopTypeSelector = (state: AppState) =>
  state.chart.nopChartType;

export const useChartRangeState = () => useSelector(chartRangeSelector);

export const useChartZoomState = () => useSelector(chartZoomSelector);

export const useChartNopTypeState = () => useSelector(chartNopTypeSelector);
