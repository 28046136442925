import React, { ReactNode } from 'react';
import { ResponsiveBar } from '@nivo/bar';

import defaultProps from './defaultConfig';
import { CreateInternalPowerTransferDirectionInput } from '../TradeAllocationWindow/Tabs/InternalAllocation/submitInternalAllocation';

export type ChartData = {
  [key: string]: string | number | Date;
};

export interface ChartProps {
  data: ChartData[];
  index: string;
  keys: string[];
  minValue: number;
  maxValue: number;
  verticalAxisTicks: number;
  layers: (string | ReactNode)[];
}

export type BarId = string;

export type BarData = {
  nop: number;
  date: string;
  efaDate: string;
  time: number;
  startTime: string;
  endTime: string;
  volume: number;
  id: CreateInternalPowerTransferDirectionInput;
  barId: BarId;
  nopColor: string;
  periodId: string;
};

export type BarsData = {
  key: string;
  index: number;
  indexValue: number | string;
  id: string;
  value: number | string;
  data: BarData;
};

export type Bars = {
  key: string;
  x: number;
  y: number;
  width: number;
  height: number;
  data: BarsData;
};

export interface LineMarkerProps {
  bars: Bars[];
  height: number;
  xScale: (id: BarId) => number;
  yScale: (volume: number) => number;
  maxValue: number;
  minValue: number;
}

const ResponsiveBarChart: React.FC<ChartProps> = (props) => {
  const { data, index, keys, minValue, maxValue, verticalAxisTicks, layers } =
    props;

  return (
    <div style={{ overflow: 'hidden', height: '100%' }}>
      <ResponsiveBar
        {...defaultProps}
        indexBy={index}
        data={data}
        minValue={minValue}
        maxValue={maxValue}
        verticalAxisTicks={verticalAxisTicks}
        keys={keys}
        colors={({ id, data }: any) => data[`${id}Color`]}
        layers={layers}
      />
    </div>
  );
};

export default ResponsiveBarChart;
