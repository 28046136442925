import { Action } from '../actions';
import {
  CounterpartySelectOption,
  TradeAllocationWindowState,
} from './reducer';

export const SET_TRADE_ALLOCATION_WINDOW_STATE =
  'SET_TRADE_ALLOCATION_WINDOW_STATE';
export const OPEN_TRADE_ALLOCATION_WINDOW = 'OPEN_TRADE_ALLOCATION_WINDOW';
export const CLEAR_SELECTED_TRADES = 'CLEAR_SELECTED_TRADES';

export type OpenTradeAllocationWindow = {
  productId: string;
  selectedCounterparty?: CounterpartySelectOption;
};

export const setTradeAllocationWindowState = (
  payload: TradeAllocationWindowState,
): Action => ({
  type: SET_TRADE_ALLOCATION_WINDOW_STATE,
  payload,
});

export const openTradeAllocationWindow = (
  payload: OpenTradeAllocationWindow,
): Action => ({
  type: OPEN_TRADE_ALLOCATION_WINDOW,
  payload,
});

export const clearSelectedTrades = () => ({
  type: CLEAR_SELECTED_TRADES,
  payload: null,
});
