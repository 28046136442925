const baseAppUrl =
  process.env.NODE_ENV === 'development'
    ? `http://${window.location.hostname}:8080`
    : `https://${window.location.hostname}`;

const region = 'eu-west-1';

const config = {
  log: {
    isLocal: false, // always false for ui
    level: process.env.REACT_APP_LOG_LEVEL,
  },
  Auth: {
    identityPoolRegion: region,
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    region,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEBCLIENT_ID,

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,
    provider: 'RED-AD',
    // OPTIONAL - Hosted UI configuration
    oauth: {
      domain: process.env.REACT_APP_OAUTH_DOMAIN,
      scope: [
        'phone',
        'email',
        'profile',
        'openid',
        'aws.cognito.signin.user.admin',
      ],
      redirectSignIn: baseAppUrl,
      redirectSignOut: baseAppUrl,
      responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
  AppSync: {
    aws_appsync_graphqlEndpoint: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    aws_appsync_region: region,
  },
  Endpoints: {
    nop_csv_download: process.env.REACT_APP_NOP_CSV_DOWNLOAD_LINK,
  },
};

export default config;
