import { heartBeatSubscription } from '../graphql/subscriptions';
import { updateHeartBeatSubscription } from '../store/heartbeat/actions';
import { SubscriptionDefinition } from './subscriptionFactory';

const subscription: SubscriptionDefinition = {
  label: 'heartBeat',
  graphQl: heartBeatSubscription,
  reduxAction: updateHeartBeatSubscription,
  middleware: [],
};

export default subscription;
