import { Action } from '../actions';

export const ADD_NOP_CHANGESET = 'ADD_NOP_CHANGESET';
export const ADD_NOP_CHANGESETS = 'ADD_NOP_CHANGESETS';
export const NOP_WINDOW_STATUS = 'NOP_WINDOW_STATUS';
export const UPDATE_NOP_GRID_DATE = 'UPDATE_NOP_GRID_DATE';
export const UPDATE_NOP_GRID_SELECTION = 'UPDATE_NOP_GRID_SELECTION';

export type NopPayload = {
  data: {
    getNop: NetOpenPosition[];
  };
};

export type NopSubscriptionPayload = {
  data: {
    nopDaySnapshotWasCreated: NetOpenPosition;
  };
};

export type NetOpenPosition = {
  snapshotId: string;
  hash: string;
  date: EfaDay;
  periods: Period[];
};

export type EfaDay = {
  efaDate: string;
  calendarDate: string;
};

export type PeriodData = {
  startTime: string;
  endTime: string;
  settlementPeriod: number;
  periodId: string;
};

export type Period = {
  period: PeriodData;
  nop: NopPosition[];
};

export type NopPosition = {
  id: string;
  name: string;
  position: Position;
  tier1: Tier1Positions[];
  tier2: Tier2Positions[];
};

export type PositionType = 'Consumption' | 'Production';

export type Tier1Positions = {
  id: string;
  type: PositionType;
  position: Position;
};

export type Tier2Positions = {
  id: string;
  position: Position;
  provider: {
    name: string;
  };
  tier3: Tier3Position[];
};

export type Tier3Position = {
  id: string;
  name: string;
  position: Position;
};

export type Position = {
  nbp: number;
  gate: number;
};

export type ChangesetMetadata = {
  periodNumber: number;
  startTime: string;
  endTime: string;
};

export type ChangesetPeriod = {
  periodId: string;
  nbp: number;
  gate: number;
  metadata: ChangesetMetadata;
};

export type GridSelectionMetaData = {
  name: string;
  value: boolean;
};

export enum ChangesetTier {
  Tier0,
  Tier1,
  Tier2,
  Tier3,
}

export type NopChangeset = {
  id: string;
  efaDate: string;
  name: string;
  tier: ChangesetTier;
  parent: string | null;
  periods: ChangesetPeriod[];
};

export const addNopChangesets = (payload: NopChangeset[]): Action => ({
  type: ADD_NOP_CHANGESETS,
  payload,
});

export const addNopChangeset = (payload: NopChangeset): Action => ({
  type: ADD_NOP_CHANGESET,
  payload,
});

export const nopUpdateWindowStatus = (payload: boolean) => ({
  type: NOP_WINDOW_STATUS,
  payload,
});

export const updateNopGridDate = (payload: string) => ({
  type: UPDATE_NOP_GRID_DATE,
  payload,
});

export const updateNopGridSelection = (payload: GridSelectionMetaData[]) => ({
  type: UPDATE_NOP_GRID_SELECTION,
  payload,
});
