import { Action } from '../actions';
import {
  SET_CHART_RANGE,
  SET_CHART_ZOOM_HORIZONTAL,
  SET_CHART_ZOOM_VERTICAL,
  SET_NOP_CHART_TYPE,
  ChartRange,
  ChartRangeKind,
  ChartZoom,
  NopChartType,
} from './actions';

export type ChartState = {
  chartRange: ChartRange;
  chartZoom: ChartZoom;
  nopChartType: NopChartType;
};

const initialState = <ChartState>{
  chartRange: {
    rangeKind: ChartRangeKind.Hours12,
    pastPeriods: 4,
    currentPeriods: 20,
    days: 1,
  },
  chartZoom: {
    horizontalZoom: { start: 0, end: 100 },
    verticalZoom: { start: 0, end: 100 },
  },
  nopChartType: NopChartType.EDF,
};

export const chart = (state: ChartState = initialState, action: Action) => {
  switch (action.type) {
    case SET_CHART_RANGE: {
      const { payload } = action;
      const { data } = payload;

      return { ...state, chartRange: { ...data } };
    }
    case SET_CHART_ZOOM_HORIZONTAL: {
      const { payload } = action;
      const { data } = payload;

      return {
        ...state,
        chartZoom: {
          horizontalZoom: { ...data },
          verticalZoom: state.chartZoom.verticalZoom,
        },
      };
    }
    case SET_CHART_ZOOM_VERTICAL: {
      const { payload } = action;
      const { data } = payload;

      return {
        ...state,
        chartZoom: {
          horizontalZoom: state.chartZoom.horizontalZoom,
          verticalZoom: { ...data },
        },
      };
    }
    case SET_NOP_CHART_TYPE: {
      const { payload } = action;

      return { ...state, nopChartType: payload };
    }
    default:
      return state;
  }
};
