import styled from 'styled-components';

const Form = styled.form`
  overflow: hidden;
`;

const Radio = styled.input`
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;

  &:checked + label {
    background-color: #10367a;
    color: #ffffff;
    cursor: default;
    border-color: #10367a;
    border-left: 1px solid #a3a3a3;

    &:first-of-type {
      border-left: 1px solid #10367a;
    }
  }
`;

const Label = styled.label`
  display: inline-block;
  background-color: #e3e3e3;
  color: #555555;
  font-size: 12px;
  font-weight: bolder;
  text-align: center;
  padding: 1.25px 7.5px;
  border: 1px solid #a3a3a3;
  border-right: 0px;
  transition: all 0.1s ease-in-out;

  &:hover {
    background-color: #ffffff;
    cursor: pointer;
  }

  &:active {
    color: #10367a;
    box-shadow: inset 0px 0px 0px 1px #10367a;
  }

  &:last-of-type {
    border-right: 1px solid #a3a3a3;
  }
`;

export { Form, Radio, Label };
