import { netOpenPositionSubscription } from '../graphql/subscriptions';
import { addNopChangeset } from '../store/nop/actions';
import unwrapChangeset from './middleware/unwrapChangeset';
import { SubscriptionDefinition } from './subscriptionFactory';

const subscription: SubscriptionDefinition = {
  label: 'nop',
  graphQl: netOpenPositionSubscription,
  reduxAction: addNopChangeset,
  middleware: [unwrapChangeset],
};

export default subscription;
