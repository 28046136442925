import { NopChangeset } from '../../store/nop/actions';
import {
  HorizonMetadata,
  HorizonWithMetadata,
  NopHorizon,
} from '../../store/nop/reducer';
import orderDaysWithinHorizon from './orderDaysWithinHorizon';
import orderNops from './orderNops';
import reduceHorizonWithChangesets from './reduceHorizonWithChangesets';
import filterChangesetsWithInvalidPeriodLength from './filterChangesetsWithInvalidPeriodLength';
import truncateHorizon from './truncateHorizon';

const MAXIMUM_DAYS_IN_HORIZON = 15;

export default (
  horizon: NopHorizon,
  metadata: HorizonMetadata,
  changesets: NopChangeset[],
): HorizonWithMetadata => {
  const filteredChangesets =
    filterChangesetsWithInvalidPeriodLength(changesets);
  const reducedWithChangesets = reduceHorizonWithChangesets(
    { ...horizon, ...metadata.incompleteDays },
    metadata,
    filteredChangesets,
  );
  const orderedReduce = orderNops(
    reducedWithChangesets[0],
    reducedWithChangesets[1],
  );
  const orderedHorizon = orderDaysWithinHorizon(orderedReduce[0]);
  const limitedHorizon = truncateHorizon(
    orderedHorizon,
    MAXIMUM_DAYS_IN_HORIZON,
  );
  return [limitedHorizon, orderedReduce[1]];
};
