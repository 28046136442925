import React from 'react';
import AWSAppSyncClient from 'aws-appsync';
import { toast } from 'react-toastify';
import { Toast } from '@edfenergy/shift-desk-wallace';
import { SubscriptionInterface } from './subscription';
import { zapiResponseWasCreatedSubscription } from '../graphql/subscriptions';
import {
  setPrivateTradeStatusAsAllocationFailed,
  setPrivateTradeStatusAsSuccessfullyAllocated,
} from '../store/privateTrades/actions';
import { NopStore } from '../store';

export type ZapiResponseStatus = 'FAILED' | 'SUCCESS';
const TRADE_ID_PREFIX = 'tradeId-';
const TRADE_ID_PREFIX_LENGTH = TRADE_ID_PREFIX.length;

type ZapiResponse = {
  data: {
    zapiResponseWasCreated: {
      message: String;
      status: ZapiResponseStatus;
      correlationId: String;
    };
  };
};

const messageForToast = (
  status: ZapiResponseStatus,
  tradeId: string | null,
): string => {
  if (status === 'SUCCESS' && tradeId !== null) {
    return `Trade allocation successful for trade ID: ${tradeId}`;
  }
  if (status === 'FAILED' && tradeId !== null) {
    return `Trade allocation failed for trade ID: ${tradeId}`;
  }
  if (status === 'SUCCESS' && tradeId === null) {
    return `Internal transfer successful`;
  }
  if (status === 'FAILED' && tradeId === null) {
    return `Internal transfer failed`;
  }
  return '';
};

class ZapiResponseQueueSubscription implements SubscriptionInterface {
  private observable: any;

  private subscription: any;

  private store: NopStore;

  constructor(client: AWSAppSyncClient<any>, store: NopStore) {
    this.store = store;
    this.observable = client.subscribe({
      query: zapiResponseWasCreatedSubscription,
    });
  }

  start(): void {
    this.subscription = this.observable.subscribe({
      next: (response: ZapiResponse) => {
        const data = response.data.zapiResponseWasCreated;
        const { correlationId, status } = data;
        const autoClose = status !== 'FAILED' ? 5000 : false;

        const tradeId = correlationId.includes(TRADE_ID_PREFIX)
          ? correlationId.slice(TRADE_ID_PREFIX_LENGTH)
          : null;

        toast(
          <Toast
            id="zapi-response-toast"
            messageType={data.status === 'FAILED' ? 'error' : 'success'}
          >
            {messageForToast(status, tradeId)}
          </Toast>,
          {
            autoClose,
            closeButton: false,
            position: toast.POSITION.TOP_CENTER,
          },
        );

        if (tradeId === null) {
          return;
        }

        if (data.status === 'SUCCESS') {
          this.store.dispatch(
            setPrivateTradeStatusAsSuccessfullyAllocated({
              tradeId,
            }),
          );
        }
        if (data.status === 'FAILED') {
          this.store.dispatch(
            setPrivateTradeStatusAsAllocationFailed({
              tradeId,
            }),
          );
        }
      },
      error: (error: any) => {
        console.log(
          `Error ocurred in subscription: ZapiResponseQueueSubscription`,
          error,
        );
        this.restart();
      },
    });
  }

  stop(): void {
    this.subscription.unsubscribe();
  }

  restart(): void {
    this.stop();
    this.start();
  }
}

export default ZapiResponseQueueSubscription;
