import { Action } from '../actions';

export const INCREMENT_CURRENT_TIME = 'INCREMENT_CURRENT_TIME';

export type CurrentTimeIncrementPayload = {
  data: {
    incrementMs: number;
  };
};

export const incrementCurrentTime = (
  payload: CurrentTimeIncrementPayload,
): Action => ({
  type: INCREMENT_CURRENT_TIME,
  payload,
});
