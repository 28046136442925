import { Action } from '../actions';
import {
  SET_WAPS_AS_LOADING,
  UPDATE_WAPS,
  Wap,
  WapsApiResponse,
  WAPS_ERROR,
} from './actions';

export type WapState = {
  waps: Wap[];
  isLoading: boolean;
  hasError: boolean;
  errorMessage?: string;
};

export const initialState: WapState = {
  waps: [],
  isLoading: false,
  hasError: false,
};

export const reduceWapState = (
  state: WapState = initialState,
  action: Action,
): WapState => {
  switch (action.type) {
    case UPDATE_WAPS: {
      const { payload }: { payload: WapsApiResponse } = action;
      return {
        waps: payload.getWapsForProducts ?? [],
        isLoading: false,
        hasError: false,
      };
    }
    case SET_WAPS_AS_LOADING: {
      return {
        waps: [],
        isLoading: true,
        hasError: false,
      };
    }
    case WAPS_ERROR: {
      const { payload }: { payload: string } = action;
      return {
        waps: [],
        isLoading: false,
        hasError: true,
        errorMessage: payload,
      };
    }
    default:
      return state;
  }
};
