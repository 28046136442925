import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import format from 'date-fns/format';
import { find, flatten, isUndefined, compact } from 'lodash';

import { ToolTip, Text, ToolTipTSpan } from './style';

import { useNopState } from '../../store/nop/selector';
import { Bars } from './ResponsiveBarChart';
import { NetOpenPosition } from '../../store/nop/reducer';
import { Tier2Positions } from '../../store/nop/actions';
import { useChartNopTypeState } from '../../store/charting/selector';
import { NopChartType } from '../../store/charting/actions';
import { NopType } from '../../data/createTableData';
import GridConstants from '../Grids/utils/constants';
import { openTradeAllocationWindow } from '../../store/tradeAllocation/actions';

const defaultX = 0;
const defaultY = 0;

export type ChartToolTipProps = {
  height: number;
  width: number;
  bars: Bars[];
};

type SummaryData = {
  portfolio: string;
  position: number;
};

const toolTipHeight: Record<NopChartType, number> = {
  [NopChartType.EDF]: 140,
  [NopChartType.WBB]: 80,
  [NopChartType.ALL]: 180,
};

const ChartToolTip: React.FC<ChartToolTipProps> = (props) => {
  const { height, width, bars } = props;
  const dispatch = useDispatch();

  const [displayToolTip, setDisplayTooltip] = useState<boolean>(false);
  const [tooltipPosition, setTooltipPosition] = useState<number[]>([
    defaultX,
    defaultY,
  ]);
  const [summaryDataEdf, setSummaryDataEdf] = useState<SummaryData[]>([]);
  const [summaryDataWbb, setSummaryDataWbb] = useState<SummaryData[]>([]);
  const [settlementPeriod, setSettlementPeriod] = useState<number>();
  const [settlementDate, setSettlementDate] = useState<string>('1970-01-01'); // default date to avoid error thrown

  const horizonData = Object.values(useNopState());

  const nopChartType = useChartNopTypeState();

  const tier2Summaries = (
    horizon: NetOpenPosition[],
    date: string,
    time: string,
    period: number,
    nopType: NopType,
  ): SummaryData[] => {
    const summaries = flatten(
      horizon
        .filter((day: NetOpenPosition) => String(day.date?.efaDate) === date)
        .map((details: NetOpenPosition) => {
          const { periods } = details;

          const periodDetails = find(periods, {
            period: { startTime: time },
          });

          if (isUndefined(periodDetails)) return [];

          const { nop } = periodDetails;
          const { name, position, tier2 } = nop[nopType];

          const nopPosition = {
            portfolio: `${name} ${GridConstants.NopRowPrefix}`,
            position: position.nbp,
          };

          const tier2s = compact(tier2).map((portfolio: Tier2Positions) => {
            const { position, provider } = portfolio;

            const currentPosition = Math.round(position.gate);
            return { portfolio: provider.name, position: currentPosition };
          });

          return [...tier2s, nopPosition];
        }),
    );
    return summaries;
  };

  const onMouseEnter = () => setDisplayTooltip(true);

  const onMouseLeave = () => setDisplayTooltip(false);

  const onMouseMove = (
    e: React.MouseEvent,
    period: number,
    startTime: string,
    efaDate: string,
    calendarDate: string,
  ) => {
    const { nativeEvent } = e;
    const { offsetX, offsetY } = nativeEvent;

    setSettlementPeriod(period);
    setSettlementDate(calendarDate);

    if (nopChartType === NopChartType.EDF) {
      const data = tier2Summaries(
        horizonData,
        efaDate,
        startTime,
        period,
        NopType.EDF,
      );
      setSummaryDataEdf(data);
    } else if (nopChartType === NopChartType.WBB) {
      const data = tier2Summaries(
        horizonData,
        efaDate,
        startTime,
        period,
        NopType.WBB,
      );
      setSummaryDataWbb(data);
    } else {
      const edfe = tier2Summaries(
        horizonData,
        efaDate,
        startTime,
        period,
        NopType.EDF,
      );
      setSummaryDataEdf(edfe);
      const wbb = tier2Summaries(
        horizonData,
        efaDate,
        startTime,
        period,
        NopType.WBB,
      );
      setSummaryDataWbb(wbb);
    }

    const tooltipHeight = 150;
    const tooltipWidth = 150;

    let newY = offsetY;
    let newX = offsetX;

    if (offsetY <= tooltipHeight) {
      newY = offsetY;
    } else if (offsetY >= height - tooltipHeight) {
      newY = height - tooltipHeight;
    }

    if (offsetX <= tooltipWidth) {
      newX = offsetX;
    } else if (offsetX >= width - tooltipWidth) {
      newX = width - tooltipWidth;
    }

    setTooltipPosition([newX, newY]);
  };

  const [x, y] = tooltipPosition;

  const [
    Nuclear,
    Coal,
    Batteries,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    GasPeaker,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    GasPeakerNop,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    BatteriesNop,
    EmbedGen,
    CustomerVol,
    TradesEdf,
    NopEdf,
  ] = summaryDataEdf;
  const [GasWbb, TradesWbb, NopWbb] = summaryDataWbb;

  const textXExtraPosition = 10;
  const tspanXExtraPosition = 100;

  const isAllNops = nopChartType === NopChartType.ALL;

  return (
    <>
      {displayToolTip ? (
        <>
          <ToolTip x={x} y={y} rx="5" height={toolTipHeight[nopChartType]} />
          <Text x={x + 10} y={y + 20} style={{ fontWeight: 'bold' }}>
            SP {settlementPeriod} &bull;{' '}
            {format(new Date(settlementDate), 'dd/MM/yy')}
          </Text>
          {(nopChartType === NopChartType.EDF ||
            nopChartType === NopChartType.ALL) &&
            summaryDataEdf.length > 0 && (
              <g>
                <Text
                  data-testid="Nuclear_Position"
                  x={x + textXExtraPosition}
                  y={y + 35}
                >
                  <tspan>{Nuclear.portfolio}</tspan>
                  <ToolTipTSpan
                    x={x + tspanXExtraPosition}
                    positionValue={parseFloat(Nuclear.position.toString())}
                  >
                    {Nuclear.position}
                  </ToolTipTSpan>
                </Text>
                <Text
                  data-testid="Coal_Position"
                  x={x + textXExtraPosition}
                  y={y + 50}
                >
                  <tspan>{Coal.portfolio}</tspan>
                  <ToolTipTSpan
                    x={x + tspanXExtraPosition}
                    positionValue={parseFloat(Coal.position.toString())}
                  >
                    {Coal.position}
                  </ToolTipTSpan>
                </Text>
                <Text
                  data-testid="Batteries"
                  x={x + textXExtraPosition}
                  y={y + 65}
                >
                  <tspan>{Batteries.portfolio}</tspan>
                  <ToolTipTSpan
                    x={x + tspanXExtraPosition}
                    positionValue={parseFloat(Batteries.position.toString())}
                  >
                    {Batteries.position}
                  </ToolTipTSpan>
                </Text>
                <Text
                  data-testid="EmbedGen_Position"
                  x={x + textXExtraPosition}
                  y={y + 80}
                >
                  <tspan>{EmbedGen.portfolio}</tspan>
                  <ToolTipTSpan
                    x={x + tspanXExtraPosition}
                    positionValue={parseFloat(EmbedGen.position.toString())}
                  >
                    {EmbedGen.position}
                  </ToolTipTSpan>
                </Text>
                <Text
                  data-testid="CustomerVol_Position"
                  x={x + textXExtraPosition}
                  y={y + 95}
                >
                  <tspan>{CustomerVol.portfolio}</tspan>
                  <ToolTipTSpan
                    x={x + tspanXExtraPosition}
                    positionValue={parseFloat(CustomerVol.position.toString())}
                  >
                    {CustomerVol.position}
                  </ToolTipTSpan>
                </Text>
                <Text
                  data-testid="Trades_Position_Edf"
                  x={x + textXExtraPosition}
                  y={y + 110}
                >
                  <tspan>{TradesEdf.portfolio}</tspan>
                  <ToolTipTSpan
                    x={x + tspanXExtraPosition}
                    positionValue={parseFloat(TradesEdf.position.toString())}
                  >
                    {TradesEdf.position}
                  </ToolTipTSpan>
                </Text>
                <Text
                  data-testid="Nop_Position_Edf"
                  x={x + textXExtraPosition}
                  y={y + 125}
                  fontWeight="bold"
                >
                  <tspan>EDF NOP</tspan>
                  <ToolTipTSpan
                    x={x + tspanXExtraPosition}
                    positionValue={parseFloat(NopEdf.position.toString())}
                  >
                    {NopEdf.position}
                  </ToolTipTSpan>
                </Text>
              </g>
            )}
          {(nopChartType === NopChartType.WBB ||
            nopChartType === NopChartType.ALL) &&
            summaryDataWbb.length > 0 && (
              <g>
                <Text
                  data-testid="Gas_Position_Wbb"
                  x={x + textXExtraPosition}
                  y={y + (isAllNops ? 140 : 35)}
                >
                  <tspan>{GasWbb.portfolio}</tspan>
                  <ToolTipTSpan
                    x={x + tspanXExtraPosition}
                    positionValue={parseFloat(GasWbb.position.toString())}
                  >
                    {GasWbb.position}
                  </ToolTipTSpan>
                </Text>
                <Text
                  data-testid="Trades_Position_Wbb"
                  x={x + textXExtraPosition}
                  y={y + (isAllNops ? 155 : 50)}
                >
                  <tspan>{TradesWbb.portfolio}</tspan>
                  <ToolTipTSpan
                    x={x + tspanXExtraPosition}
                    positionValue={parseFloat(TradesWbb.position.toString())}
                  >
                    {TradesWbb.position}
                  </ToolTipTSpan>
                </Text>
                <Text
                  data-testid="Nop_Position_Wbb"
                  x={x + textXExtraPosition}
                  y={y + (isAllNops ? 170 : 65)}
                  fontWeight="bold"
                >
                  <tspan>{NopWbb.portfolio}</tspan>
                  <ToolTipTSpan
                    x={x + tspanXExtraPosition}
                    positionValue={parseFloat(NopWbb.position.toString())}
                  >
                    {NopWbb.position}
                  </ToolTipTSpan>
                </Text>
              </g>
            )}
        </>
      ) : null}
      {bars.map((bar: Bars) => {
        const { width, x, key, data: barData } = bar;
        const { data } = barData;
        const { time, efaDate, startTime, date } = data;

        return (
          <g key={key}>
            <rect
              data-testid={key}
              width={width}
              height={height}
              fill="transparent"
              x={x}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              onMouseMove={(e: React.MouseEvent) =>
                onMouseMove(e, time, startTime, efaDate, date)
              }
              onClick={() =>
                dispatch(
                  openTradeAllocationWindow({
                    productId: bar.data.data.periodId,
                  }),
                )
              }
            />
          </g>
        );
      })}
    </>
  );
};

export default ChartToolTip;
