import { gateClosuresSubscription } from '../graphql/subscriptions';
import { updatedGateClosures } from '../store/gateClosures/actions';
import { SubscriptionDefinition } from './subscriptionFactory';

const subscription: SubscriptionDefinition = {
  label: 'gateClosures',
  graphQl: gateClosuresSubscription,
  reduxAction: updatedGateClosures,
  middleware: [],
};

export default subscription;
