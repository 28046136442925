import {
  NopPosition,
  Period,
  Tier1Positions,
  Tier2Positions,
  Tier3Position,
} from '../../store/nop/actions';
import {
  HorizonMetadata,
  HorizonWithMetadata,
  NopHorizon,
} from '../../store/nop/reducer';
import {
  entriesToNopHorizon,
  NetOpenPositionHorizonEntry,
  nopHorizonToEntries,
} from './utils';

// Order the items within a nop so that they're always in an expected order. i.e. EDF, WBB, Total

// eslint-disable-next-line prettier/prettier
class NotEnoughDataToProduceCompleteDay extends Error { }

const orderTier3 = (tier2Positions: Tier2Positions[]): Tier2Positions[] =>
  tier2Positions.map((tier2Position: Tier2Positions) => ({
    ...tier2Position,
    tier3: tier2Position.tier3.sort(
      (a: Tier3Position, b: Tier3Position): number =>
        a.name.localeCompare(b.name),
    ),
  }));

const orderTier2 = (
  nopName: string,
  tier2Positions: Tier2Positions[],
): Tier2Positions[] => {
  if (nopName === 'EDF') {
    const tier2 = [
      tier2Positions.find(
        (tier2Item) => tier2Item.provider.name === 'Nuclear',
      ) as Tier2Positions,
      tier2Positions.find(
        (tier2Item) => tier2Item.provider.name === 'Coal',
      ) as Tier2Positions,
      tier2Positions.find(
        (tier2Item) => tier2Item.provider.name === 'Batteries',
      ) as Tier2Positions,
      tier2Positions.find(
        (tier2Item) => tier2Item.provider.name === 'Gas Peaker',
      ) as Tier2Positions,
      tier2Positions.find(
        (tier2Item) => tier2Item.provider.name === 'Gas Peaker NOP',
      ) as Tier2Positions,
      tier2Positions.find(
        (tier2Item) => tier2Item.provider.name === 'Batteries NOP',
      ) as Tier2Positions,
      tier2Positions.find(
        (tier2Item) => tier2Item.provider.name === 'Embed_Gen',
      ) as Tier2Positions,
      tier2Positions.find(
        (tier2Item) => tier2Item.provider.name === 'Customer_Vol',
      ) as Tier2Positions,
      tier2Positions.find(
        (tier2Item) => tier2Item.provider.name === 'Trades',
      ) as Tier2Positions,
    ];
    if ((tier2 as any[]).includes(undefined)) {
      throw new NotEnoughDataToProduceCompleteDay(
        'Not enough data to build all EDF tier 2 positions.',
      );
    }
    return tier2;
  }
  if (nopName === 'WBB') {
    const tier2 = [
      tier2Positions.find(
        (tier2Item) => tier2Item.provider.name === 'Gas',
      ) as Tier2Positions,
      tier2Positions.find(
        (tier2Item) => tier2Item.provider.name === 'Trades',
      ) as Tier2Positions,
    ];
    if ((tier2 as any[]).includes(undefined)) {
      throw new NotEnoughDataToProduceCompleteDay(
        'Not enough data to build all WBB tier 2 positions.',
      );
    }
    return tier2;
  }
  return tier2Positions;
};

const orderTier1EDFOrWBB = (tier1: Tier1Positions[]): Tier1Positions[] => {
  const ordered = [
    tier1.find(
      (tier1Item) => tier1Item.type === 'Consumption',
    ) as Tier1Positions,
    tier1.find(
      (tier1Item) => tier1Item.type === 'Production',
    ) as Tier1Positions,
  ];

  if ((ordered as any[]).includes(undefined)) {
    throw new NotEnoughDataToProduceCompleteDay(
      'Not enough data to build all tier 1 positions.',
    );
  }

  return ordered;
};

const orderTier1 = (nop: NopPosition): NopPosition => {
  const tier1 =
    nop.name === 'EDF' || nop.name === 'WBB'
      ? orderTier1EDFOrWBB(nop.tier1)
      : nop.tier1;

  return {
    ...nop,
    tier1,
    tier2: orderTier3(orderTier2(nop.name, nop.tier2)),
  };
};

const orderDay = ([
  efaDate,
  dayData,
]: NetOpenPositionHorizonEntry): NetOpenPositionHorizonEntry => [
  efaDate,
  {
    ...dayData,
    periods: dayData.periods.map((period): Period => {
      const nop = [
        period.nop.find((nopItem) => nopItem.name === 'EDF') as NopPosition,
        period.nop.find((nopItem) => nopItem.name === 'WBB') as NopPosition,
        period.nop.find((nopItem) => nopItem.name === 'Total') as NopPosition,
      ];

      if ((nop as any[]).includes(undefined)) {
        throw new NotEnoughDataToProduceCompleteDay(
          'Not enough data to build all tier 0 positions.',
        );
      }

      return {
        ...period,
        nop: nop.map((nopItem) => orderTier1(nopItem)),
      };
    }),
  },
];

export default (
  horizon: NopHorizon,
  metadata: HorizonMetadata,
): HorizonWithMetadata => {
  const entries = nopHorizonToEntries(horizon);
  const sorted = entries.reduce(
    (
      acc: [NetOpenPositionHorizonEntry[], HorizonMetadata],
      entry: NetOpenPositionHorizonEntry,
    ): [NetOpenPositionHorizonEntry[], HorizonMetadata] => {
      try {
        return [[...acc[0], orderDay(entry)], acc[1]];
      } catch (error) {
        if (!(error instanceof NotEnoughDataToProduceCompleteDay)) {
          throw error;
        }
        return [
          acc[0],
          {
            ...acc[1],
            incompleteDays: { ...acc[1].incompleteDays, [entry[0]]: entry[1] },
          },
        ];
      }
    },
    [[], metadata],
  );
  return [entriesToNopHorizon(sorted[0]), sorted[1]];
};
