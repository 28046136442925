import { PeriodVolume } from '../../../data/createTableData';
import {
  getEdfCopyNopRowButton,
  tier3PopOutButton,
  clickableCell,
} from '../NopTableButtons';
import { CounterpartySelectOption } from '../../../store/tradeAllocation/reducer';

export type CellActions = {
  canTrade: boolean;
  hasAction: JSX.Element;
};

export type ModifierFn = (
  productId: string,
  selectedCounterparty?: CounterpartySelectOption,
) => void;

export interface IApplyCellModifications {
  rows: [string, ...Array<PeriodVolume>][];
  onClickCallbackFn: ModifierFn;
}

const gridActions: Record<string, CellActions> = {
  'Total NOP': {
    canTrade: false,
    hasAction: getEdfCopyNopRowButton('Total'),
  },
  'EDF NOP': {
    canTrade: true,
    hasAction: getEdfCopyNopRowButton('EDF'),
  },
  'WBB NOP': {
    canTrade: true,
    hasAction: getEdfCopyNopRowButton('WBB'),
  },
  'Batteries NOP': {
    canTrade: true,
    hasAction: getEdfCopyNopRowButton('Batteries'),
  },
  'Gas Peaker NOP': {
    canTrade: true,
    hasAction: getEdfCopyNopRowButton('Gas Peaker'),
  },
  WBB: {
    canTrade: false,
    hasAction: tier3PopOutButton('WBB', 'Gas'),
  },
  Nuclear: {
    canTrade: false,
    hasAction: tier3PopOutButton('EDF', 'Nuclear'),
  },
  Coal: {
    canTrade: false,
    hasAction: tier3PopOutButton('EDF', 'Coal'),
  },
  Embed_Gen: {
    canTrade: false,
    hasAction: tier3PopOutButton('EDF', 'Embed_Gen'),
  },
  Customer_Vol: {
    canTrade: false,
    hasAction: tier3PopOutButton('EDF', 'Customer_Vol'),
  },
  Trades: {
    canTrade: false,
    hasAction: tier3PopOutButton('EDF', 'Trades'),
  },
  'WBB Trades': {
    canTrade: false,
    hasAction: tier3PopOutButton('WBB', 'Trades'),
  },
};

export const applyCellModifications = (props: IApplyCellModifications) => {
  const { rows, onClickCallbackFn } = props;
  const assignTradeOrderingActionToCells = rows.map((row) => {
    const [name] = row;
    const action = gridActions[<string>name];
    if (action && action.canTrade) {
      return row.map((cell, cellIndex) => {
        if (cellIndex > 0) {
          const selectOption =
            name !== 'EDF NOP'
              ? {
                  value: String(row[0]).replace(' NOP', '').replace(' ', '_'),
                  label: String(row[0]).replace(' NOP', ''),
                }
              : { value: 'WBB', label: 'WBB' };
          return clickableCell(
            cell as PeriodVolume,
            selectOption as CounterpartySelectOption,
            onClickCallbackFn,
          );
        }
        return cell;
      });
    }
    return row;
  });

  const assignActionsToRows = assignTradeOrderingActionToCells.map((row) => {
    const [name] = row;

    const action = gridActions[<string>name];
    if (action === undefined) {
      return row;
    }

    row[0] = action.hasAction;
    return row;
  });

  return assignActionsToRows;
};
