import styled from 'styled-components';
import { ConnectionLevel } from './NetworkStatusIndicator';

type NetworkStatusIndicatorProps = {
  connectionLevel: ConnectionLevel;
};
const NetworkStatusIndicatorContainer = styled.div<NetworkStatusIndicatorProps>`
  background-color: ${(props) => {
    switch (props.connectionLevel) {
      case ConnectionLevel.Good: {
        return '#0c994c';
      }
      case ConnectionLevel.Weak: {
        return '#ff9d00';
      }
      case ConnectionLevel.None: {
        return '#f54242';
      }
      case ConnectionLevel.Initializing: {
        return 'yellow';
      }
      default:
        return '#000000';
    }
  }};
  width: 20px;
  height: 20px;
  border-radius: 20px;
`;

export default NetworkStatusIndicatorContainer;
