import { DateTime } from 'luxon';
import { createTransform } from 'redux-persist';
import { NopState } from '../nop/reducer';

const TTL_MS = 1000 * 60 * 45; // 45 minutes

const SetTransform = createTransform(
  (inboundState: NopState) => {
    const { nop, status, horizonMetadata } = inboundState;
    return {
      // Only persist part of the state
      nop,
      horizonMetadata,
      // Serialise receivedAt Luxon object
      status: {
        ...status,
        receivedAt: status?.receivedAt?.toISO(),
      },
    };
  },
  (outboundState: any) => {
    let receivedAt = null;
    try {
      receivedAt = DateTime.fromISO(outboundState.status.receivedAt);
    } catch {
      receivedAt = null;
    }

    if (receivedAt?.isValid && receivedAt < DateTime.now().minus(TTL_MS)) {
      // Expire the state if it's older than the TTL
      return {};
    }

    return {
      ...outboundState,
      status: {
        ...outboundState.status,
        receivedAt,
      },
    };
  },
  { whitelist: ['netOpenPosition'] },
);

export default SetTransform;
