import { useSelector } from 'react-redux';
import AppState from '../AppState';

export const nopSelector = (state: AppState) => state.netOpenPosition.nop;

export const statusSelector = (state: AppState) => state.netOpenPosition.status;

export const gridDateSelector = (state: AppState) =>
  state.netOpenPosition.gridDate;

export const gridSelectionSelector = (state: AppState) =>
  state.netOpenPosition.gridSelection;

export const horizonWindowStatusSelector = (state: AppState) =>
  state.netOpenPosition.horizonWindowOn;

export const useNopState = () => useSelector(nopSelector);

export const useStatusState = () => useSelector(statusSelector);

export const useGridDateState = () => useSelector(gridDateSelector);

export const useGridSelectionState = () => useSelector(gridSelectionSelector);

export const useHorizonWindowStatusState = () =>
  useSelector(horizonWindowStatusSelector);
