import React, { useEffect, useState } from 'react';
import clock, { isClockMocked } from '../../common/clock/clock';

import ClockDisplayContainer from './style';

const ClockDisplay: React.FC = () => {
  if (!isClockMocked()) {
    return null;
  }

  const getFormattedTime = () =>
    clock().now().toFormat('yyyy-MM-dd HH:mm:ss (ZZ)');

  const [time, setTime] = useState(getFormattedTime());

  useEffect(() => {
    const interval = setInterval(() => setTime(getFormattedTime()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return <ClockDisplayContainer>Mocked clock: {time}</ClockDisplayContainer>;
};

export default ClockDisplay;
