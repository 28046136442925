import { NopChangeset } from '../../store/nop/actions';

type NopChangesetSubscriptionPayload = {
  data: { nopChangesetWasCreated: NopChangeset };
};

export default (next: () => NopChangesetSubscriptionPayload): NopChangeset => {
  const incomingData = next();
  return incomingData.data.nopChangesetWasCreated;
};
