import { wapRadioItems } from '../components/TradeAllocationWindow/Tabs/InternalAllocation/InternalAllocationTab';
import {
  TradeAllocationWindowState,
  WapType,
} from '../store/tradeAllocation/reducer';
import { Wap } from '../store/waps/actions';

const setWapBasedOnSelection = (
  waps: Wap[],
  windowState: TradeAllocationWindowState,
  selectedValue: number | null,
): TradeAllocationWindowState => {
  const selectedWapType: WapType | null =
    wapRadioItems.filter((item) => item.value === selectedValue)[0]?.type ??
    null;
  const newPrice: number | null =
    waps?.filter(
      (wap) => wap.productId === windowState.productList.selected?.value,
    )[0]?.[selectedWapType] ?? null;
  const newPriceAsString = newPrice
    ? parseFloat(`${newPrice}`).toFixed(2).toString()
    : '';
  return {
    ...windowState,
    internalAllocationForm: {
      ...windowState.internalAllocationForm,
      wapSelection: {
        ...windowState.internalAllocationForm.wapSelection,
        value: selectedValue,
      },
      price: {
        ...windowState.internalAllocationForm.price,
        value: newPriceAsString,
      },
      hasValidationTriggered: true,
    },
  };
};

export default setWapBasedOnSelection;
