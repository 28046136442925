import { privateTradesQuery } from '../../graphql/queries';
import { Action } from '../actions';
import { apiAction } from '../api/actions';
import { PrivateTradesApiResponse } from './reducer';

export const UPDATE_PRIVATE_TRADES = 'UPDATE_PRIVATE_TRADES';
export const PRIVATE_TRADES_ERROR = 'PRIVATE_TRADES_ERROR';
export const SET_PRIVATE_TRADES_AS_LOADING = 'SET_PRIVATE_TRADES_AS_LOADING';
export const SET_PRIVATE_TRADE_AS_SUCCESSFULLY_ALLOCATED =
  'SET_PRIVATE_TRADE_AS_SUCCESSFULLY_ALLOCATED';
export const SET_PRIVATE_TRADE_AS_ALLOCATION_FAILED =
  'SET_PRIVATE_TRADE_AS_ALLOCATION_FAILED';
export const SET_PRIVATE_TRADES_AS_PENDING = 'SET_PRIVATE_TRADES_AS_PENDING';

export const updatePrivateTrades = (
  payload: PrivateTradesApiResponse,
): Action => ({
  type: UPDATE_PRIVATE_TRADES,
  payload,
});

export const privateTradesError = (payload: string) => ({
  type: PRIVATE_TRADES_ERROR,
  payload,
});

export const setPrivateTradesAsLoading = () => ({
  type: SET_PRIVATE_TRADES_AS_LOADING,
  payload: null,
});

export const setPrivateTradeStatusAsSuccessfullyAllocated = (payload: {
  tradeId: string;
}) => ({
  type: SET_PRIVATE_TRADE_AS_SUCCESSFULLY_ALLOCATED,
  payload,
});

export const setPrivateTradeStatusAsAllocationFailed = (payload: {
  tradeId: string;
}) => ({
  type: SET_PRIVATE_TRADE_AS_ALLOCATION_FAILED,
  payload,
});

export const setPrivateTradeStatusAsPending = (payload: {
  tradesToSetToPending: string[];
}) => ({
  type: SET_PRIVATE_TRADES_AS_PENDING,
  payload,
});

export const queryPrivateTrades = (productIds: string[]): Action => {
  const action = apiAction<PrivateTradesApiResponse>({
    query: privateTradesQuery,
    variables: { productIds },
    onSuccess: (res) => updatePrivateTrades(res),
    onFailure: (x) =>
      privateTradesError(`Private trades retrieval error: ${x}`),
    label: UPDATE_PRIVATE_TRADES,
  });

  return action;
};
