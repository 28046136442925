import { NopHorizon } from '../../store/nop/reducer';
import {
  entriesToNopHorizon,
  NetOpenPositionHorizonEntry,
  nopHorizonToEntries,
} from './utils';

// Sort days within a horizon chronologically.

export default (horizon: NopHorizon): NopHorizon => {
  const entries = nopHorizonToEntries(horizon);
  const sortedEntries = entries.sort(
    (
      [aEfaDate]: NetOpenPositionHorizonEntry,
      [bEfaDate]: NetOpenPositionHorizonEntry,
    ) => aEfaDate.localeCompare(bEfaDate),
  );
  return entriesToNopHorizon(sortedEntries);
};
