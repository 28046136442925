import React from 'react';
import ToolBarContainer from './styles';

type ToolBarProps = {
  children: React.ReactNode;
};

const ToolBar = ({ children }: ToolBarProps) => (
  <ToolBarContainer>{children}</ToolBarContainer>
);

export default ToolBar;
