import { DateTime } from 'luxon';
import convertEfaDateToCalendarDate from '../../common/dates/convertEfaDateToCalendarDate';
import { Y_M_D } from '../../common/dates/dateFormatter';
import {
  EfaDay,
  NopChangeset,
  ChangesetTier,
  Period,
  PositionType,
  ChangesetPeriod,
} from '../../store/nop/actions';
import {
  NopHorizon,
  HorizonMetadata,
  HorizonWithMetadata,
} from '../../store/nop/reducer';

// eslint-disable-next-line prettier/prettier
class EncounteredOrphanError extends Error { }

const emptyMetadata = {
  orphans: { tier1: [], tier2: [], tier3: [] },
  incompleteDays: {},
};

const positionForPeriod = (changeset: NopChangeset, periodIndex: number) => {
  if (!changeset.periods[periodIndex]) {
    console.error(
      `A changeset with fewer periods than expected was detected [${changeset.efaDate} tier ${changeset.tier} ${changeset.name}]`,
    );
  }
  return {
    nbp: changeset.periods[periodIndex]?.nbp ?? 0,
    gate: changeset.periods[periodIndex]?.gate ?? 0,
  };
};

const generatePeriods = (
  efaDay: string,
  periods: ChangesetPeriod[],
): Period[] =>
  periods.map((settlement) => ({
    period: {
      startTime: settlement.metadata.startTime,
      endTime: settlement.metadata.endTime,
      settlementPeriod: settlement.metadata.periodNumber,
      periodId: settlement.periodId,
    },
    nop: [],
  }));

const reduceTier0 = (existing: Period[], changeset: NopChangeset): Period[] => {
  let existingPeriods = existing;
  if (existing.length === 0) {
    existingPeriods = generatePeriods(changeset.efaDate, changeset.periods);
  }
  const changesetHasBeenEncounteredBefore =
    existingPeriods[0]?.nop.find((position) => position.id === changeset.id) !==
    undefined;

  return existingPeriods.map((period, periodIndex) => {
    if (!changesetHasBeenEncounteredBefore) {
      return {
        ...period,
        nop: [
          ...period.nop,
          {
            id: changeset.id,
            name: changeset.name,
            position: positionForPeriod(changeset, periodIndex),
            tier1: [],
            tier2: [],
          },
        ],
      };
    }
    return {
      ...period,
      nop: period.nop.map((position) => {
        if (position.id === changeset.id) {
          return {
            ...position,
            position: positionForPeriod(changeset, periodIndex),
          };
        }
        return position;
      }),
    };
  });
};

const reduceTier1 = (existing: Period[], changeset: NopChangeset): Period[] => {
  const doesTier0ForItemExist =
    existing[0]?.nop.find((position) => position.id === changeset.parent) !==
    undefined;

  if (!doesTier0ForItemExist) {
    throw new EncounteredOrphanError(
      `Tier 1 item [${changeset.id}] is orphaned because its tier 0 parent [${changeset.parent}] doesn't exist yet`,
    );
  }

  const changesetHasBeenEncounteredBefore =
    existing[0].nop.find((position) =>
      position.tier1.find((subPosition) => subPosition.id === changeset.id),
    ) !== undefined;

  if (!changesetHasBeenEncounteredBefore) {
    return existing.map((period, periodIndex) => ({
      ...period,
      nop: period.nop.map((position) => {
        if (position.id !== changeset.parent) {
          return position;
        }
        return {
          ...position,
          tier1: [
            ...position.tier1,
            {
              id: changeset.id,
              type: changeset.name as PositionType,
              position: positionForPeriod(changeset, periodIndex),
            },
          ],
        };
      }),
    }));
  }

  return existing.map((period, periodIndex) => ({
    ...period,
    nop: period.nop.map((position) => {
      if (position.id !== changeset.parent) {
        return position;
      }
      return {
        ...position,
        tier1: position.tier1.map((tier1Position) => {
          if (tier1Position.id !== changeset.id) {
            return tier1Position;
          }
          return {
            ...tier1Position,
            position: positionForPeriod(changeset, periodIndex),
          };
        }),
      };
    }),
  }));
};

const reduceTier2 = (existing: Period[], changeset: NopChangeset): Period[] => {
  const doesTier0ForItemExist =
    existing[0]?.nop.find((position) => position.id === changeset.parent) !==
    undefined;

  if (!doesTier0ForItemExist) {
    throw new EncounteredOrphanError(
      `Tier 2 item [${changeset.id}] is orphaned because its tier 0 parent [${changeset.parent}] doesn't exist yet`,
    );
  }

  const changesetHasBeenEncounteredBefore =
    existing[0].nop.find((position) =>
      position.tier2.find((subPosition) => subPosition.id === changeset.id),
    ) !== undefined;

  if (!changesetHasBeenEncounteredBefore) {
    return existing.map((period, periodIndex) => ({
      ...period,
      nop: period.nop.map((position) => {
        if (position.id !== changeset.parent) {
          return position;
        }
        return {
          ...position,
          tier2: [
            ...position.tier2,
            {
              id: changeset.id,
              position: positionForPeriod(changeset, periodIndex),
              provider: { name: changeset.name },
              tier3: [],
            },
          ],
        };
      }),
    }));
  }

  return existing.map((period, periodIndex) => ({
    ...period,
    nop: period.nop.map((position) => {
      if (position.id !== changeset.parent) {
        return position;
      }
      return {
        ...position,
        tier2: position.tier2.map((tier2Position) => {
          if (tier2Position.id !== changeset.id) {
            return tier2Position;
          }
          return {
            ...tier2Position,
            position: positionForPeriod(changeset, periodIndex),
          };
        }),
      };
    }),
  }));
};

const getIndexForTier3 = (
  existing: Period[],
  parentId: string,
): [number, number] | null => {
  if (existing.length === 0) {
    return null;
  }
  let index = null;
  existing[0].nop.forEach((tier0position, tier0Index) => {
    tier0position.tier2.forEach((tier2Position, tier2Index) => {
      if (tier2Position.id === parentId) {
        index = [tier0Index, tier2Index];
      }
    });
  });
  return index;
};

const reduceTier3 = (existing: Period[], changeset: NopChangeset): Period[] => {
  const indexForTier3 = getIndexForTier3(existing, changeset.parent as string);
  if (indexForTier3 === null) {
    throw new EncounteredOrphanError(
      `Tier 3 item [${changeset.id}] is orphaned because its tier 2 parent [${changeset.parent}] doesn't exist yet`,
    );
  }

  const changesetHasBeenEncounteredBefore =
    existing[0].nop[indexForTier3[0]].tier2[indexForTier3[1]].tier3.find(
      (tier3Item) => tier3Item.id === changeset.id,
    ) !== undefined;

  if (!changesetHasBeenEncounteredBefore) {
    return existing.map((period, periodIndex) => ({
      ...period,
      nop: period.nop.map((tier0Position, tier0PositionIndex) => {
        if (tier0PositionIndex !== indexForTier3[0]) {
          return tier0Position;
        }
        return {
          ...tier0Position,
          tier2: tier0Position.tier2.map(
            (tier2Position, tier2PositionIndex) => {
              if (tier2PositionIndex !== indexForTier3[1]) {
                return tier2Position;
              }
              return {
                ...tier2Position,
                tier3: [
                  ...tier2Position.tier3,
                  {
                    id: changeset.id,
                    name: changeset.name,
                    position: positionForPeriod(changeset, periodIndex),
                  },
                ],
              };
            },
          ),
        };
      }),
    }));
  }

  return existing.map((period, periodIndex) => ({
    ...period,
    nop: period.nop.map((tier0Position, tier0PositionIndex) => {
      if (tier0PositionIndex !== indexForTier3[0]) {
        return tier0Position;
      }
      return {
        ...tier0Position,
        tier2: tier0Position.tier2.map((tier2Position, tier2PositionIndex) => {
          if (tier2PositionIndex !== indexForTier3[1]) {
            return tier2Position;
          }
          return {
            ...tier2Position,
            tier3: tier2Position.tier3.map((tier3Position) => {
              if (tier3Position.id !== changeset.id) {
                return tier3Position;
              }
              return {
                ...tier3Position,
                position: positionForPeriod(changeset, periodIndex),
              };
            }),
          };
        }),
      };
    }),
  }));
};

const reducePeriods = (
  existing: Period[],
  changeset: NopChangeset,
): Period[] => {
  switch (changeset.tier) {
    case ChangesetTier.Tier0:
      return reduceTier0(existing, changeset);
    case ChangesetTier.Tier1:
      return reduceTier1(existing, changeset);
    case ChangesetTier.Tier2:
      return reduceTier2(existing, changeset);
    case ChangesetTier.Tier3:
      return reduceTier3(existing, changeset);
    default:
      return existing;
  }
};

const deriveDate = (efaDate: string): EfaDay => ({
  efaDate,
  calendarDate: convertEfaDateToCalendarDate(
    DateTime.fromISO(efaDate),
  ).toFormat(Y_M_D),
});

const reduceHorizon = (
  horizon: NopHorizon,
  changeset: NopChangeset,
): NopHorizon => ({
  ...horizon,
  [changeset.efaDate]: {
    periods: reducePeriods(
      horizon[changeset.efaDate]?.periods ?? [],
      changeset,
    ),
    date: horizon[changeset.efaDate]?.date ?? deriveDate(changeset.efaDate),
  },
});

const handleOrphans = (
  horizon: NopHorizon,
  metadata: HorizonMetadata,
): HorizonWithMetadata =>
  [
    ...metadata.orphans.tier1,
    ...metadata.orphans.tier2,
    ...metadata.orphans.tier3,
  ].reduce(
    (acc: HorizonWithMetadata, orphan: NopChangeset): HorizonWithMetadata => {
      try {
        return [reduceHorizon(acc[0], orphan), acc[1]];
      } catch (error) {
        if (!(error instanceof EncounteredOrphanError)) {
          throw error;
        }
        return [acc[0], addOrphanToMetadata(acc[1], orphan)];
      }
    },
    [horizon, emptyMetadata],
  );

const addOrphanToMetadata = (
  metadata: HorizonMetadata,
  orphan: NopChangeset,
): HorizonMetadata => {
  switch (orphan.tier) {
    case ChangesetTier.Tier1:
      return {
        ...metadata,
        orphans: {
          ...metadata.orphans,
          tier1: [...metadata.orphans.tier1, orphan],
        },
      };
    case ChangesetTier.Tier2:
      return {
        ...metadata,
        orphans: {
          ...metadata.orphans,
          tier2: [...metadata.orphans.tier2, orphan],
        },
      };
    case ChangesetTier.Tier3:
      return {
        ...metadata,
        orphans: {
          ...metadata.orphans,
          tier3: [...metadata.orphans.tier3, orphan],
        },
      };
    default:
      return metadata;
  }
};

const reduceOne = (
  horizon: NopHorizon,
  metadata: HorizonMetadata,
  newChangeset: NopChangeset,
): HorizonWithMetadata => {
  try {
    return [reduceHorizon(horizon, newChangeset), metadata];
  } catch (error) {
    if (!(error instanceof EncounteredOrphanError)) {
      throw error;
    }
    return [horizon, addOrphanToMetadata(metadata, newChangeset)];
  }
};

export default (
  horizon: NopHorizon,
  metadata: HorizonMetadata,
  changesets: NopChangeset[],
): HorizonWithMetadata => {
  const horizonWithIncompleteDays = { ...horizon, ...metadata.incompleteDays };
  const reduceAll = changesets.reduce(
    (acc: HorizonWithMetadata, changeset: NopChangeset) =>
      reduceOne(acc[0], acc[1], changeset),
    [horizonWithIncompleteDays, metadata],
  );
  const withHandledOrphans = handleOrphans(reduceAll[0], reduceAll[1]);
  return [withHandledOrphans[0], withHandledOrphans[1]];
};
