import {
  CalendarDateTime,
  CalendarDayEndPeriods,
  EfaDay,
  Product,
  ProductName,
  ProductType,
} from '@edfenergy/shift-desk-efa-calendar';
import { DateTime } from 'luxon';
import clock, { isClockMocked } from '../../common/clock/clock';
import productMapper from '../../common/products/mapper';
import { Action } from '../actions';
import { INCREMENT_CURRENT_TIME } from '../timing/actions';
import {
  ADD_GATECLOSURES,
  UPDATE_GATECLOSURES,
  UpdateGateClosuresPayload,
} from './actions';

export type CurrentGateDetails = {
  period: number;
  startTime: string;
  endTime: string;
  date: string;
  time: Date;
};
export type ClosureDetails = {
  period: number;
  startTime: string;
  endTime: string;
  date: string;
};

export type GateClosures = {
  currentGateDetails?: CurrentGateDetails;
  marketGatePeriod?: ClosureDetails;
  pnGatePeriod?: ClosureDetails;
};

export const initialState: GateClosures = {
  currentGateDetails: undefined,
  marketGatePeriod: undefined,
  pnGatePeriod: undefined,
};

const isFirstHalfOfPeriod = (date: DateTime): boolean =>
  date.minute < 15 || (date.minute >= 30 && date.minute < 45);

const mockGateClosures = (now: DateTime): GateClosures => {
  const currentGate = new EfaDay(now.toJSDate());
  const marketGate = new EfaDay(
    now.plus({ minutes: isFirstHalfOfPeriod(now) ? 30 : 60 }).toJSDate(),
  );
  const pnGate = new EfaDay(now.plus({ minutes: 90 }).toJSDate());

  return {
    currentGateDetails: {
      ...(currentGate.getCurrentSettlementPeriod() as CalendarDayEndPeriods),
      time: now.toJSDate(),
    },
    marketGatePeriod: marketGate.getCurrentSettlementPeriod(),
    pnGatePeriod: pnGate.getCurrentSettlementPeriod(),
  };
};

const fixGateClosures = (gateClosures: GateClosures): GateClosures => {
  // The current .getCurrentSettlementPeriod() function which is executed on the backend is not clock change aware.
  // Specifically it doesn't account for 'two 1AMs' in local time on long day
  // This function fixes that by intercepting the gate closure for that case and correcting it
  // The long term fix is to correct this on the backend.
  if (!gateClosures.currentGateDetails) {
    return gateClosures;
  }

  const gateClosuresTime = gateClosures.currentGateDetails.time;

  const calendarDateTime =
    gateClosuresTime instanceof Date
      ? new CalendarDateTime(DateTime.fromJSDate(gateClosuresTime))
      : CalendarDateTime.fromISO(gateClosuresTime);

  if (calendarDateTime.toEfaDateTime().dayType().valueOf() !== 1) {
    // Not long day
    return gateClosures;
  }

  const currentProduct =
    productMapper().getHalfHourProductForTime(calendarDateTime);

  if (!['1', '2', '3', '4', '5', '6'].includes(currentProduct.getName())) {
    // Not a period where there's a bug
    return gateClosures;
  }

  const marketGate = new Product(
    ProductType.HalfHour,
    `${
      +currentProduct.getName() +
      (isFirstHalfOfPeriod(calendarDateTime.toLuxon()) ? 1 : 2)
    }` as ProductName,
    currentProduct.getCalendarDate(),
  );

  const pnGate = new Product(
    ProductType.HalfHour,
    `${+currentProduct.getName() + 3}` as ProductName,
    currentProduct.getCalendarDate(),
  );

  return {
    ...gateClosures,
    currentGateDetails: {
      ...gateClosures.currentGateDetails,
      period: +currentProduct.getName(),
    } as CurrentGateDetails,
    marketGatePeriod: {
      ...gateClosures.marketGatePeriod,
      period: +marketGate.getName(),
    } as ClosureDetails,
    pnGatePeriod: {
      ...gateClosures.pnGatePeriod,
      period: +pnGate.getName(),
    } as ClosureDetails,
  };
};

export const gateClosures = (
  state: GateClosures = initialState,
  action: Action,
) => {
  switch (action.type) {
    case ADD_GATECLOSURES: {
      if (isClockMocked()) {
        // Return mocked gate closures which match the environment override
        return fixGateClosures({
          ...state,
          ...mockGateClosures(clock().now().toLuxon()),
        });
      }

      const { payload } = action;
      const { getCurrentGateClosures } = payload;

      return fixGateClosures({ ...state, ...getCurrentGateClosures });
    }
    case UPDATE_GATECLOSURES: {
      if (isClockMocked()) {
        // Return mocked gate closures which match the environment override
        return fixGateClosures({
          ...state,
          ...mockGateClosures(clock().now().toLuxon()),
        });
      }

      const { payload }: { payload: UpdateGateClosuresPayload } = action;
      return fixGateClosures({
        ...state,
        ...payload.data.currentGateClosuresWasUpdated,
      });
    }
    case INCREMENT_CURRENT_TIME: {
      if (!isClockMocked()) {
        // Return mocked gate closures which match the environment override
        return state;
      }
      return fixGateClosures({
        ...state,
        ...mockGateClosures(clock().now().toLuxon()),
      });
    }
    default:
      return state;
  }
};
