import React from 'react';
import { TableCellHighlight } from '@edfenergy/shift-desk-wallace';
import { Product } from '@edfenergy/shift-desk-efa-calendar';
import { formatDateDDMmm } from '../../../common/dates/dateFormatter';
import {
  NopPeriodCell,
  NopPeriodHeader,
  PeriodHeader,
  PeriodVolume,
} from '../../../data/createTableData';
import {
  ClosureDetails,
  CurrentGateDetails,
  GateClosures,
} from '../../../store/gateClosures/reducer';

export type ElementAttributes = {
  role: string;
  'data-starttime': string;
  'data-endttime': string;
  'data-efadate': string;
  'data-periodid': string;
};

export const findColumnToHighlight = (
  cell: NopPeriodCell,
  gate: CurrentGateDetails | ClosureDetails | undefined,
) => {
  const gateDate = gate?.date;
  const gatePeriod = gate?.period;
  const { periodId } = cell as PeriodVolume;

  if (!gateDate || !gatePeriod || !periodId) {
    return false;
  }

  const period = Product.fromId(periodId);

  return (
    period.getCalendarDate().toFormat() === gateDate &&
    period.getName() === `${gatePeriod}`
  );
};

const cellHightLighter = (cell: NopPeriodCell, gate: GateClosures) => {
  if (findColumnToHighlight(cell, gate.currentGateDetails)) {
    return TableCellHighlight.Highlight1;
  }
  if (findColumnToHighlight(cell, gate.marketGatePeriod)) {
    return TableCellHighlight.Highlight2;
  }
  if (findColumnToHighlight(cell, gate.pnGatePeriod)) {
    return TableCellHighlight.Highlight3;
  }
  return undefined;
};

export const getBodyCellHighlight = (
  cell: NopPeriodCell,
  gateDetails: GateClosures,
): TableCellHighlight | undefined => {
  if (React.isValidElement<ElementAttributes>(cell)) {
    const { props } = cell;

    if (props.role === 'button') {
      const startTime = props['data-starttime'];
      const endTime = props['data-endttime'];
      const efaDate = props['data-efadate'];
      const periodId = props['data-periodid'];

      const cellInfo: PeriodVolume = {
        startTime,
        endTime,
        efaDate,
        portfolio: '',
        volume: 0,
        periodId,
      };

      return cellHightLighter(cellInfo, gateDetails);
    }
  }

  return cellHightLighter(cell, gateDetails);
};

const findHeaderToHighlight = (
  header: NopPeriodHeader,
  gate: CurrentGateDetails | ClosureDetails | undefined,
) => {
  if (gate === undefined) {
    return false;
  }
  const { startTime, endTime, date } = gate;

  const cellDate = `${formatDateDDMmm(date)} ${endTime}`;

  const headerFound = !!(
    startTime &&
    (header as PeriodHeader).endTime === cellDate &&
    (header as PeriodHeader).efaDate === date
  );

  return headerFound;
};

export const getHeaderCellHighlight = (
  header: NopPeriodHeader,
  gate: GateClosures,
): TableCellHighlight | undefined => {
  if (findHeaderToHighlight(header, gate.currentGateDetails)) {
    return TableCellHighlight.Highlight1;
  }
  if (findHeaderToHighlight(header, gate.marketGatePeriod)) {
    return TableCellHighlight.Highlight2;
  }
  if (findHeaderToHighlight(header, gate.pnGatePeriod)) {
    return TableCellHighlight.Highlight3;
  }
  return undefined;
};
