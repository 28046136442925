import {
  addMilliseconds,
  differenceInMilliseconds,
  format,
  parseISO,
} from 'date-fns';
import { toDate } from 'date-fns-tz';
import { DateTime } from 'luxon';
import {
  ADD_GATECLOSURES,
  GATECLOSURES_ERROR,
  UPDATE_GATECLOSURES,
} from '../gateClosures/actions';
import { Action } from '../actions';
import { INCREMENT_CURRENT_TIME } from './actions';
import clock, { isClockMocked } from '../../common/clock/clock';

export interface CurrentTimeState {
  receivedAt?: Date;
  receivedNewStart: boolean;
  startDateTime?: Date;
  currentDateTime?: Date;
}

const initialState: CurrentTimeState = {
  receivedAt: undefined,
  receivedNewStart: false,
  startDateTime: undefined,
  currentDateTime: undefined,
};

/*
Notes:

0) The design is based on an idea that the back-end serves as a single source of truth - in this case time.
1) UI needs granular updates (ticks) to advance time indicator(s) in a (very soft) real-time.
2) Doing such ticks over the network would be somewhat of an overhead.
3) Hence the need to tick on front-end but sync-up with the back-end "every now and then".
4) Current time is not coupled to (absolute) local time, only to (relative) local ticks (otherwise the purpose of the design would be defeated).
5) Front-end ticks at regular intervals (every N seconds).
6) Back-end sends a starting point in time also regularly but much less frequently (every M minutes).
7) Front-end increments starting from that point every tick.
8) Back-end time is brought into the client time zone by ignoring time components at hour granularity and above (day, month, year).
9) New starting point always overrides the current time point, so that the highlighted bars and the current time indicator would advance in sync at the moment when gates are transitioning.
10) In an unusual situation where the new starting point arrives between ticks then a partial increment is applied on the next tick.
11) If an error occurs when receiving a back-end signal then the front-end carries on.
12) If on start front-end ticks prior to receiving the back-end signal then it falls back on current local front-end time.

(see other notes in code below)

*/

const mockTimings = (now: DateTime) => ({
  receivedAt: now.toJSDate(),
  receivedNewStart: true,
  startDateTime: now.toJSDate(),
  currentDateTime: now.toJSDate(),
});

export const currentTime = (
  state: CurrentTimeState = initialState,
  action: Action,
) => {
  switch (action.type) {
    case ADD_GATECLOSURES: {
      if (isClockMocked()) {
        // Return mocked timings which match the environment override
        return { ...state, ...mockTimings(clock().now().toLuxon()) };
      }

      const { payload } = action;
      const { getCurrentGateClosures } = payload;
      const { currentGateDetails } = getCurrentGateClosures;

      const localDateTime = clock().now().toLuxon().toJSDate();
      const gateDateTime = parseISO(currentGateDetails.time);
      const newStartDateTime = deriveDateTime(localDateTime, gateDateTime);

      return {
        receivedAt: localDateTime,
        receivedNewStart: true,
        startDateTime: newStartDateTime,
        currentDateTime: newStartDateTime,
      };
    }
    case UPDATE_GATECLOSURES: {
      if (isClockMocked()) {
        // Return mocked timings which match the environment override
        return { ...state, ...mockTimings(clock().now().toLuxon()) };
      }

      const { payload } = action;
      const { data } = payload;
      const { currentGateClosuresWasUpdated } = data;
      const { currentGateDetails } = currentGateClosuresWasUpdated;

      const localDateTime = clock().now().toLuxon().toJSDate();
      const gateDateTime = parseISO(currentGateDetails.time);
      const newStartDateTime = deriveDateTime(localDateTime, gateDateTime);

      return {
        receivedAt: localDateTime,
        receivedNewStart: true,
        startDateTime: newStartDateTime,
        currentDateTime: newStartDateTime,
      };
    }
    case GATECLOSURES_ERROR: {
      return {
        receivedAt: state.receivedAt,
        receivedNewStart: false,
        startDateTime: state.startDateTime,
        currentDateTime: state.currentDateTime,
      };
    }
    case INCREMENT_CURRENT_TIME: {
      const { payload } = action;
      const { data } = payload;
      const { incrementMs } = data;

      if (!state.receivedAt) {
        // Fall back on local time since the back-end did not (yet) send the gate time.

        return {
          receivedAt: state.receivedAt,
          receivedNewStart: false,
          startDateTime: state.startDateTime,
          currentDateTime: clock().now().toLuxon().toJSDate(),
        };
      }

      if (state.receivedNewStart) {
        return {
          receivedAt: state.receivedAt,
          receivedNewStart: false,
          startDateTime: state.startDateTime,
          currentDateTime: rebaseOnNewStartDateTime(
            state.startDateTime!,
            state.receivedAt,
            clock().now().toLuxon().toJSDate(),
            incrementMs,
          ),
        };
      }

      return {
        receivedAt: state.receivedAt,
        receivedNewStart: false,
        startDateTime: state.startDateTime,
        currentDateTime: addMilliseconds(state.currentDateTime!, incrementMs),
      };
    }
    default:
      return state;
  }
};

export const deriveDateTime = (
  dateHoursDateTime: Date,
  minSecMsDateTime: Date,
) => {
  // Back-end is in a different timezone (UTC vs GMT) so a local representation of that is concocted here based on current local date and hours time components.

  const hours = dateHoursDateTime.getHours();
  const minutes = minSecMsDateTime.getMinutes();
  const seconds = minSecMsDateTime.getSeconds();

  const startDateTimeString = `${format(
    dateHoursDateTime,
    'yyyy-MM-dd',
  )}T${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}+00:00`;

  const ms = minSecMsDateTime.getMilliseconds();
  return addMilliseconds(toDate(startDateTimeString), ms);
};

export const rebaseOnNewStartDateTime = (
  startDateTime: Date,
  receivedAt: Date,
  localDateTime: Date,
  incrementMs: number,
): Date => {
  const receivedAgoMs = differenceInMilliseconds(localDateTime, receivedAt);

  if (receivedAgoMs < incrementMs) {
    // New gate time has been received mid-tick for some reason so we only need a partial "top-up" increment.
    // Note: there is no accomodation for the network lag at the moment.
    const partialIncrementMs = receivedAgoMs;

    return addMilliseconds(startDateTime, partialIncrementMs);
  }

  // New gate time has been received slightly more than a tick ago - increment accordingly to catch-up.
  return addMilliseconds(startDateTime, receivedAgoMs);
};
