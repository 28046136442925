import _ from 'lodash';
import { PrivateTrade } from '../store/privateTrades/reducer';

const wapForPrivateTrades = (privateTrades: PrivateTrade[]): number => {
  const totalVolume = privateTrades.reduce(
    (acc, trade) => acc + trade.volume,
    0,
  );
  const wap =
    privateTrades.reduce((acc, trade) => acc + trade.volume * trade.price, 0) /
    totalVolume;
  return _.round(wap, 2);
};

export default wapForPrivateTrades;
