import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import { TableColumnNames } from '../../data/createTableData';
import {
  ChartRange,
  ChartRangeKind,
  setChartRange,
  NopChartType,
  setNopChartType,
} from '../../store/charting/actions';
import {
  useChartRangeState,
  useChartNopTypeState,
} from '../../store/charting/selector';
import Legend from '../Charts/Legend';
import { ColoursEdf, ColoursWbb } from '../Charts/utils/colours';

import ToggleRadioButtonGroup from '../ToggleRadioButtonGroup';
import ToolBar from '../ToolBar';

const ChartToolBar: React.FC = () => {
  const currentRange = useChartRangeState();
  const nopChartType = useChartNopTypeState();
  const dispatch = useDispatch();

  const pastPeriods = 4;
  const periodsIn12Hours = 12 * 2;
  const periodsIn24Hours = 24 * 2;
  const periodsIn2Days = 24 * 2 * 2;
  const periodsIn7Days = 24 * 7 * 2;

  const horizonType = [
    {
      text: '12H',
      value: {
        rangeKind: ChartRangeKind.Hours12,
        pastPeriods,
        currentPeriods: periodsIn12Hours - pastPeriods,
        days: 1,
      },
    },
    {
      text: '24H',
      value: {
        rangeKind: ChartRangeKind.Hours24,
        pastPeriods,
        currentPeriods: periodsIn24Hours - pastPeriods,
        days: 1,
      },
    },
    {
      text: '2D',
      value: {
        rangeKind: ChartRangeKind.Days2,
        pastPeriods,
        currentPeriods: periodsIn2Days - pastPeriods,
        days: 2,
      },
    },
    {
      text: '7D',
      value: {
        rangeKind: ChartRangeKind.Days7,
        pastPeriods,
        currentPeriods: periodsIn7Days - pastPeriods,
        days: 7,
      },
    },
  ].map((item) => ({
    ...item,
    checked: item.value.rangeKind === currentRange.rangeKind,
  }));

  const nopType = [
    {
      text: NopChartType.EDF,
      value: NopChartType.EDF,
    },
    {
      text: NopChartType.WBB,
      value: NopChartType.WBB,
    },
    {
      text: NopChartType.ALL,
      value: NopChartType.ALL,
    },
  ].map((item) => ({
    ...item,
    checked: item.value === nopChartType,
  }));

  const dispatchHorizon = (value: ChartRange) => {
    dispatch(setChartRange({ data: value }));
  };

  const dispatchNopChartType = (value: NopChartType) => {
    dispatch(setNopChartType(value));
  };

  return (
    <div data-testid="Horizon_tool_bar">
      <ToolBar>
        <span>Horizon</span>
        <ToggleRadioButtonGroup
          items={horizonType}
          onChange={(item, checked) => {
            if (checked) dispatchHorizon(item.value);
          }}
        />
        <span>NOP Type</span>
        <ToggleRadioButtonGroup
          items={nopType}
          onChange={(item, checked) => {
            if (checked) dispatchNopChartType(item.value);
          }}
        />
        <span>Legend</span>
        <Legend
          colours={[ColoursEdf.Positive, ColoursEdf.Negative]}
          label={TableColumnNames.NopEdf}
        />
        <Legend
          colours={[ColoursWbb.Positive, ColoursWbb.Negative]}
          label={TableColumnNames.NopWbb}
        />
      </ToolBar>
    </div>
  );
};

export default memo(ChartToolBar);
