import styled from 'styled-components';
import { StyledFormInputProps } from './InputField';
import { InputSymbolProps } from './InputSymbol';

export const StyledFormLabel = styled.label`
  font-size: 12px;
  font-weight: 600;
  color: #666;
  letter-spacing: 0.8px;
  margin-top: -10px;
`;

export const TextLabel = styled.div`
  padding: 10px 0;
`;

export const InputContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 15px;
`;

export const StyledFormInput = styled.input<StyledFormInputProps>`
  appearance: none;
  outline: none;
  background-color: ${({ hasError }) =>
    hasError ? 'rgba(227, 39, 71, 0.12) !important' : '#f1f1f1'};
  font-size: 14px;
  font-weight: 600;
  border-style: ${({ units }) => (units ? 'solid none solid solid' : 'solid')};
  border-width: 1px;
  border-color: #ff0000
  height: 23px;
  width: 100%;
  padding-left: 8px;
  padding-right: 3px;
  &:disabled {
    background-color: #f1f1f1;
  }
  &:focus {
    background-color: #eff6ff;
    & + div {
      background-color: #eff6ff;
    }
  }

`;

export const StyledUnitDiv = styled.div<InputSymbolProps>`
  appearance: none;
  outline: none;
  font-weight: 600;
  font-size: 14px;
  background-color: ${({ hasError }) =>
    hasError ? 'rgba(227, 39, 71, 0.12) !important' : '#f1f1f1'};
  padding: 0px 10px;
  text-align: center;
  border-style: solid;
  border-width: 1px;
  border-left: none;
`;
